import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const MobileIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g>
      <g>
        <path
          d="M320,120c4.418,0,8-3.582,8-8V32c0-17.673-14.327-32-32-32H88C70.327,0,56,14.327,56,32v408
			c0.026,22.08,17.92,39.974,40,40h192c22.08-0.026,39.974-17.92,40-40v-72c0-4.418-3.582-8-8-8s-8,3.582-8,8v48H72V64h240v48
			C312,116.418,315.582,120,320,120z M312,432v8c0,13.255-10.745,24-24,24H96c-13.255,0-24-10.745-24-24v-8H312z M72,48V32
			c0-8.837,7.163-16,16-16h208c8.837,0,16,7.163,16,16v16H72z"
        />
      </g>
    </g>
    <g>
      <g>
        <path d="M208,440h-32c-4.418,0-8,3.582-8,8s3.582,8,8,8h32c4.418,0,8-3.582,8-8S212.418,440,208,440z" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M312,128c-61.828,0.066-111.934,50.172-112,112v112c0,3.348,2.084,6.342,5.224,7.504c0.89,0.324,1.829,0.492,2.776,0.496
			c2.339,0,4.56-1.023,6.08-2.8l26.568-31.04c20.006,16.734,45.27,25.884,71.352,25.84c61.856,0,112-50.144,112-112
			S373.856,128,312,128z M312,336c-24.864,0.072-48.767-9.601-66.584-26.944c-0.064-0.064-0.152-0.08-0.208-0.136
			c-0.056-0.056-0.096-0.144-0.16-0.2c-0.367-0.25-0.755-0.467-1.16-0.648c-0.442-0.313-0.913-0.581-1.408-0.8
			c-0.451-0.12-0.911-0.2-1.376-0.24c-0.543-0.127-1.098-0.192-1.656-0.192c-0.439,0.055-0.872,0.146-1.296,0.272
			c-0.568,0.088-1.123,0.241-1.656,0.456c-0.431,0.23-0.84,0.498-1.224,0.8c-0.413,0.23-0.804,0.498-1.168,0.8
			c-0.064,0.064-0.08,0.152-0.136,0.216c-0.056,0.064-0.144,0.088-0.2,0.152L216,330.352V240c0-53.019,42.981-96,96-96
			s96,42.981,96,96S365.019,336,312,336z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M312,216c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S325.255,216,312,216z M312,248c-4.418,0-8-3.582-8-8
			s3.582-8,8-8s8,3.582,8,8S316.418,248,312,248z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M256,216c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S269.255,216,256,216z M256,248c-4.418,0-8-3.582-8-8
			s3.582-8,8-8s8,3.582,8,8S260.418,248,256,248z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M368,216c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S381.255,216,368,216z M368,248c-4.418,0-8-3.582-8-8
			s3.582-8,8-8s8,3.582,8,8S372.418,248,368,248z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </SvgIcon>
);
