import {
  LabelHeader,
  SortingHeader,
} from "../../components/table/table-headers";
import { Column } from "react-table";
import { Reservation } from "../model/reservation";
import {
  BooleanAccessor,
  DateAccessor,
  IdentityAccessor,
} from "../../components/table/table-accessors";
import {
  DateFilter,
  ReservationApaleoStatusSelectFilter,
} from "../../components/table/table-filters";

export const reservationsTableColumns: Column<Reservation>[] = [
  { Header: LabelHeader("Actions"), id: "actions" },

  // Reservation ID
  {
    Header: SortingHeader("Reservation ID"),
    accessor: IdentityAccessor("reservationId"),
    id: "reservationId",
  },
  // Group Booking
  {
    Header: SortingHeader("Group booking"),
    accessor: BooleanAccessor("groupBooking"),
    id: "groupBooking",
    disableFilters: true,
  },
  // Property ID
  {
    Header: SortingHeader("Property ID"),
    accessor: IdentityAccessor("propertyId"),
    id: "propertyId",
    disableFilters: true,
  },
  // Status
  {
    Header: SortingHeader("Status"),
    accessor: IdentityAccessor("status"),
    id: "status",
    Filter: ReservationApaleoStatusSelectFilter,
  },
  // apaleo created at
  {
    Header: SortingHeader("Created at"),
    accessor: DateAccessor("apaleoCreatedAt"),
    id: "exactCreatedAt",
    Filter: DateFilter,
  },
  // Timestamp
  {
    Header: SortingHeader("Updated at"),
    accessor: DateAccessor("timestamp"),
    id: "timestamp",
    disableFilters: true,
  },
  {
    Header: SortingHeader("Channel"),
    accessor: IdentityAccessor("channelCode"),
    id: "channelCode",
    disableFilters: true,
  },
  // arrival date and time
  {
    Header: SortingHeader("Arrival"),
    accessor: DateAccessor("arrival"),
    id: "arrival",
    Filter: DateFilter,
  },
  // Check-in time
  {
    Header: SortingHeader("Check-in time"),
    accessor: DateAccessor("checkInTime"),
    id: "checkInTime",
    Filter: DateFilter,
  },
  // departure date and time
  {
    Header: SortingHeader("Departure"),
    accessor: DateAccessor("departure"),
    id: "departure",
    Filter: DateFilter,
  },
  // Check-out time
  {
    Header: SortingHeader("Check-out time"),
    accessor: DateAccessor("checkOutTime"),
    id: "checkOutTime",
    Filter: DateFilter,
  },
  //
  // channel code
  //
  // check in time
  //
  // check out time
  //
  // cancellation time
];
