import React from "react";
import {
  HealthCheckReport as IHealthCheckReport,
  HealthCheckReportItem,
} from "../model/health-check-report";
import { ReportItem } from "./report-item";

interface HealthCheckReportProps {
  report: Partial<IHealthCheckReport>;
}

export enum HealthCheckOrigin {
  APALEO_INVENTORY = "APALEO_INVENTORY",
  APALEO_BOOKING = "APALEO_BOOKING",
  APALEO_WEBHOOK = "APALEO_WEBHOOK",
  APALEO_FINANCE = "APALEO_FINANCE",
  APALEO_RATEPLAN = "APALEO_RATEPLAN",
  MESSAGEBIRD_WEBHOOK = "MESSAGEBIRD_WEBHOOK",
  STAY_KOOOOK_WEBSITE = "STAY_KOOOOK_WEBSITE",
  ADYEN = "ADYEN",
  FOUR_SUITES = "FOUR_SUITES",
  FRESHDESK = "FRESHDESK",
  PRISMIC = "PRISMIC",
  SALTO = "SALTO",
  LEONECK_WEBSITE = "LEONECK_WEBSITE",
  FLEMLODGE_WEBSITE = "FLEMLODGE_WEBSITE",
  AMARIS_WEBSITE = "AMARIS_WEBSITE",
  LIKE_MAGIC_WEBSITE = "LIKE_MAGIC_WEBSITE",
  ANSTATT_WEBSITE = "ANSTATT_WEBSITE",
  GUESTHOUSE_ZURZACH_WEBSITE = "GUESTHOUSE_ZURZACH_WEBSITE",
  URBM_WEBSITE = "URBM_WEBSITE",
  MMNT_WEBSITE = "MMNT_WEBSITE",
  CONTI_WEBSITE = "CONTI_WEBSITE",
  SMARTHOTEL_WEBSITE = "SMARTHOTEL_WEBSITE",
  STAY_BRIGHT_WEBSITE = "STAY_BRIGHT_WEBSITE",
  WELCOME_HOMES_WEBSITE = "WELCOME_HOMES_WEBSITE",
  SCHANI_WEBSITE = "SCHANI_WEBSITE",
  RIVA_WEBSITE = "RIVA_WEBSITE",
  PRINCESS_WEBSITE = "PRINCESS_WEBSITE",
  PENSIONAROSA_WEBSITE = "PENSIONAROSA_WEBSITE",
  DEVICE = "DEVICE",
  SALTO_SPACE = "SALTO_SPACE",
}

function getOriginLabel(origin: string | undefined): string {
  switch (origin) {
    case HealthCheckOrigin.APALEO_INVENTORY:
      return "Apaleo Inventory API";
    case HealthCheckOrigin.APALEO_BOOKING:
      return "Apaleo Booking API";
    case HealthCheckOrigin.APALEO_WEBHOOK:
      return "Apaleo Webhook API";
    case HealthCheckOrigin.APALEO_FINANCE:
      return "Apaleo Finance API";
    case HealthCheckOrigin.MESSAGEBIRD_WEBHOOK:
      return "MessageBird Conversation API";
    case HealthCheckOrigin.STAY_KOOOOK_WEBSITE:
      return "StayKooook Website";
    case HealthCheckOrigin.LEONECK_WEBSITE:
      return "Leoneck Website";
    case HealthCheckOrigin.FLEMLODGE_WEBSITE:
      return "Flemlodge Website";
    case HealthCheckOrigin.AMARIS_WEBSITE:
      return "Amaris Website";
    case HealthCheckOrigin.LIKE_MAGIC_WEBSITE:
      return "likeMagic Website";
    case HealthCheckOrigin.ANSTATT_WEBSITE:
      return "Anstatt Website";
    case HealthCheckOrigin.GUESTHOUSE_ZURZACH_WEBSITE:
      return "Guesthouse Zurzach Website";
    case HealthCheckOrigin.URBM_WEBSITE:
      return "URBM Website";
    case HealthCheckOrigin.MMNT_WEBSITE:
      return "MM:NT Website";
    case HealthCheckOrigin.CONTI_WEBSITE:
      return "Conti Website";
    case HealthCheckOrigin.SMARTHOTEL_WEBSITE:
      return "Smarthotel Website";
    case HealthCheckOrigin.STAY_BRIGHT_WEBSITE:
      return "Stay Bright Website";
    case HealthCheckOrigin.WELCOME_HOMES_WEBSITE:
      return "Welcome Homes Website";
    case HealthCheckOrigin.SCHANI_WEBSITE:
      return "Schani Hotels Website";
    case HealthCheckOrigin.RIVA_WEBSITE:
      return "Riva Boutique Website";
    case HealthCheckOrigin.PRINCESS_WEBSITE:
      return "Hotel Princess Website";
    case HealthCheckOrigin.PENSIONAROSA_WEBSITE:
      return "Pension Arosa Website";
    case HealthCheckOrigin.ADYEN:
      return "Adyen";
    case HealthCheckOrigin.FOUR_SUITES:
      return "4Suites";
    case HealthCheckOrigin.FRESHDESK:
      return "Freshdesk";
    case HealthCheckOrigin.PRISMIC:
      return "Prismic.io";
    case HealthCheckOrigin.APALEO_RATEPLAN:
      return "Apaleo Rateplan API";
    case HealthCheckOrigin.SALTO:
      return "Salto";
    case HealthCheckOrigin.DEVICE:
      return "Kiosk";
    case HealthCheckOrigin.SALTO_SPACE:
      return "Salto Space SHIP Interface";
    default:
      return "Unknown healthcheck-type";
  }
}

function getLabel(
  reportItem: HealthCheckReportItem | null | undefined
): string {
  const detailsSuffix = reportItem?.details
    ? " " +
      reportItem.details.deviceName +
      " (" +
      reportItem.details.deviceId +
      ")"
    : "";
  return getOriginLabel(reportItem?.origin) + detailsSuffix;
}

export const HealthCheckReport: React.FC<HealthCheckReportProps> = ({
  report,
}) => {
  return (
    <div>
      {Object.keys(report)
        .sort()
        .map((reportKey) => (
          <div key={reportKey}>
            <h3>{getLabel(report[reportKey as keyof typeof report])}</h3>
            <ReportItem data={report[reportKey as keyof typeof report]} />
          </div>
        ))}
    </div>
  );
};
