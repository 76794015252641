import React, { useEffect } from "react";
import { useOperationPage } from "../operation/hooks/use-operation-page";
import { LoadingShadow } from "../components/loading-shadow";
import { Loading } from "../components/loading";
import { OperationTable } from "../operation/components/operation-table";

export const OperationPage: React.FC = () => {
  const {
    data,
    fetchData,
    idCheckDialogOpened,
    selectedReservation,
    signatureDocument,
    identificationDocument,
    handleIdCheckDialogOpening,
    handleIdCheckDialogClosing,
    handleUpdateIdCheckStatus,
    loading,
    sortingRule,
    handleChangePage,
    handleFilterChange,
    handleSortChange,
    handleChangeRowsPerPage,
    searchParams,
  } = useOperationPage();

  useEffect(() => {
    fetchData(
      searchParams.page,
      searchParams.rowsPerPage,
      sortingRule,
      searchParams.filterParams
    );
  }, [
    fetchData,
    searchParams.page,
    searchParams.rowsPerPage,
    searchParams.filterParams,
    sortingRule,
  ]);

  if (!data) {
    return <Loading />;
  }

  return (
    <LoadingShadow loading={loading}>
      <OperationTable
        data={data}
        handleFilterChange={handleFilterChange}
        handleSortChange={handleSortChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        handleIdCheckDialogOpening={handleIdCheckDialogOpening}
        idCheckDialogOpened={idCheckDialogOpened}
        handleIdCheckDialogClosing={handleIdCheckDialogClosing}
        selectedReservation={selectedReservation}
        signatureDocument={signatureDocument}
        identificationDocument={identificationDocument}
        handleUpdateIdCheckStatus={handleUpdateIdCheckStatus}
      />
    </LoadingShadow>
  );
};
