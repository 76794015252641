import React from "react";
import { Typography, Box } from "@mui/material";
import { TabsTemplate } from "../components/tabs-template/tabs-template";
import { GuestIdentities } from "../record-matching/components/guest-identities";
import { Persons } from "../record-matching/components/persons";

export const RecordMatchingPage: React.FC = () => {
  return (
    <Box>
      <Typography variant="h4" noWrap>
        Record Matching
      </Typography>
      <TabsTemplate
        tabConfigs={[
          {
            label: "Guest Identities",
            path: "guest-identities",
            components: [
              {
                comp: <GuestIdentities />,
                key: "guest-identities",
              },
            ],
          },
          {
            label: "Persons",
            path: "persons",
            components: [
              {
                comp: <Persons />,
                key: "persons",
              },
            ],
          },
        ]}
        defaultPath="guest-identities"
      />
    </Box>
  );
};
