import { Grid, Modal, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { MagicFileData } from "../model/magic-file-data";
import { Reservation } from "../model/reservation";

const useStyles = makeStyles(() => ({
  images: {
    height: 216,
    width: 307,
    objectFit: "contain",
  },
  labels: {
    width: 307,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalImage: {
    maxHeight: 1250,
    maxWidth: 1250,
  },
}));

export const PersonalDataView: React.FC<{
  reservation: Reservation;
  signature: MagicFileData;
  identification: MagicFileData;
}> = ({ reservation, signature, identification }) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        className={classes.modal}
      >
        <img
          src={identification?.fileSrc}
          alt="Identification document"
          className={classes.modalImage}
        />
      </Modal>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item>
          <img
            src={identification?.fileSrc}
            alt="Identification document"
            className={classes.images}
            onClick={() => setOpenModal(true)}
          />
          <br />
          <br />
          <TextField
            label="Gender"
            className={classes.labels}
            value={reservation?.primaryGuest?.gender || "-"}
            InputProps={{
              readOnly: true,
            }}
          />
          <br />
          <br />
          <TextField
            label="First name"
            className={classes.labels}
            value={reservation?.primaryGuest?.firstName || "-"}
            InputProps={{
              readOnly: true,
            }}
          />
          <br />
          <br />
          <TextField
            label="Last name"
            className={classes.labels}
            value={reservation?.primaryGuest?.lastName || "-"}
            InputProps={{
              readOnly: true,
            }}
          />
          <br />
          <br />
          <TextField
            label="Date of birth"
            className={classes.labels}
            value={reservation?.primaryGuest?.birthdate || "-"}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item>
          <img
            src={signature?.fileSrc}
            alt="Signature document"
            className={classes.images}
          />
          <br />
          <br />
          <TextField
            label="Nationality"
            className={classes.labels}
            value={reservation?.primaryGuest?.nationalityCountryCode || "-"}
            InputProps={{
              readOnly: true,
            }}
          />
          <br />
          <br />
          <TextField
            label="Identification number"
            className={classes.labels}
            value={reservation?.primaryGuest?.identificationNumber || "-"}
            InputProps={{
              readOnly: true,
            }}
          />
          <br />
          <br />
          <TextField
            label="Phone number"
            className={classes.labels}
            value={reservation?.primaryGuest?.phone || "-"}
            InputProps={{
              readOnly: true,
            }}
          />
          <br />
          <br />
          <TextField
            label="e-mail"
            className={classes.labels}
            value={reservation?.primaryGuest?.email || "-"}
            InputProps={{
              readOnly: true,
            }}
          />
          <br />
          <br />
        </Grid>
      </Grid>
    </>
  );
};
