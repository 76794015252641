import React, { useCallback, useEffect, useRef } from "react";
import { Reservation } from "../model/reservation";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Page } from "../../core/page";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { operationsTableColumns } from "../config/operation-table-config";
import { useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ApaleoIcon } from "../../components/apaleo-icon";
import { AproveIdIcon } from "../../components/approve-id-icon";
import { IdConfirmationDialog } from "./id-confirmation-dialog";
import { MagicFileData } from "../model/magic-file-data";
import { ExpandingRow } from "../../components/table/expanding-row";
import { JsonTreeView } from "../../components/json-tree-view";

interface OperationTableProps {
  data: Page<Reservation>;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  handleFilterChange: (event: any) => void;
  handleSortChange: (sort: { id: string; desc?: boolean }[]) => void;
  handleIdCheckDialogOpening: (reservation: Reservation) => void;
  handleIdCheckDialogClosing: () => void;
  handleUpdateIdCheckStatus: (reservationId: string, status: string) => void;
  idCheckDialogOpened: boolean;
  selectedReservation: Reservation;
  signatureDocument: MagicFileData;
  identificationDocument: MagicFileData;
}

const apaleoUrl: string =
  "http://app.apaleo.com/{propertyId}/reservations/{reservationId}";

export const OperationTable: React.FC<OperationTableProps> = (params) => {
  const {
    data,
    handleSortChange,
    handleChangeRowsPerPage,
    handleChangePage,
    handleFilterChange,
    handleIdCheckDialogClosing,
    handleIdCheckDialogOpening,
    handleUpdateIdCheckStatus,
    idCheckDialogOpened,
    selectedReservation,
    signatureDocument,
    identificationDocument,
  } = params;

  const didMountRef = useRef(false);

  const handleChangePageAdapter = useCallback(
    (event: any, newPage: number) => handleChangePage(newPage),
    [handleChangePage]
  );

  const handleChangeRowsPerPageAdapter = useCallback(
    (event: any) => handleChangeRowsPerPage(parseInt(event.target.value, 10)),
    [handleChangeRowsPerPage]
  );

  const handleFilterChangeAdapter = useAsyncDebounce(
    (filtersObject) => handleFilterChange(filtersObject),
    300
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, filters },
  } = useTable(
    {
      columns: operationsTableColumns,
      data: data.content,
      manualFilters: true,
      manualSortBy: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (didMountRef.current) {
      handleSortChange(sortBy);
    }
  }, [sortBy, handleSortChange]);
  useEffect(() => {
    if (didMountRef.current) {
      handleFilterChangeAdapter(filters);
    }
  }, [filters, handleFilterChangeAdapter]);

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  interface PrepareIconProps {
    openLink: () => void;
    icon: any;
    tooltip: string;
  }

  const prepareIconsData = useCallback(
    (reservation: Reservation): any => {
      const arrayOfClickableIcons: Array<PrepareIconProps> = [];

      arrayOfClickableIcons.push({
        openLink: () => {
          navigate(`/reservations/${reservation.id}`);
        },
        icon: <OpenInNewIcon />,
        tooltip: "Open Reservation",
      });
      arrayOfClickableIcons.push({
        openLink: () => {
          window.open(
            apaleoUrl
              .replace("{reservationId}", reservation.id.toString())
              .replace("{propertyId}", reservation.propertyId)
          );
        },
        icon: <ApaleoIcon viewBox="0 0 564 564" />,
        tooltip: "Open apaleo",
      });

      if (reservation.files?.length >= 2) {
        arrayOfClickableIcons.push({
          openLink: () => {
            handleIdCheckDialogOpening(reservation);
          },
          icon: <AproveIdIcon />,
          tooltip: "Confirm ID",
        });
      }

      return arrayOfClickableIcons;
    },
    [handleIdCheckDialogOpening, navigate]
  );

  return (
    <>
      <Box mb={2} />

      <IdConfirmationDialog
        close={handleIdCheckDialogClosing}
        reservation={selectedReservation}
        open={idCheckDialogOpened}
        signatureDocument={signatureDocument}
        identificationDocument={identificationDocument}
        idCheckAction={handleUpdateIdCheckStatus}
      />
      <Paper>
        <TableContainer>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell {...column.getHeaderProps()}>
                      <>
                        {column.render("Header")}
                        <br />
                        {column.canFilter ? column.render("Filter", {}) : null}
                      </>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <ExpandingRow
                    {...row.getRowProps()}
                    key={"" + row.getRowProps().key + row.original.id}
                    renderSummary={() => {
                      const tail = row.cells.slice(1);
                      return tail.map((cell) => {
                        return (
                          <TableCell
                            {...cell.getCellProps()}
                            style={{ textAlign: "center" }}
                          >
                            <Typography noWrap>
                              <>{cell.render("Cell")}</>
                            </Typography>
                          </TableCell>
                        );
                      });
                    }}
                    renderDetails={() => (
                      <Box p={2}>
                        <JsonTreeView data={row.original} />
                      </Box>
                    )}
                    icons={prepareIconsData(row.original)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.totalElements}
          rowsPerPage={data.size}
          page={data.number}
          onPageChange={handleChangePageAdapter}
          onRowsPerPageChange={handleChangeRowsPerPageAdapter}
        />
      </Paper>
    </>
  );
};
