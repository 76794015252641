import { Column } from "react-table";
import { GuestIdentityWithPersonCount } from "../model/guest-identity";
import {
  IdentityAccessor,
  DateAccessor,
} from "../../components/table/table-accessors";
import {
  SortingHeader,
  LabelHeader,
} from "../../components/table/table-headers";

export const guestIdentitiesTableColumns: Column<GuestIdentityWithPersonCount>[] =
  [
    {
      Header: SortingHeader("Last Updated"),
      accessor: DateAccessor("updatedAt"),
      id: "updatedAt",
    },
    {
      Header: SortingHeader("Persons"),
      accessor: IdentityAccessor("persons"),
      id: "persons",
    },
    {
      Header: LabelHeader("First Name"),
      accessor: IdentityAccessor("firstName"),
      id: "firstName",
    },
    {
      Header: LabelHeader("Last Name"),
      accessor: IdentityAccessor("lastName"),
      id: "lastName",
    },
    {
      Header: LabelHeader("Email"),
      accessor: IdentityAccessor("email"),
      id: "email",
    },
    {
      Header: LabelHeader("Phone"),
      accessor: IdentityAccessor("phone"),
      id: "phone",
    },
    {
      Header: LabelHeader("City"),
      accessor: IdentityAccessor("city"),
      id: "city",
    },
    {
      Header: LabelHeader("Address Line 1"),
      accessor: IdentityAccessor("addressLine1"),
      id: "addressLine1",
    },
  ];
