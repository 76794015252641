export interface Notification {
  reservationId: string;
  bookingId: string;
  createdAt: Date;
  updatedAt: Date;
  timestamp: Date;
  messages: any[] | null;
}

export function NotificationFromJSON(json: any): Notification {
  if (!json) {
    return json;
  }

  return {
    reservationId: json["reservationId"],
    bookingId: json["bookingId"],
    createdAt: new Date(json["createdAt"]),
    updatedAt: new Date(json["updatedAt"]),
    timestamp: new Date(json["timestamp"]),
    messages: json["messages"] || null,
  };
}
