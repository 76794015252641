export interface MessageBirdV2Subscription {
  id: string;
  url: string;
  workspaceId: string;
  event: string;
  status: string;
  eventFilters: string[];
  createdDatetime: Date;
  updatedDatetime: Date;
}

export const MessageBirdSV2ubscriptionFromJSON = (
  json: any
): MessageBirdV2Subscription => {
  if (!json) {
    return json;
  }

  return {
    id: json["id"],
    url: json["url"],
    workspaceId: json["workspaceId"],
    event: json["event"],
    status: json["status"],
    eventFilters: json["eventFilters"],
    createdDatetime: new Date(json["createdDatetime"]),
    updatedDatetime: new Date(json["updatedDatetime"]),
  };
};
