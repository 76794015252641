import { FC, useEffect } from "react";

export const FaviconSetter: FC<React.PropsWithChildren<unknown>> = () => {
  useEffect(() => {
    const faviconUpdate = async () => {
      const favicon32: any = document.getElementById("favicon-32");
      const favicon16: any = document.getElementById("favicon-16");
      favicon16.href = `${window.location.origin}/likemagic/favicon-16x16.png`;
      favicon32.href = `${window.location.origin}/likemagic/favicon-32x32png`;
    };
    faviconUpdate();
  }, []);

  return null;
};
