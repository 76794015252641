import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TooltipTypography } from "../../components/tooltip-typography";

const useStyles = makeStyles(() => ({
  labeledText: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  label: {
    fontWeight: "bold",
    fontSize: 14,
  },
  text: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: 16,
  },
}));

interface Props {
  label: string;
  text: string;
}

export const LabeledText: FC<Props> = ({ label, text }): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.labeledText}>
      <Typography component="span" className={classes.label}>
        {label}
      </Typography>
      <TooltipTypography
        component="span"
        tooltipText={text}
        TypographyProps={{ className: classes.text }}
      >
        {text}
      </TooltipTypography>
    </Box>
  );
};
