import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SubscriptionAccessorFn } from "./subscription-accessor-fn";
import { useAuth, ADMIN_ROLE } from "@likemagic-tech/sv-magic-library";

export interface SubscriptionListAccessors<T = any> {
  id: SubscriptionAccessorFn<T>;
  subject: SubscriptionAccessorFn<T>;
  events: SubscriptionAccessorFn<T>;
  endpointUrl: SubscriptionAccessorFn<T>;
}

interface Props {
  subscriptions: any[];
  onDelete: (id: string) => void;
  accessors: SubscriptionListAccessors;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export const SubscriptionList: React.FC<Props> = ({
  subscriptions,
  onDelete,
  accessors,
}) => {
  const classes = useStyles();
  const auth = useAuth();

  return (
    <>
      {subscriptions.map((subscription) => (
        <Card key={subscription.id} variant="outlined" className={classes.root}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {accessors.id(subscription)}
            </Typography>
            <Typography variant="h5" component="h2">
              {accessors.subject(subscription)}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {accessors.events(subscription)}
            </Typography>
            <Typography variant="body2" component="p">
              <b>Endpoint URL:</b>
              <br />
              {accessors.endpointUrl(subscription)}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              variant="outlined"
              onClick={() => onDelete(subscription.id)}
              disabled={!auth.hasRole(ADMIN_ROLE)}
            >
              Unsubscribe
            </Button>
          </CardActions>
        </Card>
      ))}
    </>
  );
};
