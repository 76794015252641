import React from "react";
import { Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

interface PageTitleProps {
  totalConflicts?: number;
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export const PageTitle: React.FC<PageTitleProps> = ({ totalConflicts }) => {
  const classes = useStyles();
  return (
    <Typography component="h1" variant="h5" noWrap className={classes.title}>
      Conflict Resolution
      {totalConflicts && totalConflicts > 0 && ` (${totalConflicts} left)`}
    </Typography>
  );
};
