import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Loading } from "../../components/loading";
import { usePageableFetch } from "../hooks/use-pageable-fetch";
import { LoadingShadow } from "../../components/loading-shadow";
import { SearchBar } from "./search-bar";
import { Person, PersonFromJSON } from "../model/person";
import { PersonsTable } from "./persons-table";
import { personsWithStatusTableColumns } from "../config/persons-table-config";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  searchBar: {
    marginBottom: theme.spacing(2),
  },
}));

export const Persons: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    data,
    loading,
    searchValue,
    handlePageNumberChange,
    handlePageSizeChange,
    handleSortChange,
    handleFilterChange,
    fetchData,
    handleSearchChange,
  } = usePageableFetch<Person>("persons", PersonFromJSON);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRecordConflictSelected = useCallback(
    (person: Person) => {
      navigate(`/record-matching/conflict-resolution/${person.id}`);
    },
    [navigate]
  );

  if (!data) {
    return <Loading />;
  }

  return (
    <Box>
      <SearchBar
        className={classes.searchBar}
        value={searchValue}
        onChange={handleSearchChange}
        placeholder="Find Persons..."
      />
      <LoadingShadow loading={loading}>
        <PersonsTable
          columns={personsWithStatusTableColumns}
          data={data}
          onPageNumberChange={handlePageNumberChange}
          onPageSizeChange={handlePageSizeChange}
          onSortChange={handleSortChange}
          onFilterChange={handleFilterChange}
          onRecordConflictSelection={handleRecordConflictSelected}
        />
      </LoadingShadow>
    </Box>
  );
};
