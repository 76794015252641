import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import { SubscriptionAccessorFn } from "./subscription-accessor-fn";

export interface WebhookSubscriptionFormState<Event> {
  subjectId: string;
  events: Event[];
}

export interface WebhookSubscriptionFormChange<Event> {
  value: WebhookSubscriptionFormState<Event>;
  isValid: boolean;
}

export interface WebhookSubscriptionFormAccessors<Subject> {
  subjectLabel: (subject: Subject) => string;
  subjectId: SubscriptionAccessorFn<Subject>;
  subjectFormLabel: () => string;
  eventsFormLabel: () => string;
}

export interface WebhookSubscriptionFormProps<Subject, Event> {
  subjects: Subject[];
  events: string[];
  onChange: (change: WebhookSubscriptionFormChange<Event>) => any;
  accessors: WebhookSubscriptionFormAccessors<Subject>;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(item: any, selectedCollection: any[], theme: Theme) {
  return {
    fontWeight:
      selectedCollection.indexOf(item) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function WebhookSubscriptionForm<Subject, Event>({
  subjects,
  events: eventsInput,
  onChange,
  accessors,
}: WebhookSubscriptionFormProps<Subject, Event>): React.ReactElement<
  WebhookSubscriptionFormProps<Subject, Event>
> {
  const classes = useStyles();

  const theme = useTheme();

  const [state, setState] = useState<WebhookSubscriptionFormState<Event>>({
    subjectId: "",
    events: [],
  });

  const { subjectId, events } = state;

  const handleSubjectChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setState((s) => ({ ...s, subjectId: event.target.value as string }));
    },
    []
  );

  const handleEventsChange = useCallback(
    (event: SelectChangeEvent<Event[]>) => {
      setState((s) => ({ ...s, events: event.target.value as Event[] }));
    },
    []
  );

  const isValid = useMemo(() => {
    return subjectId.length > 0 && events.length > 0;
  }, [subjectId, events]);

  useEffect(() => {
    onChange({ value: state, isValid });
  }, [state, isValid, onChange]);

  return (
    <div>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="subjectId">{accessors.subjectFormLabel()}</InputLabel>
          <Select
            labelId="subjectId"
            value={subjectId}
            onChange={handleSubjectChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {subjects.map((subject) => (
              <MenuItem
                key={accessors.subjectId(subject)}
                value={accessors.subjectId(subject)}
              >
                {accessors.subjectLabel(subject)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="events">{accessors.eventsFormLabel()}</InputLabel>
          <Select
            labelId="events"
            multiple
            value={events}
            onChange={handleEventsChange}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={`event-${value}`}
                    label={<>{value}</>}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {eventsInput.map((event) => (
              <MenuItem
                key={event}
                value={event}
                style={getStyles(event, events, theme)}
              >
                {event}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
