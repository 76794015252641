import React, { useEffect } from "react";
import { MagicEventsTable } from "../magic-events/components/magic-events-table";
import { Loading } from "../components/loading";
import { useMagicEventsPage } from "../magic-events/hooks/use-magic-events-page";
import { LoadingShadow } from "../components/loading-shadow";

export const MagicEventsPage: React.FC = () => {
  const {
    data,
    fetchData,
    loading,
    page,
    rowsPerPage,
    sortingRule,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilterChange,
    handleSortChange,
    debouncedObjectId,
    debouncedReservationId,
  } = useMagicEventsPage();

  useEffect(() => {
    fetchData(
      page,
      rowsPerPage,
      sortingRule,
      debouncedObjectId,
      debouncedReservationId
    );
  }, [
    fetchData,
    page,
    rowsPerPage,
    debouncedObjectId,
    debouncedReservationId,
    sortingRule,
  ]);

  if (!data) {
    return <Loading />;
  }

  return (
    <LoadingShadow loading={loading}>
      <MagicEventsTable
        data={data}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleFilterChange={handleFilterChange}
        handleSortChange={handleSortChange}
      />
    </LoadingShadow>
  );
};
