import { useAuth } from "@likemagic-tech/sv-magic-library";
import { Loading } from "../components/loading";
import makeStyles from "@mui/styles/makeStyles";
import { ReservationFinder } from "../reservation/components/reservations-finder";
import { ForbiddenPage } from "./forbidden.page";
import Logo from "../components/logo/Logo";

const useStyles = makeStyles(() => ({
  welcomeMessage: {
    textAlign: "center",
  },
}));

export const HomePage = () => {
  const auth = useAuth();
  const classes = useStyles();

  if (auth.authenticated && !auth.hasAdminOrOperatorRole()) {
    return <ForbiddenPage />;
  }

  return (
    <div className="App">
      <header className="App-header">
        <Logo isWhite={true} />
      </header>
      {!auth.initialized && <Loading />}
      {auth.authenticated && (
        <>
          <h3 className={classes.welcomeMessage}>Hello!</h3>
          <ReservationFinder />
        </>
      )}
      {!auth.authenticated && (
        <h3 className={classes.welcomeMessage}>Welcome, guest!</h3>
      )}
    </div>
  );
};
