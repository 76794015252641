import React, { useCallback, useEffect, useState } from "react";
import { Loading } from "../loading";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { WebhookSubscriptionFormChange } from "./webhook-subscription-form";

interface CreateWebhookSubscriptionProps<Subject, Event> {
  show: boolean;
  onHide: (reason?: "submitted") => void;
  fetchSubjects: () => Promise<Subject[]>;
  fetchEvents: () => Promise<Event[]>;
  createSubscription: (formValue: {
    subjectId: string;
    events: Event[];
  }) => Promise<void>;
  formComp: React.FC<any>;
}

export function CreateWebhookSubscription<S, E>(
  props: CreateWebhookSubscriptionProps<S, E>
): React.ReactElement<CreateWebhookSubscriptionProps<S, E>> {
  const { show, onHide, fetchSubjects, fetchEvents, createSubscription } =
    props;

  const [subjects, setSubjects] = useState<S[]>([]);
  const [events, setEvents] = useState<E[]>([]);
  const [formState, setFormState] =
    useState<WebhookSubscriptionFormChange<E>>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!show) {
      return;
    }
    const init = async () => {
      try {
        setLoading(true);
        const [subjectsResponse, eventsResponse] = await Promise.all([
          fetchSubjects(),
          fetchEvents(),
        ]);
        setSubjects(subjectsResponse);
        setEvents(eventsResponse);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    init();
  }, [show, fetchSubjects, fetchEvents]);

  const handleFormChange = useCallback(
    (change: WebhookSubscriptionFormChange<E>) => setFormState(change),
    []
  );

  const submit = useCallback(async () => {
    if (!formState) {
      return;
    }

    try {
      setLoading(true);
      await createSubscription(formState.value);
      onHide("submitted");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [formState, onHide, createSubscription]);

  return (
    <Dialog open={show} onClose={() => onHide()}>
      <DialogTitle>Create subscription</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <props.formComp
            subjects={subjects}
            events={events}
            onChange={handleFormChange}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={submit}
          disabled={!formState?.isValid || loading}
        >
          Subscribe
        </Button>
      </DialogActions>
    </Dialog>
  );
}
