import { Column } from "react-table";
import { MagicEvent } from "../model/magic-event";
import {
  DateAccessor,
  IdentityAccessor,
} from "../../components/table/table-accessors";
import {
  LabelHeader,
  SortingHeader,
} from "../../components/table/table-headers";

export const magicEventTableColumns: Column<MagicEvent>[] = [
  { Header: LabelHeader("Actions"), id: "actions" },
  { Header: SortingHeader("ID"), accessor: IdentityAccessor("id"), id: "id" },
  {
    Header: SortingHeader("Timestamp"),
    accessor: DateAccessor("timestamp"),
    id: "timestamp",
  },
  {
    Header: SortingHeader("Origin"),
    accessor: IdentityAccessor("origin"),
    id: "origin",
  },
  {
    Header: SortingHeader("Topic"),
    accessor: IdentityAccessor("topic"),
    id: "topic",
  },
  {
    Header: SortingHeader("Type"),
    accessor: IdentityAccessor("type"),
    id: "type",
  },
  {
    Header: SortingHeader("State"),
    accessor: IdentityAccessor("state"),
    id: "state",
  },
  {
    Header: SortingHeader("ID Origin"),
    accessor: IdentityAccessor("idOrigin"),
    id: "idOrigin",
  },
];
