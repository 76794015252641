import React from "react";
import { TableCell, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ColumnInstance } from "react-table";
import { Person } from "../model/person";
import { BaseIdentity } from "../model/base-identity";

interface PersonRowProps {
  person: Person;
  columns: ColumnInstance<BaseIdentity>[];
}

const useStyles = makeStyles(() => ({
  tableSubtitle: {
    fontWeight: "bold",
    fontSize: 16,
  },
}));

export const PersonRow: React.FC<PersonRowProps> = ({ person, columns }) => {
  const classes = useStyles();
  return (
    <>
      <TableRow>
        <TableCell colSpan={17} className={classes.tableSubtitle}>
          Person
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell />
        {columns.map((column) => {
          return (
            <TableCell key={"person" + column.id}>
              <>{person[column.id as keyof Person]}</>{" "}
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};
