import React from "react";
import {
  SubscriptionListAccessors,
  WebhooksPage,
} from "../../components/webhook-subscriptions";
import { MessageBirdWebhookSubscriptionForm } from "../components/messagebird-webhook-subscription-form";
import { MessageBirdV2Api } from "../api/messagebirdV2.api";
import { useMessageBirdV2WebhooksPage } from "../hooks/use-messagebird-v2-webhooks-page";
import { MessageBirdV2Subscription } from "../model/messagebird-v2-subscription";

const subscriptionListAccessors: SubscriptionListAccessors<MessageBirdV2Subscription> =
  {
    id: (subscription) => subscription.id,
    subject: (subscription) => subscription.workspaceId,
    events: (subscription) => [subscription.event],
    endpointUrl: (subscription) => subscription.url,
  };

export const MessageBirdV2WebhooksPage: React.FC = () => {
  const { subscriptions, fetchSubscriptions, removeSubscription, loading } =
    useMessageBirdV2WebhooksPage();

  return (
    <WebhooksPage
      subscriptions={subscriptions}
      fetchSubscriptions={fetchSubscriptions}
      removeSubscription={removeSubscription}
      loading={loading}
      subscriptionListAccessors={subscriptionListAccessors}
      fetchSubjects={MessageBirdV2Api.getChannels.bind(MessageBirdV2Api)}
      fetchEvents={MessageBirdV2Api.getEvents.bind(MessageBirdV2Api)}
      createSubscription={({ subjectId, events }) =>
        MessageBirdV2Api.createSubscription({
          channelId: subjectId,
          events: events as unknown as string[],
        })
      }
      formComp={MessageBirdWebhookSubscriptionForm}
    />
  );
};
