import React from "react";
import {
  WebhookSubscriptionForm,
  WebhookSubscriptionFormAccessors,
  WebhookSubscriptionFormProps,
} from "../../components/webhook-subscriptions";
import { MessageBirdConversationChannel } from "../model/messagebird-conversation-channel";

const accessors: WebhookSubscriptionFormAccessors<MessageBirdConversationChannel> =
  {
    subjectLabel: (subject) => subject.name,
    subjectId: (subject) => subject.id,
    subjectFormLabel: () => "Channel",
    eventsFormLabel: () => "Events",
  };

export const MessageBirdWebhookSubscriptionForm: React.FC<
  WebhookSubscriptionFormProps<MessageBirdConversationChannel, string>
> = ({ subjects, events, onChange }) => {
  return (
    <WebhookSubscriptionForm
      subjects={subjects}
      events={events}
      onChange={onChange}
      accessors={accessors}
    />
  );
};
