import { parseDate } from "../util/date-util";

export interface BaseIdentity {
  id: number;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  title: string | null;
  gender: string | null;
  birthdate: string | null;
  city: string | null;
  postalCode: string | null;
  countryCode: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  preferredLanguage: string | null;
  identificationType: string | null;
  identificationNumber: string | null;
  nationalityCountryCode: string | null;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export function BaseIdentityFieldsFromJSON(json: any): any {
  if (!json) {
    return json;
  }

  return {
    firstName: json["firstName"],
    lastName: json["lastName"],
    email: json["email"],
    phone: json["phone"],
    title: json["title"],
    gender: json["gender"],
    birthdate: json["birthdate"],
    city: json["city"],
    postalCode: json["postalCode"],
    countryCode: json["countryCode"],
    addressLine1: json["addressLine1"],
    addressLine2: json["addressLine2"],
    preferredLanguage: json["preferredLanguage"],
    identificationType: json["identificationType"],
    identificationNumber: json["identificationNumber"],
    nationalityCountryCode: json["nationalityCountryCode"],
  };
}

export function BaseIdentityFromJSON(json: any): any {
  if (!json) {
    return json;
  }

  return {
    id: json["id"],
    ...BaseIdentityFieldsFromJSON(json),
    createdAt: parseDate(json["createdAt"]),
    updatedAt: parseDate(json["updatedAt"]),
  };
}
