import { useCallback, useState } from "react";
import { SortingRule } from "react-table";
import { Page } from "../../core/page";
import { FilterObject } from "../../api/search-criteria-parsing";
import { GuestIdentityApi } from "../api/guest-identity.api";

interface SearchableParams {
  filters: FilterObject[];
  pageNumber: number;
  pageSize: number;
}

interface UsePageableFetch<T> {
  data: Page<T> | undefined;
  loading: boolean;
  pageNumber: number;
  pageSize: number;
  filters: any[];
  sortingRules: SortingRule<T>[];
  searchValue: string;
  fetchData: () => Promise<void>;
  handlePageNumberChange: (pageNumber: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortChange: (sort: SortingRule<T>[]) => void;
  handleFilterChange: (filters: any[]) => void;
  handleSearchChange: (value: string) => void;
}

export const usePageableFetch: <T>(
  url: string,
  responseMapper: (response: any) => T
) => UsePageableFetch<T> = <T>(
  url: string,
  responseMapper: (response: any) => T
) => {
  const [data, setData] = useState<Page<T>>();
  const [sortingRules, setSortingRules] = useState<SortingRule<T>[]>([
    { id: "updatedAt", desc: true },
  ]);
  const [searchParams, setSearchParams] = useState<SearchableParams>({
    pageNumber: 0,
    pageSize: 10,
    filters: [],
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handlePageNumberChange = useCallback((newPageNumber: number) => {
    setSearchParams((prevState) => ({
      ...prevState,
      pageNumber: newPageNumber,
    }));
  }, []);

  const handlePageSizeChange = useCallback((newPageSize: number) => {
    setSearchParams((prevState) => ({
      ...prevState,
      pageSize: newPageSize,
      pageNumber: 0,
    }));
  }, []);

  const handleSortChange = useCallback((sort: SortingRule<T>[]) => {
    // ensure stable sort with additional sort field
    const rules =
      sort.length > 0 && sort[0].id !== "updatedAt"
        ? [...sort, { id: "updatedAt", desc: true }]
        : sort;
    setSortingRules(rules);
  }, []);

  const handleFilterChange = useCallback((newFilters: any[]) => {
    setSearchParams((prevState) => ({
      ...prevState,
      pageNumber: 0,
      filters: newFilters,
    }));
  }, []);

  const handleSearchChange = useCallback((value: string) => {
    setSearchParams((prevState) => ({
      ...prevState,
      pageNumber: 0,
    }));
    setSearchValue(value);
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const freeTextSearchFilterParams: Array<FilterObject> = searchValue
        ? searchValue.split(" ").map((searchVal) => {
            return {
              id: "freeTextSearch",
              value: { value: searchVal, type: "lax" },
            };
          })
        : [];
      const response = await GuestIdentityApi.getPagedEntities(
        url,
        responseMapper,
        {
          pageable: {
            pageNumber: searchParams.pageNumber,
            pageSize: searchParams.pageSize,
          },
          sortingRules,
          filterParams: [
            ...searchParams.filters,
            ...freeTextSearchFilterParams,
          ],
        }
      );
      setData(response);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    url,
    searchParams.filters,
    searchParams.pageNumber,
    searchParams.pageSize,
    sortingRules,
    searchValue,
    responseMapper,
  ]);

  return {
    data,
    loading,
    pageNumber: searchParams.pageNumber,
    pageSize: searchParams.pageSize,
    filters: searchParams.filters,
    sortingRules,
    searchValue,
    handlePageNumberChange,
    handlePageSizeChange,
    handleSortChange,
    handleFilterChange,
    handleSearchChange,
    fetchData,
  };
};
