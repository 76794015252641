import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const BadgeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M20.5928 5.4477H14.5928V4.94331C14.5928 3.50331 13.4172 2.35107 12.0006 2.35107C10.585 2.35107 9.40835 3.52669 9.40835 4.94331V5.4477H3.40835C1.87273 5.4477 0.600586 6.69551 0.600586 8.25546V18.8632C0.600586 20.3988 1.84839 21.671 3.40835 21.671H20.6161C22.1517 21.671 23.4239 20.4232 23.4239 18.8632L23.4248 8.23194C23.4004 6.69539 22.1526 5.44746 20.5926 5.44746L20.5928 5.4477ZM10.2963 4.94425C10.2963 4.00863 11.0642 3.23987 12.0007 3.23987C12.9363 3.23987 13.7051 4.00767 13.7051 4.94425V7.43977H10.2964L10.2963 4.94425ZM22.5128 18.8643C22.5128 19.9199 21.6484 20.7599 20.6172 20.7599H3.40849C2.35287 20.7599 1.51287 19.8955 1.51287 18.8643V8.23201C1.51287 7.17639 2.37726 6.33639 3.40849 6.33639H9.40849V7.87201C9.40849 8.11201 9.60068 8.32763 9.86411 8.32763H14.1596C14.3996 8.32763 14.6152 8.13544 14.6152 7.87201V6.33639H20.6152C21.6709 6.33639 22.5109 7.17639 22.5109 8.23201L22.5128 18.8643Z"
        fill="black"
      />
      <path
        d="M19.6554 10.5352H13.3199C13.0799 10.5352 12.8643 10.7273 12.8643 10.9908C12.8643 11.2552 13.0564 11.4464 13.3199 11.4464H19.6554C19.8954 11.4464 20.111 11.2542 20.111 10.9908C20.1119 10.7273 19.9198 10.5352 19.6554 10.5352Z"
        fill="black"
      />
      <path
        d="M19.6554 14.0874H13.3199C13.0799 14.0874 12.8643 14.2796 12.8643 14.543C12.8643 14.783 13.0564 14.9986 13.3199 14.9986H19.6554C19.8954 14.9986 20.111 14.8064 20.111 14.543C20.1119 14.2796 19.9198 14.0874 19.6554 14.0874Z"
        fill="black"
      />
      <path
        d="M19.6554 17.6157H13.3199C13.0799 17.6157 12.8643 17.8079 12.8643 18.0713C12.8643 18.3113 13.0564 18.527 13.3199 18.527H19.6554C19.8954 18.527 20.111 18.3348 20.111 18.0713C20.1119 17.8079 19.9198 17.6157 19.6554 17.6157Z"
        fill="black"
      />
      <path
        d="M8.59298 14.2326C9.19298 13.8004 9.57737 13.1048 9.57737 12.3126C9.57737 10.9926 8.49737 9.9126 7.17737 9.9126C5.85737 9.9126 4.77737 10.9926 4.77737 12.3126C4.77737 13.1048 5.16175 13.8004 5.76175 14.2326C4.68175 14.8804 3.79395 16.2248 3.79395 17.9281C3.79395 18.1681 3.98613 18.3837 4.24956 18.3837C4.48956 18.3837 4.70518 18.1916 4.70518 17.9281C4.70518 16.2725 5.92956 14.7126 7.2007 14.7126C8.49631 14.7126 9.69622 16.2726 9.69622 17.9281C9.69622 18.1681 9.8884 18.3837 10.1518 18.3837C10.3918 18.3837 10.6074 18.1916 10.6074 17.9281C10.5606 16.2247 9.67277 14.8804 8.59277 14.2326H8.59298ZM7.15299 13.8004C6.31299 13.8004 5.64079 13.1282 5.64079 12.2882C5.64079 11.4482 6.31299 10.776 7.15299 10.776C7.99299 10.776 8.66518 11.4482 8.66518 12.2882C8.66518 13.1282 7.99299 13.8004 7.15299 13.8004V13.8004Z"
        fill="black"
      />
    </svg>
  </SvgIcon>
);
