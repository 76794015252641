import React, { ChangeEvent, useCallback, useEffect, useRef } from "react";
import {
  useSortBy,
  useTable,
  Column,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Person, Status } from "../model/person";
import { Page } from "../../core/page";
import { ExpandingRow } from "../../components/table/expanding-row";
import { PersonDetails } from "./person-details";

interface PersonsTableProps {
  columns: Column<Person>[];
  data: Page<Person>;
  onPageNumberChange: (newPage: number) => void;
  onPageSizeChange: (rowsPerPage: number) => void;
  onSortChange: (sort: { id: string; desc?: boolean }[]) => void;
  onCheckboxValueChange?: (event: ChangeEvent, persons: Person) => void;
  onFilterChange?: (event: any) => void;
  onRecordConflictSelection?: (row: Person) => void;
}

interface PrepareIconProps {
  openLink: () => void;
  icon: any;
  tooltip: string;
}

export const PersonsTable: React.FC<PersonsTableProps> = ({
  columns,
  data,
  onPageNumberChange,
  onPageSizeChange,
  onSortChange,
  onCheckboxValueChange,
  onFilterChange,
  onRecordConflictSelection,
}) => {
  const didMountRef = useRef(false);

  const handlePageSizeChange = useCallback(
    (event: any) => onPageSizeChange(parseInt(event.target.value, 10)),
    [onPageSizeChange]
  );

  const handlePageNumberChange = useCallback(
    (event: any, newPage: number) => onPageNumberChange(newPage),
    [onPageNumberChange]
  );

  const handleFilterChange = useAsyncDebounce(
    (filtersObject) => onFilterChange && onFilterChange(filtersObject),
    300
  );

  const computeData: any = React.useMemo(
    () => data?.content.map((person: Person) => person),
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, filters },
  } = useTable(
    {
      columns,
      data: computeData,
      manualFilters: true,
      manualSortBy: true,
      initialState: {
        sortBy: [
          {
            id: "updatedAt",
            desc: true,
          },
        ],
        filters: [],
      },
    },
    useFilters,
    useSortBy
  );

  useEffect(() => {
    if (didMountRef.current) {
      onSortChange(sortBy);
    }
  }, [sortBy, onSortChange]);

  useEffect(() => {
    if (didMountRef.current) {
      handleFilterChange(filters);
    } else {
      didMountRef.current = true;
    }
  }, [filters, handleFilterChange]);

  const prepareIconsData = useCallback(
    (person: Person): any => {
      const arrayOfClickableIcons: Array<PrepareIconProps> = [];

      if (!onCheckboxValueChange) {
        return arrayOfClickableIcons;
      }

      arrayOfClickableIcons.push({
        openLink: () => undefined,
        icon: (
          <Checkbox
            onChange={(event) => onCheckboxValueChange(event, person)}
          />
        ),
        tooltip: "",
      });
      return arrayOfClickableIcons;
    },
    [onCheckboxValueChange]
  );

  return (
    <Paper>
      <TableContainer>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    <>
                      {column.render("Header")}
                      <br />
                      {column.canFilter ? column.render("Filter") : null}
                    </>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <ExpandingRow
                  {...row.getRowProps()}
                  key={"" + row.getRowProps().key + row.original.id}
                  renderSummary={() => {
                    const tail = row.cells.slice(1);
                    return tail.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          <>{cell.render("Cell")}</>{" "}
                        </TableCell>
                      );
                    });
                  }}
                  renderDetails={() => <PersonDetails person={row.original} />}
                  icons={prepareIconsData(row.original)}
                  tableRowProps={
                    onRecordConflictSelection &&
                    row.original.status === Status.RecordConflict
                      ? {
                          onClick: () =>
                            onRecordConflictSelection(row.original),
                          style: { cursor: "pointer" },
                        }
                      : {}
                  }
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.totalElements}
        rowsPerPage={data.size}
        page={data.number}
        onPageChange={handlePageNumberChange}
        onRowsPerPageChange={handlePageSizeChange}
      />
    </Paper>
  );
};
