export interface PreparedMessage {
  messageId: string;
  toAddress: string;
}

export function PreparedMessageFromJSON(json: any): PreparedMessage {
  if (!json) {
    return json;
  }

  return {
    messageId: json["messageId"],
    toAddress: json["toAddress"],
  };
}
