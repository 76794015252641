import React, { useEffect, useState } from "react";
import { useHealthCheck } from "../health-check/hooks/use-health-check";
import { Loading } from "../components/loading";
import { HealthCheckReport } from "../health-check/components/health-check-report";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ApiInfoApi, ServiceInfo } from "../api/api-info.api";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  chipRoot: {
    color: "white",
  },
}));

const getChipStyles = (status: "UP" | "DOWN") =>
  status === "UP"
    ? { backgroundColor: "#28A745" }
    : { backgroundColor: "#DC3545" };

export const StatusPage: React.FC = () => {
  const [apiInfo, setApiInfo] = useState<ServiceInfo[]>();
  const { report, loading: loadingReport, fetchReport } = useHealthCheck();
  const classes = useStyles();

  useEffect(() => {
    const fetchGatewayInfo = async () => {
      try {
        const resp = await ApiInfoApi.getInfo();
        setApiInfo(resp);
      } catch (e) {
        setApiInfo(undefined);
      }
    };

    fetchGatewayInfo();
    fetchReport();
  }, [fetchReport]);

  return (
    <div>
      <div>
        <Typography component="h1" variant="h5" noWrap>
          Health Check
        </Typography>
        <div>
          {loadingReport ? <Loading /> : <HealthCheckReport report={report} />}
        </div>
      </div>
      {apiInfo && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Service Name</TableCell>
                <TableCell>Version</TableCell>
                <TableCell align="right">Commit time</TableCell>
                <TableCell align="right">Commit ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apiInfo.map((row) => (
                <TableRow key={row.id}>
                  <TableCell scope="row">
                    <Chip
                      size="small"
                      label={row.health.status}
                      classes={{ root: classes.chipRoot }}
                      style={getChipStyles(row.health?.status)}
                    />
                  </TableCell>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.info?.build.version}</TableCell>
                  <TableCell align="right">
                    {row.info?.build.time.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.info && row.info.git && row.info.git.commit && (
                      <a
                        href={row.info.git.commit.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {row.info.git.commit.id}
                      </a>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
