import React, { useEffect } from "react";
import { Row, useRowSelect, useTable } from "react-table";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { conflictTableColumns } from "../config/conflict-table-config";
import { Person } from "../model/person";
import { BaseIdentity } from "../model/base-identity";
import { PersonRow } from "./person-row";
import { IndeterminateCheckbox } from "./indeterminate-checkbox";

interface ConflictTableProps {
  person: Person;
  potentialMatches: BaseIdentity[];
  onSelectedMatchesChange: (macthes: BaseIdentity[]) => void;
}

const useStyles = makeStyles(() => ({
  tableSubtitle: {
    fontWeight: "bold",
    fontSize: 16,
  },
}));

export const ConflictTable: React.FC<ConflictTableProps> = ({
  person,
  potentialMatches,
  onSelectedMatchesChange,
}) => {
  const classes = useStyles();
  const {
    getTableProps,
    getTableBodyProps,
    getToggleAllRowsSelectedProps,
    headerGroups,
    rows,
    columns,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns: conflictTableColumns,
      data: potentialMatches,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((visibleColumns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: any) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...visibleColumns,
      ]);
    }
  );

  useEffect(() => {
    const selected = selectedFlatRows.map(
      (row: Row<BaseIdentity>) => row.original
    );
    onSelectedMatchesChange(selected);
  }, [selectedFlatRows, onSelectedMatchesChange]);

  const isSelected = (id: string) =>
    selectedFlatRows.findIndex((row) => row.id === id) !== -1;

  if (!person) {
    return null;
  }

  return (
    <Paper>
      <TableContainer>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    <>{column.render("Header")}</>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            <PersonRow person={person} columns={columns} />
            <TableRow>
              <TableCell>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </TableCell>
              <TableCell colSpan={16} className={classes.tableSubtitle}>
                Potential Matches
              </TableCell>
            </TableRow>
            {rows.map((row) => {
              prepareRow(row);
              const isItemSelected = isSelected(row.id);
              return (
                <TableRow
                  key={"" + row.getRowProps().key + row.original.id}
                  hover
                  selected={isItemSelected}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        <>{cell.render("Cell")}</>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
