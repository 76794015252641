import { HealthCheckReportIncident } from "../model/health-check-report";
import React, { useCallback, useMemo, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface BarProps {
  date: Date;
  incidents: HealthCheckReportIncident[];
}

const useStyles = makeStyles((theme) => ({
  bar: {
    height: theme.spacing(4),
    backgroundColor: (props) =>
      (props as any)?.incidents?.length ? "#DC3545" : "#28A745",
    flex: "1 1 auto",
    margin: theme.spacing(0.25),
    borderRadius: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "calc(100% - var(--bs-gutter-x))",
  },
}));

const EPS = 0.001;
export const ReportItemBar: React.FC<BarProps> = (props) => {
  const { incidents, date } = props;
  const [open, setOpen] = useState(true);
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  console.log(open, anchorEl);
  const openPopUp = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    },
    [setAnchorEl, setOpen]
  );
  const closePopUp = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, [setAnchorEl, setOpen]);

  const tooltipContent = useMemo(
    () => (
      <>
        <Typography variant="subtitle2">{date.toLocaleDateString()}</Typography>
        {incidents.map((incident) => {
          const diffTime = Math.abs(
            incident.from.getTime() - incident.to.getTime()
          );

          const mins = Math.ceil((diffTime + EPS) / (1000 * 60)) % 60;
          const hours = Math.floor(diffTime / (1000 * 60 * 60));
          const from = incident.from.toLocaleTimeString();
          const to = incident.to.toLocaleTimeString();
          return (
            <Typography
              key={JSON.stringify(incident.from)}
            >{`Down from ${from} to ${to} (${hours}h ${mins}m)`}</Typography>
          );
        })}
        {incidents.length === 0 && (
          <Typography>No downtime recorded on this day.</Typography>
        )}
      </>
    ),
    [date, incidents]
  );
  return (
    <Tooltip title={tooltipContent}>
      <Box
        boxShadow={1}
        className={classes.bar}
        onMouseEnter={openPopUp}
        onMouseLeave={closePopUp}
      ></Box>
    </Tooltip>
  );
};
