import React, { ReactElement, useState } from "react";
import { Box, Grid, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TabLink } from "./tab-link";
import { TabPanel } from "./tab-panel";

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: theme.spacing(1),
    boxShadow: "0px 10px 8px -7px rgba(0,0,0,0.25)",
  },
}));

export interface TabConfig {
  label: string;
  path: string;
  components: { comp: ReactElement; key: string }[];
}

interface TabsTemplateProps {
  tabConfigs: TabConfig[];
  boxContent?: ReactElement;
  defaultPath: string;
}

export const TabsTemplate: React.FC<TabsTemplateProps> = ({
  tabConfigs,
  boxContent,
  defaultPath,
}) => {
  const classes = useStyles({});
  const [tabValue, setTabValue] = useState(defaultPath);

  const url = "/record-matching";

  // const tabValue = `${url}/${matchedTab ?? defaultPath}`;
  // console.log(tabValue);

  const mdSize = boxContent ? 9 : 12;

  return !tabValue ? null : (
    <Grid container spacing={3}>
      {boxContent && (
        <Grid item xs={12} md={3}>
          <Box mt={3}>{boxContent}</Box>
        </Grid>
      )}
      <Grid item xs={12} md={mdSize}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={tabValue}
          className={classes.tabs}
        >
          {tabConfigs.map((tab) => (
            <TabLink
              key={`${url}/${tab.path}`}
              label={tab.label}
              value={tab.path}
              onClick={() => setTabValue(tab.path)}
            />
          ))}
        </Tabs>
        {tabConfigs.map((tab) => (
          <TabPanel
            key={`${url}/${tab.path}`}
            index={tab.path}
            value={tabValue}
          >
            <Grid container spacing={2}>
              {tab.components.map((component) => (
                <Grid item xs={12} key={component.key}>
                  {component.comp}
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
};
