export interface ApaleoBankAccount {
  iban?: string;
  bic?: string;
  bank?: string;
}

export const ApaleoBankAccountModelFromJSON = (
  json: any
): ApaleoBankAccount => {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    iban: json["iban"],
    bic: json["bic"],
    bank: json["bank"],
  };
};
