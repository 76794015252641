import { useCallback, useState } from "react";
import { SortingRule } from "react-table";
import { FilterObject } from "../../api/search-criteria-parsing";
import { Page } from "../../core/page";
import { GuestJourneyApi } from "../api/guest-journey.api";
import { MagicFileData } from "../model/magic-file-data";
import { IdCheckStatus, Reservation } from "../model/reservation";
import { MagicFileType } from "../model/magic-file-type";
import { MagicFile } from "../model/magic-file";

interface UseOperationPageProps {
  fetchData: (
    pageNumber: number,
    pageSize: number,
    newSorting: SortingRule<Reservation>[],
    filterParams: FilterObject[]
  ) => Promise<void>;
  data: Page<Reservation> | undefined;
  idCheckDialogOpened: boolean;
  handleIdCheckDialogOpening: (reservation: any) => void;
  handleIdCheckDialogClosing: () => void;
  selectedReservation: Reservation;
  signatureDocument: MagicFileData;
  identificationDocument: MagicFileData;
  handleUpdateIdCheckStatus: (reservationId: string, status: string) => void;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  loading: boolean;
  sortingRule: SortingRule<Reservation>[];
  handleChangePage: (newPage: number) => void;
  handleFilterChange: (event: any) => void;
  handleSortChange: (sort: SortingRule<Reservation>[]) => void;
  searchParams: {
    filterParams: FilterObject[];
    page: number;
    rowsPerPage: number;
  };
}

// @ts-ignore
export const useOperationPage: () => UseOperationPageProps = () => {
  const [data, setData] = useState<Page<Reservation>>();
  const [loading, setLoading] = useState(false);
  const [idCheckDialogOpened, setIdCheckDialogOpened] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState<Reservation>();
  const [identificationDocument, setIdentificationDocument] =
    useState<MagicFileData>();
  const [signatureDocument, setSignatureDocument] = useState<MagicFileData>();

  const defaultSort: SortingRule<Reservation> = { id: "updatedAt", desc: true };
  const [sortingRule, setSortingRule] = useState<SortingRule<Reservation>[]>([
    defaultSort,
  ]);

  const [searchParams, setSearchParam] = useState({
    page: 0,
    rowsPerPage: 10,
    filterParams: [
      {
        id: "status",
        value: { type: "exact", value: "Confirmed" },
      } as FilterObject,
    ],
  });

  const fetchData = useCallback(
    async (
      pageNumber: number,
      pageSize: number,
      sorting: SortingRule<Reservation>[],
      filterArray: Array<FilterObject>
    ) => {
      setLoading(true);
      try {
        const response = await GuestJourneyApi.getReservations({
          pageable: { pageNumber, pageSize },
          sortingRules: sorting,
          filterParams: [...filterArray],
        });
        setData(response);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const fetchFileData = useCallback(
    async (file: MagicFile, setFile: (file: MagicFileData) => void) => {
      setLoading(true);
      try {
        const response = await GuestJourneyApi.getMagicFile(file.fileName);
        setFile({
          fileName: file.fileName,
          magicFileType: file.metaData.magicFileType,
          fileSrc: response,
        });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const updateIdCheckStatus = useCallback(
    async (reservationId: string, status: IdCheckStatus) => {
      setLoading(true);
      try {
        await GuestJourneyApi.updateReservationIdCheckStatus(
          reservationId,
          status
        );
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handleChangePage = useCallback((newPage: number) => {
    setSearchParam((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setSearchParam((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
      page: 0,
    }));
  }, []);

  const handleFilterChange = useCallback((event: []) => {
    setSearchParam((prevState) => ({
      ...prevState,
      page: 0,
      filterParams: event,
    }));
  }, []);

  const handleSortChange = useCallback((sort: SortingRule<Reservation>[]) => {
    setSortingRule(sort);
  }, []);

  const handleFileFetching = useCallback(
    (reservation: Reservation) => {
      reservation.files?.forEach((file) => {
        if (
          file.metaData.magicFileType === MagicFileType.IDENTIFICATION_DOCUMENT
        ) {
          fetchFileData(file, setIdentificationDocument);
        }
        if (file.metaData.magicFileType === MagicFileType.SIGNATURE_DOCUMENT) {
          fetchFileData(file, setSignatureDocument);
        }
      });
    },
    [fetchFileData]
  );

  const handleIdCheckDialogOpening = useCallback(
    (reservation: Reservation) => {
      setSelectedReservation(reservation);
      handleFileFetching(reservation);
      setIdCheckDialogOpened(true);
    },
    [handleFileFetching]
  );

  const handleIdCheckDialogClosing = useCallback(() => {
    setIdCheckDialogOpened(false);
    setSelectedReservation({} as Reservation);
    setSignatureDocument({} as MagicFileData);
    setIdentificationDocument({} as MagicFileData);
  }, []);

  const handleUpdateIdCheckStatus = useCallback(
    (resrevationId: string, status: IdCheckStatus) => {
      updateIdCheckStatus(resrevationId, status).then((result) => {
        setIdCheckDialogOpened(false);
        fetchData(
          searchParams.page,
          searchParams.rowsPerPage,
          sortingRule,
          searchParams.filterParams
        );
      });
    },
    [
      fetchData,
      searchParams.filterParams,
      searchParams.page,
      searchParams.rowsPerPage,
      sortingRule,
      updateIdCheckStatus,
    ]
  );

  return {
    data,
    fetchData,
    selectedReservation,
    idCheckDialogOpened,
    handleIdCheckDialogOpening,
    handleIdCheckDialogClosing,
    handleUpdateIdCheckStatus,
    signatureDocument,
    identificationDocument,
    loading,
    handleChangePage,
    handleFilterChange,
    handleSortChange,
    handleChangeRowsPerPage,
    sortingRule,
    searchParams,
  };
};
