import { GuestIdentity, GuestIdentityFromJSON } from "./guest-identity";
import { Person, PersonFromJSON } from "./person";

export interface Conflict {
  person: Person;
  guestIdentities: GuestIdentity[];
  count: number;
}

export function ConflictFromJSON(json: any): Conflict {
  if (!json) {
    return json;
  }

  return {
    person: PersonFromJSON(json["person"]),
    guestIdentities: (json["guestIdentities"] as any[]).map(
      GuestIdentityFromJSON
    ),
    count: json["count"],
  };
}
