import React from "react";
import { Grid } from "@mui/material";
import { BaseIdentity } from "../../model/base-identity";
import { LabeledText } from "../labeled-text";

interface GuestIdentitySummaryProps {
  guestIdentity: BaseIdentity;
}

const displayFields = [
  { name: "addressLine1", label: "Address Line 1" },
  { name: "email", label: "Email" },
  { name: "city", label: "City" },
  { name: "phone", label: "Phone" },
];

export const GuestIdentitySummary: React.FC<GuestIdentitySummaryProps> = ({
  guestIdentity,
}) => {
  return (
    <Grid container direction="row" spacing={1}>
      {displayFields.map((displayField) => {
        return (
          <Grid
            item
            xs={6}
            key={`summary-${guestIdentity.id}-${displayField.name}`}
          >
            <LabeledText
              label={displayField.label}
              text={
                guestIdentity[
                  displayField.name as keyof BaseIdentity
                ]?.toString() || "-"
              }
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
