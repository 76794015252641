import { UtcDateFormat } from "../components/formats";
import { SortingRule } from "react-table";
import moment from "moment-timezone";
import { FilterValue } from "../components/table/table-filters";

interface PageableRequestParams {
  pageNumber: number;
  pageSize: number;
}

export interface FilterObject {
  id: string;
  value: FilterValue;
}

export interface GetRequestParams<D> {
  pageable?: PageableRequestParams;
  sortingRules: SortingRule<D>[];
  filterParams: Array<FilterObject>;
}

export function parseQueryParams<D>(
  requestParams: GetRequestParams<D>
): string {
  if (!requestParams) {
    return "";
  }
  const result = [];
  if (Object.keys(requestParams.filterParams).length) {
    const parsedFilterParams = requestParams.filterParams
      .map((filterObject) => {
        switch (filterObject.value.type) {
          case "date": {
            if (!moment(filterObject.value.value, UtcDateFormat).toDate()) {
              console.error(
                "cannot parse filter date " + filterObject.value.value
              );
            }

            const startOfDay = moment(filterObject.value.value, UtcDateFormat)
              .tz("Europe/Zurich")
              .seconds(0)
              .minutes(0)
              .hours(0)
              .utc()
              .format();
            const endOfDay = moment(filterObject.value.value, UtcDateFormat)
              .tz("Europe/Zurich")
              .seconds(59)
              .minutes(59)
              .hours(23)
              .utc()
              .format();

            // Create between filter (x < date & date > x)
            return `${filterObject.id}>${startOfDay},${filterObject.id}<${endOfDay},`;
          }
          case "exact":
            return `${filterObject.id}:${filterObject.value.value},`;
          case "lax":
          default:
            // date/exact/lax actually cover all cases, but linter doesn't realize this and forces the 'default:' case...
            return `${filterObject.id}~${filterObject.value.value},`;
        }
      })
      .reduce((acc: string, next: string) => acc.concat(next), "");
    result.push(`search=${parsedFilterParams}`);
  }

  if (requestParams.pageable?.pageNumber !== undefined) {
    result.push(
      `page=${encodeURIComponent(requestParams.pageable.pageNumber)}`
    );
  }
  if (requestParams.pageable?.pageSize !== undefined) {
    result.push(`size=${encodeURIComponent(requestParams.pageable.pageSize)}`);
  }
  if (requestParams.sortingRules !== undefined) {
    result.push(
      ...requestParams.sortingRules.map(
        (rule) => `sort=${rule.id},${rule.desc ? "desc" : "asc"}`
      )
    );
  }
  return result.join("&");
}
