import React, { PropsWithChildren } from "react";
import { FullscreenDialog } from "./fullscreen-dialog";
import { Button, Grid } from "@mui/material";

interface FullscreenConfirmProps {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const FullscreenConfirm: React.FC<
  PropsWithChildren<FullscreenConfirmProps>
> = ({ open, onCancel, onOk, children }) => {
  return (
    <FullscreenDialog open={open}>
      {children}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Button onClick={onCancel} variant="outlined">
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={onOk}
            variant="contained"
            color="primary"
            disableElevation
            sx={{ color: "common.white" }}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </FullscreenDialog>
  );
};
