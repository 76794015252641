import { GuestIdentity, GuestIdentityFromJSON } from "./guest-identity";

export interface Prediction {
  personId: number;
  status: string;
  guestIdentities: GuestIdentity[];
}

export function PredictionFromJSON(json: any): Prediction {
  if (!json) {
    return json;
  }

  return {
    personId: json["personId"],
    status: json["status"],
    guestIdentities: (json["guestIdentities"] as any[]).map(
      GuestIdentityFromJSON
    ),
  };
}
