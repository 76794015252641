import { useCallback, useState } from "react";
import { Message } from "../model/message";
import { MessageApi } from "../api/message.api";
import { ReservationApi } from "../../reservation/api/reservation.api";

export const useResendMessages = () => {
  const [messageToResend, setMessageToResend] = useState<Message>();

  const discardMessageToResend = useCallback(
    () => setMessageToResend(undefined),
    []
  );

  const resendMessage = useCallback(
    async (message: Message, toAddress: string) => {
      discardMessageToResend();
      try {
        const reservation = await ReservationApi.getReservation(
          message.reservationId
        );
        await MessageApi.resendMessage(message, toAddress, reservation.magicId);
      } catch (e) {
        console.error(e);
      }
    },
    [discardMessageToResend]
  );

  return {
    messageToResend,
    discardMessageToResend,
    resendMessage,
    setMessageToResend,
  };
};
