import React, { useCallback, useEffect, useRef } from "react";
import { Reservation } from "../model/reservation";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Page } from "../../core/page";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { JsonTreeView } from "../../components/json-tree-view";
import { ExpandingRow } from "../../components/table/expanding-row";
import { reservationsTableColumns } from "../config/reservations-table-config";
import { DefaultColumnFilter } from "../../components/table/table-filters";
import { useNavigate, useLocation } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ApaleoIcon } from "../../components/apaleo-icon";
import { MobileIcon } from "../../components/mobile-icon";
import { ReservationSearchInput } from "./reservations-finder";
import * as H from "history";
import { ReservationPageHistoryState } from "../../pages/reservations.page";

interface ReservationsTableProps {
  data: Page<Reservation>;
  globalFilter: string;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  handleFilterChange: (event: any) => void;
  handleSortChange: (sort: { id: string; desc?: boolean }[]) => void;
  handleSearchChange: (search: string) => void;
}

export const ReservationsTable: React.FC<ReservationsTableProps> = (params) => {
  const {
    data,
    globalFilter: globalFilterInput,
    handleSortChange,
    handleChangeRowsPerPage,
    handleChangePage,
    handleFilterChange,
    handleSearchChange,
  } = params;
  const didMountRef = useRef(false);

  const handleChangePageAdapter = useCallback(
    (event: any, newPage: number) => handleChangePage(newPage),
    [handleChangePage]
  );

  const handleChangeRowsPerPageAdapter = useCallback(
    (event: any) => handleChangeRowsPerPage(parseInt(event.target.value, 10)),
    [handleChangeRowsPerPage]
  );

  const handleFilterChangeAdapter = useAsyncDebounce(
    (filtersObject) => handleFilterChange(filtersObject),
    300
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const computeData: any = React.useMemo(
    () =>
      data.content.map((reservation: Reservation) => {
        const channelCode = reservation.source
          ? reservation.source
          : reservation.channelCode;
        return { ...reservation, channelCode };
      }),
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, filters, globalFilter },
    setAllFilters,
    setGlobalFilter,
  } = useTable(
    {
      columns: reservationsTableColumns,
      data: computeData,
      manualFilters: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: "timestamp",
            desc: true,
          },
        ],
        globalFilter: globalFilterInput,
        filters: [],
      },
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const location: H.Location<ReservationPageHistoryState> = useLocation();
  const locationSearchString = location?.state?.searchString;
  useEffect(() => {
    if (didMountRef.current) {
      locationSearchString && setAllFilters([]);
    }
  }, [locationSearchString, setAllFilters]);

  const navigate = useNavigate();

  useEffect(() => {
    if (didMountRef.current) {
      handleSortChange(sortBy);
    }
  }, [sortBy, handleSortChange]);
  useEffect(() => {
    if (didMountRef.current) {
      handleFilterChangeAdapter(filters);
    }
  }, [filters, handleFilterChangeAdapter]);
  useEffect(() => {
    if (didMountRef.current) {
      handleSearchChange(globalFilter);
    }
  }, [globalFilter, handleSearchChange]);
  useEffect(() => {
    if (didMountRef.current) {
      setGlobalFilter(globalFilterInput);
    }
  }, [globalFilterInput, setGlobalFilter]);

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  interface PrepareIconProps {
    openLink: () => void;
    icon: any;
    tooltip: string;
  }

  const prepareIconsData = useCallback(
    (reservation: Reservation): any => {
      const arrayOfClickableIcons: Array<PrepareIconProps> = [];

      arrayOfClickableIcons.push({
        openLink: () => {
          navigate(`/reservations/${reservation.id}`);
        },
        icon: <OpenInNewIcon />,
        tooltip: "Open Reservation",
      });
      arrayOfClickableIcons.push({
        openLink: () => {
          window.open(reservation.apaleoUrl);
        },
        icon: <ApaleoIcon viewBox="0 0 564 564" />,
        tooltip: "Open apaleo",
      });
      arrayOfClickableIcons.push({
        openLink: () => {
          window.open(reservation.bookerFlowLinksEN);
        },
        icon: <MobileIcon viewBox="0 0 480 480" />,
        tooltip: "Open Bookerflow Notifications",
      });
      arrayOfClickableIcons.push({
        openLink: () => {
          window.open(reservation.flowLinksEN);
        },
        icon: <MobileIcon viewBox="0 0 480 480" />,
        tooltip: "Open GuestFlow Notifications",
      });

      return arrayOfClickableIcons;
    },
    [navigate]
  );

  return (
    <>
      <Box mb={2}>
        <ReservationSearchInput
          searchString={globalFilter}
          setSearchString={setGlobalFilter}
        />
      </Box>
      <Paper>
        <TableContainer>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell {...column.getHeaderProps()}>
                      <>
                        {column.render("Header")}
                        <br />
                        {column.canFilter ? column.render("Filter", {}) : null}
                      </>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <ExpandingRow
                    {...row.getRowProps()}
                    key={"" + row.getRowProps().key + row.original.id}
                    renderSummary={() => {
                      const tail = row.cells.slice(1);
                      return tail.map((cell) => {
                        return (
                          <TableCell {...cell.getCellProps()}>
                            <Typography noWrap>
                              {cell.render("Cell") as any}
                            </Typography>
                          </TableCell>
                        );
                      });
                    }}
                    renderDetails={() => (
                      <Box p={2}>
                        <JsonTreeView data={row.original} />
                      </Box>
                    )}
                    icons={prepareIconsData(row.original)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.totalElements}
          rowsPerPage={data.size}
          page={data.number}
          onPageChange={handleChangePageAdapter}
          onRowsPerPageChange={handleChangeRowsPerPageAdapter}
        />
      </Paper>
    </>
  );
};
