import { ThemeOptions } from "@mui/material";

export const themeOverride = (): ThemeOptions => {
  return {
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
          }),
        },
      },
    },
  };
};
