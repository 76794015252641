import React, {
  FC,
  useCallback,
  useState,
  useEffect,
  useRef,
  PropsWithChildren,
} from "react";
import {
  Typography,
  TypographyProps as MuiTypographyProps,
  Tooltip,
} from "@mui/material";

interface Props {
  tooltipText: string;
  TypographyProps?: MuiTypographyProps;
  component: React.ElementType;
}

export const TooltipTypography: FC<PropsWithChildren<Props>> = ({
  tooltipText,
  children,
  component,
  TypographyProps,
}) => {
  const [allowTooltip, setAllowTooltip] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);
  const measuredRef = useCallback(() => {
    if (textRef.current) {
      setAllowTooltip(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, []);

  useEffect(() => {
    measuredRef();
  }, [measuredRef]);

  useEffect(() => {
    window.addEventListener("resize", measuredRef);
    return () => {
      window.removeEventListener("resize", measuredRef);
    };
  }, [measuredRef]);

  return (
    <Tooltip title={tooltipText || ""} disableHoverListener={!allowTooltip}>
      <Typography
        component={component}
        noWrap
        {...TypographyProps}
        ref={textRef}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
