import { Column } from "react-table";
import { BaseIdentity } from "../model/base-identity";
import { IdentityAccessor } from "../../components/table/table-accessors";
import { LabelHeader } from "../../components/table/table-headers";

export const conflictTableColumns: Column<BaseIdentity>[] = [
  {
    Header: LabelHeader("First Name"),
    accessor: IdentityAccessor("firstName"),
    id: "firstName",
  },
  {
    Header: LabelHeader("Last Name"),
    accessor: IdentityAccessor("lastName"),
    id: "lastName",
  },
  {
    Header: LabelHeader("Email"),
    accessor: IdentityAccessor("email"),
    id: "email",
  },
  {
    Header: LabelHeader("Phone"),
    accessor: IdentityAccessor("phone"),
    id: "phone",
  },
  {
    Header: LabelHeader("Preferred Language"),
    accessor: IdentityAccessor("preferredLanguage"),
    id: "preferredLanguage",
  },
  {
    Header: LabelHeader("Title"),
    accessor: IdentityAccessor("title"),
    id: "title",
  },
  {
    Header: LabelHeader("Gender"),
    accessor: IdentityAccessor("gender"),
    id: "gender",
  },
  {
    Header: LabelHeader("Birthdate"),
    accessor: IdentityAccessor("birthdate"),
    id: "birthdate",
  },
  {
    Header: LabelHeader("Address Line 1"),
    accessor: IdentityAccessor("addressLine1"),
    id: "addressLine1",
  },
  {
    Header: LabelHeader("Address Line 2"),
    accessor: IdentityAccessor("addressLine2"),
    id: "addressLine2",
  },
  {
    Header: LabelHeader("City"),
    accessor: IdentityAccessor("city"),
    id: "city",
  },
  {
    Header: LabelHeader("Postal Code"),
    accessor: IdentityAccessor("postalCode"),
    id: "postalCode",
  },
  {
    Header: LabelHeader("Country Code"),
    accessor: IdentityAccessor("countryCode"),
    id: "countryCode",
  },
  {
    Header: LabelHeader("Identification Number"),
    accessor: IdentityAccessor("identificationNumber"),
    id: "identificationNumber",
  },
  {
    Header: LabelHeader("Identification Type"),
    accessor: IdentityAccessor("identificationType"),
    id: "identificationType",
  },
  {
    Header: LabelHeader("Nationality Country Code"),
    accessor: IdentityAccessor("nationalityCountryCode"),
    id: "nationalityCountryCode",
  },
];
