import { useCallback, useState } from "react";
import { Page } from "../../core/page";
import { SortingRule } from "react-table";
import { Message } from "../model/message";
import { MessageApi } from "../api/message.api";

interface UseMessagesPage {
  fetchData: (
    pageNumber: number,
    pageSize: number,
    newSorting: SortingRule<Message>[],
    filterParams: []
  ) => Promise<void>;
  data: Page<Message> | undefined;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  loading: boolean;
  sortingRule: SortingRule<Message>[];
  handleChangePage: (newPage: number) => void;
  handleFilterChange: (event: any) => void;
  handleSortChange: (sort: SortingRule<Message>[]) => void;
  searchParams: { filterParams: []; page: number; rowsPerPage: number };
}

// @ts-ignore
export const useMessagesPage: () => UseMessagesPage = () => {
  const [data, setData] = useState<Page<Message>>();
  const [loading, setLoading] = useState(false);
  const defaultSort: SortingRule<Message> = {
    id: "latestChangeTimestamp",
    desc: true,
  };
  const [sortingRule, setSortingRule] = useState<SortingRule<Message>[]>([
    defaultSort,
  ]);

  const [searchParams, setSearchParam] = useState({
    page: 0,
    rowsPerPage: 10,
    filterParams: [],
  });

  const fetchData = useCallback(
    async (
      pageNumber: number,
      pageSize: number,
      newSorting: SortingRule<Message>[],
      filterArray: any
    ) => {
      setLoading(true);
      try {
        const response = await MessageApi.getMessages({
          pageable: { pageNumber, pageSize },
          sortingRules: newSorting,
          filterParams: filterArray,
        });
        setData(response);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handleChangePage = useCallback((newPage: number) => {
    setSearchParam((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setSearchParam((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
      page: 0,
    }));
  }, []);

  const handleFilterChange = useCallback((event: []) => {
    setSearchParam((prevState) => ({
      ...prevState,
      page: 0,
      filterParams: event,
    }));
  }, []);

  const handleSortChange = useCallback((sort: SortingRule<Message>[]) => {
    setSortingRule(sort);
  }, []);

  return {
    data,
    fetchData,
    loading,
    handleChangePage,
    handleFilterChange,
    handleSortChange,
    handleChangeRowsPerPage,
    sortingRule,
    searchParams,
  };
};
