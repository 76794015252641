import { FC } from "react";
import { LogoProps } from "./Logo";
import lmLogo from "./lm_logo.png";

export const LikeMagicLogo: FC<React.PropsWithChildren<LogoProps>> = (
  props
) => {
  return (
    <img
      alt="LIKE MAGIC Logo"
      src={lmLogo}
      height={props.height}
      style={{ paddingTop: "8px", paddingBottom: "8px", marginBottom: "-4px" }}
    />
  );
};
