import { BaseApiClient, JSONApiResponse } from "../../core/api";
import { Page, PageFromJSON } from "../../core/page";
import { Message, MessageFromJSON } from "../model/message";
import {
  GetRequestParams,
  parseQueryParams,
} from "../../api/search-criteria-parsing";
import {
  PreparedMessage,
  PreparedMessageFromJSON,
} from "../model/prepared-message";

const BASE_URL = "/api/reservation-service/message-view";

export interface ResendMessageDTO {
  magicId: string;
  language: string;
  template: string;
  platform: string;
}

class MessageApiClient extends BaseApiClient {
  async getDistinctTemplate(): Promise<string[]> {
    let url = BASE_URL + "/distinct-template";
    const response = await this.fetchApi(url);
    return await response.json();
  }

  async getDistinctStatus(): Promise<string[]> {
    let url = BASE_URL + "/distinct-status";
    const response = await this.fetchApi(url);
    return await response.json();
  }

  async getDistinctPlatform(): Promise<string[]> {
    let url = BASE_URL + "/distinct-platform";
    const response = await this.fetchApi(url);
    return await response.json();
  }

  async getMessages(
    requestParams?: GetRequestParams<Message>
  ): Promise<Page<Message>> {
    let url = BASE_URL;

    if (requestParams) {
      url += "?" + parseQueryParams(requestParams);
    }
    const response = await this.fetchApi(url);
    return new JSONApiResponse(response, PageFromJSON(MessageFromJSON)).value();
  }

  async resendMessage(
    message: Message,
    toAddress: string,
    magicId: string
  ): Promise<PreparedMessage> {
    //We are using DEFAULT (preferred) language
    const runString: string = `/api/notification-service/resendNotification/${magicId}/DEFAULT/${
      message.template
    }/${message.platform.toUpperCase()}`;

    const response = await this.fetchApi(runString, {
      method: "POST",
      body: JSON.stringify({
        toAddress,
      }),
    });
    return new JSONApiResponse(response, PreparedMessageFromJSON).value();
  }
}

export const MessageApi = new MessageApiClient();
