import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PersonalDataView } from "./personal-data-view";
import { IdCheckStatus, Reservation } from "../model/reservation";
import Close from "@mui/icons-material/Close";
import { MagicFileData } from "../model/magic-file-data";

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    borderRadius: "12px",
    color: "#FFFFFF",
    backgroundColor: "#000000",
    fontStyle: "normal",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#000000",
      "@media (hover: none)": {
        backgroundColor: "#000000",
      },
    },
    "&.Mui-disabled'": {
      color: "#FFFFFF",
      backgroundColor: "#000000",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  declineButton: {
    borderRadius: "12px",
    textTransform: "capitalize",
  },
}));

interface IdConfirmationProps {
  signatureDocument: MagicFileData;
  identificationDocument: MagicFileData;
  reservation: Reservation;
  open: boolean;
  close: () => void;
  idCheckAction: (reservationId: string, status: string) => void;
}

export const IdConfirmationDialog: React.FC<IdConfirmationProps> = ({
  signatureDocument,
  identificationDocument,
  reservation,
  open,
  close,
  idCheckAction,
}) => {
  const classes = useStyles();

  return (
    <Dialog onClose={close} open={open} maxWidth="xl">
      <div>
        <DialogTitle>Please confirm ID and signature:</DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={close}
          size="large"
        >
          <Close />
        </IconButton>
      </div>
      <DialogContent>
        <PersonalDataView
          reservation={reservation}
          signature={signatureDocument}
          identification={identificationDocument}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.declineButton}
          variant="outlined"
          onClick={() => {
            idCheckAction(reservation.id, IdCheckStatus.DECLINED);
          }}
        >
          Decline
        </Button>
        <Button
          variant="contained"
          className={classes.confirmButton}
          onClick={() => {
            idCheckAction(reservation.id, IdCheckStatus.CONFIRMED);
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
