import React, { useState } from "react";
import {
  HealthCheckReportIncident,
  HealthCheckReportItem,
} from "../model/health-check-report";
import makeStyles from "@mui/styles/makeStyles";
import { ReportItemBar } from "./report-item-bar";

interface ReportItemProps {
  data: HealthCheckReportItem | null | undefined;
}

const generateLast30Days = () => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  return new Array(30).fill(null).map((_, i) => {
    let date = new Date();
    date.setDate(today.getDate() - i);
    date.setHours(0, 0, 0, 0);
    return date;
  });
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  typography: {
    maxWidth: 300,
  },
}));

function isSameDay(d1: Date, d2: Date) {
  return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth();
}

function getIncidentsForDay(
  data: HealthCheckReportItem | null | undefined,
  date: Date
): HealthCheckReportIncident[] {
  if (!data) {
    return [];
  }
  return data.incidents.filter(
    (i) => isSameDay(i.from, date) || isSameDay(i.to, date)
  );
}

export const ReportItem: React.FC<ReportItemProps> = ({ data }) => {
  const [days] = useState<Date[]>(generateLast30Days().reverse());
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {days.map((d) => (
        <ReportItemBar
          key={d.toISOString()}
          date={d}
          incidents={getIncidentsForDay(data, d)}
        />
      ))}
    </div>
  );
};
