import { Icon } from "@mui/material";
import { Done } from "@mui/icons-material";
import React from "react";
import { BadgeIcon } from "../../components/badge-icon";
import { DeclinedIcon } from "../../components/declined-icon";
import { IdCheckStatus, Reservation } from "../model/reservation";

export function IdCheckAccessor<T>(
  field: keyof T
): (row: Reservation) => React.ReactElement {
  return (row: Reservation) =>
    row.files?.length >= 2 ? statusHandle(row.idCheckStatus) : <Icon />;
}

function statusHandle(status: string): any {
  if (status === IdCheckStatus.CONFIRMED) {
    return <Done />;
  } else if (status === IdCheckStatus.DECLINED) {
    return <DeclinedIcon />;
  }
  return <BadgeIcon />;
}
