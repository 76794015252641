export interface MagicEvent {
  id: number;
  idOrigin: string;
  objectId: string;
  origin: string;
  state: string;
  topic: string;
  type: string;
  message: any;
  payload: any;
  timestamp: Date;
  createdAt: Date;
  updatedAt: Date;
}

export function MagicEventFromJSON(json: any) {
  return {
    id: json["id"],
    idOrigin: json["idOrigin"],
    objectId: json["objectId"],
    origin: json["origin"],
    state: json["state"],
    topic: json["topic"],
    type: json["type"],
    message: json["message"],
    payload: json["payload"],
    timestamp: new Date(json["timestamp"]),
    createdAt: new Date(json["createdAt"]),
    updatedAt: new Date(json["updatedAt"]),
  };
}
