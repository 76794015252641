import { useCallback, useState } from "react";
import { MessageBirdV2Api } from "../api/messagebirdV2.api";

interface UseMessagebirdV2WebhooksPage {
  subscriptions: any[];
  loading: boolean;
  fetchSubscriptions: () => Promise<void>;
  removeSubscription: (id: string) => Promise<void>;
}

export const useMessageBirdV2WebhooksPage =
  (): UseMessagebirdV2WebhooksPage => {
    const [subscriptions, setSubscriptions] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchSubscriptions = useCallback(async () => {
      try {
        setLoading(true);
        const subs = await MessageBirdV2Api.getSubscriptions();
        setSubscriptions(subs);
      } catch (e) {
        setSubscriptions([]);
      } finally {
        setLoading(false);
      }
    }, []);

    const removeSubscription = useCallback(
      async (id: string) => {
        const result = await MessageBirdV2Api.deleteSubscription(id);
        fetchSubscriptions();
        return result;
      },
      [fetchSubscriptions]
    );

    return {
      subscriptions,
      fetchSubscriptions,
      loading,
      removeSubscription,
    };
  };
