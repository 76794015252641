import React, { useCallback, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    marginRight: theme.spacing(2),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchInputFocused: {
    flexGrow: (props: { focused: boolean }) => (props.focused ? 1 : 0),
    transition: "0.2s width",
  },
}));

export interface NavbarReservationSearchProps {}

export const NavbarReservationSearch: React.FC<
  NavbarReservationSearchProps
> = () => {
  const inputRef = useRef<HTMLElement>();
  const [focused, setFocused] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");
  const onChange = useCallback(
    (event: any) => setSearchString(event.target.value),
    []
  );
  const navigate = useNavigate();

  const onSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      console.log(searchString);
      navigate(`/reservations`); //, { searchString }
      setSearchString("");
      inputRef.current?.blur();
    },
    [navigate, searchString, inputRef]
  );

  const classes = useStyles({ focused });

  return (
    <Paper
      component="form"
      className={classes.root}
      elevation={0}
      onSubmit={onSubmit}
      classes={{
        root: classes.searchInputFocused,
      }}
    >
      <InputBase
        className={classes.input}
        placeholder={
          focused
            ? "Apaleo ID, Name, E-Mail, Phone, Company, Room Number, ..."
            : "Search..."
        }
        value={searchString}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        classes={{
          focused: classes.searchInputFocused,
        }}
        inputRef={inputRef}
      />
      <IconButton type="submit" aria-label="search" size="small">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
