import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Person } from "../model/person";
import { ReferenceType } from "../model/object-reference";

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: "lighter",
    fontSize: 14,
  },
  text: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: "bold",
    fontSize: 14,
  },
  detailContainer: {
    flexBasis: "20%",
  },
}));

interface PersonDetailsProps {
  person: Person;
}

const columns = [
  { name: "addressLine2", label: "Address Line 2" },
  { name: "countryCode", label: "Country" },
  { name: "postalCode", label: "Postal Code" },
  { name: "title", label: "Title" },
  { name: "gender", label: "Gender" },
  { name: "birthdate", label: "Birth Date" },
  { name: "preferredLanguage", label: "Preferred Language" },
  { name: "identificationType", label: "ID Type" },
  { name: "identificationNumber", label: "ID Number" },
  { name: "nationalityCountryCode", label: "Nationality" },
];

export const PersonDetails: React.FC<PersonDetailsProps> = ({ person }) => {
  const classes = useStyles();
  return (
    <Box p={2} mx={6}>
      <Grid container direction="column" spacing={1} style={{ height: 160 }}>
        {columns.map((column) => {
          const text = person[column.name as keyof Person]?.toString() || "-";
          return (
            <Grid
              key={`pd_${person.id}_${column.name}`}
              container
              item
              xs={3}
              direction="row"
              className={classes.detailContainer}
            >
              <Grid item xs={6} className={classes.label}>
                {column.label}
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.text} component="span">
                  {text}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
        {person.objectReference && (
          <Grid
            key={`pd_${person.id}_objref`}
            container
            item
            xs={3}
            direction="row"
            className={classes.detailContainer}
          >
            <Grid item xs={6} className={classes.label}>
              Reference
            </Grid>
            <Grid item xs={6}>
              {person.objectReference.referenceType ===
              ReferenceType.Reservation ? (
                <Link to={`/reservations/${person.objectReference.objectId}`}>
                  {person.objectReference.objectId}
                </Link>
              ) : (
                <Typography className={classes.text} component="span">
                  {person.objectReference.objectId}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
