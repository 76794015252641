import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { IconButton, InputBase } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAsyncDebounce } from "react-table";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    border: "1px solid #0000003b",
    "border-radius": "5px",
    width: "30%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    width: "100%",
  },
}));

export const ReservationSearchInput: React.FC<{
  searchString: string;
  setSearchString: (s: string) => void;
}> = ({ searchString, setSearchString }) => {
  const classes = useStyles();

  const defaultSearchPlaceholder = "Find a reservation...";
  const [searchPlaceholder, setSearchPlaceholder] = React.useState(
    defaultSearchPlaceholder
  );
  const [value, setValue] = React.useState(searchString);
  const onChange = useAsyncDebounce((value) => {
    setSearchString(value || undefined);
  }, 200);
  useEffect(() => {
    setValue(searchString);
  }, [searchString]);

  return (
    <InputBase
      placeholder={searchPlaceholder}
      onFocus={() =>
        setSearchPlaceholder(
          "Apaleo ID, Name, E-Mail, Phone, Company, Room Number, ..."
        )
      }
      onBlur={() => setSearchPlaceholder(defaultSearchPlaceholder)}
      value={value}
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
      }}
      onChange={(event) => {
        setValue(event.target.value);
        onChange(event.target.value);
      }}
    />
  );
};
export const ReservationFinder: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState<string>("");

  return (
    <div className={classes.root}>
      <ReservationSearchInput
        searchString={searchString}
        setSearchString={setSearchString}
      />
      <IconButton
        size="small"
        onClick={() => navigate(`/reservations`, { state: { searchString } })}
        color="inherit"
        disabled={!searchString}
      >
        <Search />
      </IconButton>
    </div>
  );
};
