import React, { PropsWithChildren } from "react";
import makeStyles from "@mui/styles/makeStyles";

interface LoadingShadowProps {
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: { position: "relative" },
  shadow: {
    zIndex: 100,
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: theme.palette.grey[100],
    opacity: (props: LoadingShadowProps) => (props.loading ? 1 : 0),
    pointerEvents: (props: LoadingShadowProps) =>
      props.loading ? "auto" : "none",
    transition: `opacity 0.25s ${theme.transitions.easing.easeInOut}`,
  },
}));

export const LoadingShadow: React.FC<PropsWithChildren<LoadingShadowProps>> = (
  props
) => {
  const { children } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.shadow} />
      {children}
    </div>
  );
};
