import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";

export function JsonStringifyAccessor<T>(field: keyof T): (row: T) => string {
  return (row: T) => JSON.stringify(row[field]);
}

export function DateAccessor<T>(field: keyof T): (row: T) => string {
  return (row: T) =>
    row[field] ? (row[field] as unknown as Date).toLocaleString() : "";
}

export function BooleanAccessor<T>(
  field: keyof T
): (row: T) => React.ReactElement {
  return (row: T) => (row[field] ? <CheckCircleIcon /> : <CancelIcon />);
}

export function IdentityAccessor<T>(field: keyof T): (row: T) => string {
  return (row: T) => row[field] as unknown as string;
}
