import React, { useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ConflictTable } from "../record-matching/components/conflict-table";
import { useConflictResolutionPage } from "../record-matching/hooks/use-conflict-resolution-page";
import { PageTitle } from "../record-matching/components/page-title";
import { Loading } from "../components/loading";

const useStyles = makeStyles((theme) => ({
  actionWrapper: {
    display: "flex",
    marginTop: theme.spacing(2),
    justifyContent: "flex-end",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export const ConflictResolutionPage: React.FC = () => {
  const classes = useStyles();
  const params: any = useParams();
  const navigate = useNavigate();
  const {
    fetchConflicts,
    fetchPerson,
    person,
    potentialMatches,
    loading,
    resolveRecordConflict,
    handleSelectedMatchesChange,
  } = useConflictResolutionPage();

  useEffect(() => {
    if (params.personId) {
      fetchPerson(params.personId);
      fetchConflicts(params.personId);
    }
  }, [fetchPerson, fetchConflicts, params.personId]);

  const handleSaveChanges = useCallback(async () => {
    await resolveRecordConflict(params.personId);
    navigate("/record-matching/persons");
  }, [resolveRecordConflict, params.personId, navigate]);

  if (loading) {
    return <Loading />;
  }

  if (!person) {
    return <PageTitle />;
  }

  return (
    <Box>
      <Typography component="h1" variant="h5" noWrap className={classes.title}>
        Conflict Resolution
      </Typography>
      <ConflictTable
        person={person}
        potentialMatches={potentialMatches}
        onSelectedMatchesChange={handleSelectedMatchesChange}
      />
      <Box className={classes.actionWrapper}>
        <Button color="primary" onClick={handleSaveChanges}>
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};
