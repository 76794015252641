import { Reservation, ReservationFromJSON } from "../model/reservation";
import { BaseApiClient, JSONApiResponse } from "../../core/api";
import { Page, PageFromJSON } from "../../core/page";
import {
  GetRequestParams,
  parseQueryParams,
} from "../../api/search-criteria-parsing";

const BASE_URL = "/api/reservation-service/reservations";

class ReservationApiClient extends BaseApiClient {
  async getReservation(reservationId: string): Promise<Reservation> {
    let url = BASE_URL.concat(`/${reservationId}`);

    const response = await this.fetchApi(url);
    return new JSONApiResponse(response, ReservationFromJSON).value();
  }

  async getDistinctStatus(): Promise<string[]> {
    let url = BASE_URL + "/distinct-status";
    const response = await this.fetchApi(url);
    return await response.json();
  }

  async getReservations(
    requestParams?: GetRequestParams<Reservation>
  ): Promise<Page<Reservation>> {
    let url = BASE_URL;

    if (requestParams) {
      url += "?" + parseQueryParams(requestParams);
    }
    const response = await this.fetchApi(url);
    return new JSONApiResponse(
      response,
      PageFromJSON(ReservationFromJSON)
    ).value();
  }
}

export const ReservationApi = new ReservationApiClient();
