import React, { useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Grid, Button, Box, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBack } from "@mui/icons-material";
import { Loading } from "../components/loading";
import { PersonsTable } from "../record-matching/components/persons-table";
import { useGuestIdentityDetailsPage } from "../record-matching/hooks/use-guest-identity-details-page";
import { LoadingShadow } from "../components/loading-shadow";
import { GuestIdentityWidget } from "../record-matching/components/guest-identity-widget/guest-identity-widget";
import { personsTableColumns } from "../record-matching/config/persons-table-config";

const useStyles = makeStyles((theme) => ({
  widgetContainer: {
    marginBottom: theme.spacing(3),
  },
  tableTitle: {
    marginBottom: theme.spacing(2),
  },
  detachButton: {
    float: "right",
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const GuestIdentityDetailsPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params: any = useParams();
  const {
    guestIdentity,
    guestIdentityFieldOptions,
    persons,
    loading,
    selectedPersons,
    handlePageNumberChange,
    handlePageSizeChange,
    handleSortChange,
    handleGuestIdentitySave,
    handlePersonSelectionToggle,
    detachPersons,
    fetchPersons,
    fetchGuestIdentity,
    fetchGuestIdentityFieldOptions,
  } = useGuestIdentityDetailsPage();

  useEffect(() => {
    if (params.guestIdentityId) {
      fetchGuestIdentity(params.guestIdentityId);
      fetchPersons(params.guestIdentityId);
      fetchGuestIdentityFieldOptions(params.guestIdentityId);
    }
  }, [
    fetchGuestIdentity,
    fetchPersons,
    fetchGuestIdentityFieldOptions,
    params.guestIdentityId,
  ]);

  const handleGoBack = useCallback(() => {
    navigate("/record-matching/guest-identities");
  }, [navigate]);

  if (!guestIdentity || !persons) {
    return <Loading />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box pb={2}>
          <Button
            startIcon={<ArrowBack />}
            onClick={handleGoBack}
            variant="text"
          >
            Back to Guest Identities
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.widgetContainer}>
        <GuestIdentityWidget
          guestIdentity={guestIdentity}
          guestIdentityFieldOptions={guestIdentityFieldOptions}
          onGuestIdentitySave={handleGuestIdentitySave}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.tableTitle}>
          Persons
        </Typography>
        {selectedPersons.length > 0 && (
          <Tooltip
            title={
              "This action will detach selected person records from existing one and create new Guest Identity"
            }
          >
            <Button
              size="small"
              className={classes.detachButton}
              onClick={detachPersons}
            >
              Detach
            </Button>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={12}>
        <LoadingShadow loading={loading}>
          <PersonsTable
            columns={personsTableColumns}
            data={persons}
            onPageNumberChange={handlePageNumberChange}
            onPageSizeChange={handlePageSizeChange}
            onSortChange={handleSortChange}
            onCheckboxValueChange={handlePersonSelectionToggle}
          />
        </LoadingShadow>
      </Grid>
    </Grid>
  );
};
