import { BaseApiClient, JSONApiResponse } from "../../core/api";
import { HealthCheckReportFromJSON } from "../model/health-check-report";

class HealthCheckApiClient extends BaseApiClient {
  private baseUrl = "/api/healthcheck-service";

  async getReport() {
    const response = await this.fetchApi(`${this.baseUrl}/report`);
    return new JSONApiResponse(response, HealthCheckReportFromJSON).value();
  }
}

export const HealthCheckApi = new HealthCheckApiClient();
