import { useCallback, useState } from "react";
import { HealthCheckApi } from "../api/health-check.api";
import { HealthCheckReport } from "../model/health-check-report";

interface UseHealthCheckState {
  loading: boolean;
  report: Partial<HealthCheckReport>;
}

interface UseHealthCheck extends UseHealthCheckState {
  fetchReport: () => Promise<void>;
}

export const useHealthCheck = (): UseHealthCheck => {
  const [{ loading, report }, setState] = useState<UseHealthCheckState>({
    loading: false,
    report: {},
  });
  const fetchReport = useCallback(async () => {
    try {
      setState((s) => ({ ...s, loading: true }));
      const reportResult = await HealthCheckApi.getReport();
      setState((s) => ({ ...s, report: reportResult }));
    } catch (e) {
      console.log(e);
    } finally {
      setState((s) => ({ ...s, loading: false }));
    }
  }, []);

  return {
    loading,
    fetchReport,
    report,
  };
};
