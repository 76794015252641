import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete } from "@mui/material";
import { GuestIdentityFieldOptions } from "../../model/guest-identity";
import { BaseIdentity } from "../../model/base-identity";

interface GuestIdentityEditProps {
  guestIdentity: BaseIdentity;
  guestIdentityFieldOptions?: GuestIdentityFieldOptions;
  onCancel: () => void;
  onSave: (guestIdentity: BaseIdentity) => void;
}

const editFields = [
  { name: "firstName", label: "First Name" },
  { name: "lastName", label: "Last Name" },
  { name: "email", label: "Email" },
  { name: "city", label: "City" },
  { name: "phone", label: "Phone" },
  { name: "addressLine1", label: "Address Line 1" },
  { name: "addressLine2", label: "Address Line 2" },
  { name: "countryCode", label: "Country" },
  { name: "postalCode", label: "Postal Code" },
  { name: "title", label: "Title" },
  { name: "gender", label: "Gender" },
  { name: "birthdate", label: "Birth Date" },
  { name: "preferredLanguage", label: "Preferred Language" },
  { name: "identificationType", label: "ID Type" },
  { name: "identificationNumber", label: "ID Number" },
  { name: "nationalityCountryCode", label: "Nationality" },
];

const useStyles = makeStyles(() => ({
  actionsContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "flex-end",
  },
  actionButton: {
    margin: 12,
  },
}));

export const GuestIdentityEditor: React.FC<GuestIdentityEditProps> = ({
  guestIdentity,
  guestIdentityFieldOptions,
  onCancel,
  onSave,
}) => {
  const classes = useStyles();
  const [internalGuestIdentity, setInternalGuestIdentity] =
    useState<BaseIdentity>(guestIdentity);

  useEffect(() => {
    setInternalGuestIdentity(guestIdentity);
  }, [guestIdentity]);

  return (
    <Grid container>
      <Grid container direction="row" spacing={1}>
        {editFields.map((editField) => {
          return (
            <Grid item xs={6} md={2} key={`edit-item-${editField.name}`}>
              <Autocomplete
                id={`gi-autocomplete-${editField.name}`}
                freeSolo
                openOnFocus
                options={
                  (guestIdentityFieldOptions &&
                    guestIdentityFieldOptions[
                      editField.name as keyof GuestIdentityFieldOptions
                    ]) ||
                  []
                }
                size="small"
                inputValue={
                  internalGuestIdentity[
                    editField.name as keyof BaseIdentity
                  ]?.toString() || ""
                }
                onInputChange={(_: any, newValue: string | null) => {
                  const updatedGuestIdentity = {
                    ...internalGuestIdentity,
                    [editField.name as keyof BaseIdentity]: newValue || null,
                  };
                  setInternalGuestIdentity(updatedGuestIdentity);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={editField.label}
                    margin="normal"
                  />
                )}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={12} className={classes.actionsContainer}>
        <Button
          className={classes.actionButton}
          onClick={() => onSave(internalGuestIdentity)}
        >
          Save
        </Button>
        <Button className={classes.actionButton} onClick={() => onCancel()}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};
