export interface MessageBirdSubscription {
  id: string;
  url: string;
  channelId: string;
  events: string[];
  status: string;
  createdDatetime: Date;
  updatedDatetime: Date;
}

export const MessageBirdSubscriptionFromJSON = (
  json: any
): MessageBirdSubscription => {
  if (!json) {
    return json;
  }

  return {
    id: json["id"],
    url: json["url"],
    channelId: json["channelId"],
    events: json["events"],
    status: json["status"],
    createdDatetime: new Date(json["createdDatetime"]),
    updatedDatetime: new Date(json["updatedDatetime"]),
  };
};
