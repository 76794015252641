import { ChangeEvent, useCallback, useState } from "react";
import { SortingRule } from "react-table";
import { Page } from "../../core/page";
import { GuestIdentityApi } from "../api/guest-identity.api";
import { GuestIdentityFieldOptions } from "../model/guest-identity";
import { BaseIdentity } from "../model/base-identity";
import { Person } from "../model/person";

interface UseGuestIdentityDetailsPage {
  guestIdentity: BaseIdentity | undefined;
  guestIdentityFieldOptions: GuestIdentityFieldOptions | undefined;
  persons: Page<Person> | undefined;
  loading: boolean;
  pageNumber: number;
  pageSize: number;
  sortingRules: SortingRule<Person>[];
  selectedPersons: Person[];
  fetchGuestIdentity: (id: number) => Promise<void>;
  fetchPersons: (id: number) => Promise<void>;
  handlePageNumberChange: (pageNumber: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleSortChange: (sort: SortingRule<Person>[]) => void;
  handlePersonSelectionToggle: (event: ChangeEvent, person: Person) => void;
  detachPersons: () => Promise<void>;
  handleGuestIdentitySave: (guestIdentity: BaseIdentity) => Promise<void>;
  fetchGuestIdentityFieldOptions: (id: number) => Promise<void>;
}

export const useGuestIdentityDetailsPage: () => UseGuestIdentityDetailsPage =
  () => {
    const [guestIdentity, setGuestIdentity] = useState<BaseIdentity>();
    const [guestIdentityFieldOptions, setGuestIdentityFieldOptions] =
      useState<GuestIdentityFieldOptions>();
    const [persons, setPersons] = useState<Page<Person>>();
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sortingRules, setSortingRules] = useState<SortingRule<Person>[]>([
      { id: "updatedAt", desc: true },
    ]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedPersons, setSelectedPersons] = useState<Person[]>([]);

    const handlePersonSelectionToggle = useCallback(
      (event: any, person: Person) => {
        event.target.checked
          ? setSelectedPersons([...selectedPersons, person])
          : setSelectedPersons(
              selectedPersons.filter((el) => el.id !== person.id)
            );
      },
      [setSelectedPersons, selectedPersons]
    );

    const handlePageNumberChange = useCallback((newPage: number) => {
      setPageNumber(newPage);
    }, []);

    const handlePageSizeChange = useCallback((newPageSize: number) => {
      setPageSize(newPageSize);
      setPageNumber(0);
    }, []);

    const handleSortChange = useCallback((sort: SortingRule<Person>[]) => {
      setSortingRules(sort);
    }, []);

    const handleGuestIdentitySave = useCallback(
      async (updatedGuestIdentity: BaseIdentity) => {
        const response = await GuestIdentityApi.updateGuestIdentity(
          updatedGuestIdentity
        );
        setGuestIdentity(response);
      },
      []
    );

    const fetchGuestIdentity = useCallback(async (guestIdentityId: number) => {
      setLoading(true);
      try {
        const response = await GuestIdentityApi.getGuestIdentityById(
          guestIdentityId
        );
        setGuestIdentity(response);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }, []);

    const fetchGuestIdentityFieldOptions = useCallback(
      async (guestIdentityId: number) => {
        setLoading(true);
        try {
          const response =
            await GuestIdentityApi.getFieldOptionsByGuestIdentity(
              guestIdentityId
            );
          setGuestIdentityFieldOptions(response);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      },
      []
    );

    const fetchPersons = useCallback(
      async (guestIdentityId: number) => {
        setLoading(true);
        try {
          const response = await GuestIdentityApi.getPersonsByGuestIdentity(
            guestIdentityId,
            {
              pageNumber,
              pageSize,
              sortingRules,
            }
          );
          setPersons(response);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      },
      [pageNumber, pageSize, sortingRules]
    );

    const detachPersons = useCallback(async () => {
      setLoading(true);
      try {
        const request: number[] = selectedPersons.map((person) => person.id);
        await GuestIdentityApi.detachPersons(request);
        if (guestIdentity) {
          fetchPersons(guestIdentity.id);
        }
        setSelectedPersons([]);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }, [fetchPersons, selectedPersons, guestIdentity]);

    return {
      guestIdentity,
      persons,
      loading,
      pageNumber,
      pageSize,
      sortingRules,
      guestIdentityFieldOptions,
      selectedPersons,
      handlePageNumberChange,
      handlePageSizeChange,
      handleSortChange,
      handleGuestIdentitySave,
      handlePersonSelectionToggle,
      detachPersons,
      fetchGuestIdentity,
      fetchPersons,
      fetchGuestIdentityFieldOptions,
    };
  };
