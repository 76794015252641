import React from "react";
import { useDebounce } from "../../util/use-debounce";
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  TableRowProps as MuiTableRowProps,
} from "@mui/material";
import { ChevronRight, ExpandMore } from "@mui/icons-material";

export function ExpandingRow({
  renderSummary,
  renderDetails,
  icons,
  tableRowProps,
}: {
  renderSummary: any;
  renderDetails: any;
  icons: [];
  tableRowProps?: MuiTableRowProps;
}) {
  const [open, setOpen] = React.useState(false);
  const debouncedOpen = useDebounce(open, 200);
  const shouldRenderDetails = open || debouncedOpen !== open;
  return (
    <>
      <TableRow hover {...tableRowProps}>
        <TableCell>
          <Typography noWrap>
            <IconButton
              size="small"
              onClick={() => {
                setOpen(!open);
              }}
              data-testid="expansion-toggle"
            >
              {open ? <ExpandMore /> : <ChevronRight />}
            </IconButton>
            {icons.map((iconData: any) => (
              <Tooltip key={iconData.tooltip} title={iconData.tooltip}>
                <IconButton size="small" onClick={iconData.openLink}>
                  {iconData.icon}
                </IconButton>
              </Tooltip>
            ))}
          </Typography>
        </TableCell>
        {renderSummary()}
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={12}>
          <Collapse in={open}>
            {shouldRenderDetails && renderDetails()}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
