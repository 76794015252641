export interface Folio {
  id: string;
  type: string;
  status: string;
  company: string;
  created: Date;
  updated: Date;
  closingDate: Date;
  debitor: string;
  reservationId: string;
  bookingId: string;
  isMainFolio: boolean;
  balance: any;
  property: any;
  folioWarnings: any | null;
  charges: any[] | null;
  payments: any[] | null;
  transitoryCharges: any[] | null;
  allowances: any[] | null;
  createdAt: Date;
  updatedAt: Date;
  stateChanges: any[] | null;
}

export function FolioFromJSON(json: any): Folio {
  if (!json) {
    return json;
  }

  return {
    id: json["id"],
    type: json["type"],
    status: json["status"],
    company: json["company"],
    created: new Date(json["created"]),
    updated: new Date(json["updated"]),
    closingDate: new Date(json["closingDate"]),
    debitor: json["debitor"],
    reservationId: json["reservationId"],
    bookingId: json["bookingId"],
    isMainFolio: json["isMainFolio"],
    balance: json["balance"],
    property: json["property"],
    folioWarnings: json["folioWarnings"] || null,
    charges: json["charges"] || null,
    payments: json["payments"] || null,
    transitoryCharges: json["transitoryCharges"] || null,
    stateChanges: json["stateChanges"] || null,
    allowances: json["allowances"] || null,
    createdAt: new Date(json["created"]),
    updatedAt: new Date(json["created"]),
  };
}
