import React, { useEffect, useState } from "react";
import { useReservationsPage } from "../reservation/hooks/use-reservations-page";
import { LoadingShadow } from "../components/loading-shadow";
import { ReservationsTable } from "../reservation/components/reservations-table";
import { Loading } from "../components/loading";
import * as H from "history";
import { useLocation } from "react-router-dom";

export interface ReservationPageHistoryState {
  searchString: string;
}

export const ReservationsPage: React.FC = () => {
  const {
    data,
    fetchData,
    loading,
    sortingRule,
    handleChangePage,
    handleFilterChange,
    handleSortChange,
    handleChangeRowsPerPage,
    searchParams,
  } = useReservationsPage();

  const location: H.Location<ReservationPageHistoryState> = useLocation();
  const locationSearchString = location?.state?.searchString;
  const [searchString, setSearchString] =
    useState<string>(locationSearchString);
  useEffect(() => {
    setSearchString(locationSearchString);
  }, [locationSearchString]);

  useEffect(() => {
    fetchData(
      searchParams.page,
      searchParams.rowsPerPage,
      sortingRule,
      searchParams.filterParams,
      searchString
    );
  }, [
    fetchData,
    searchParams.page,
    searchParams.rowsPerPage,
    searchParams.filterParams,
    sortingRule,
    searchString,
  ]);

  if (!data) {
    return <Loading />;
  }

  return (
    <LoadingShadow loading={loading}>
      <ReservationsTable
        data={data}
        globalFilter={searchString}
        handleFilterChange={handleFilterChange}
        handleSortChange={handleSortChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        handleSearchChange={setSearchString}
      />
    </LoadingShadow>
  );
};
