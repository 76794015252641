import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DeclinedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="https://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M11.998,24c-6.617,0-12-5.383-12-12c0-6.617,5.383-12,12-12c6.617,0,12,5.383,12,12C23.998,18.617,18.615,24,11.998,24z
          M11.998,1.5c-5.79,0-10.5,4.71-10.5,10.5c0,5.79,4.71,10.5,10.5,10.5c5.79,0,10.5-4.71,10.5-10.5
          C22.498,6.21,17.788,1.5,11.998,1.5z"
        />
        <path
          d="M16.498,17.25c-0.2,0-0.389-0.078-0.53-0.22l-3.97-3.97l-3.969,3.97c-0.141,0.142-0.33,0.22-0.53,0.22
          c-0.2,0-0.389-0.078-0.53-0.22c-0.142-0.142-0.22-0.33-0.22-0.53s0.078-0.389,0.22-0.53L10.937,12l-3.97-3.97
          C6.825,7.889,6.747,7.7,6.747,7.5c0-0.2,0.078-0.389,0.22-0.53c0.141-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22l3.97,3.97
          l3.969-3.97c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22c0.142,0.141,0.22,0.33,0.22,0.53c0,0.2-0.078,0.389-0.22,0.53
          L13.058,12l3.97,3.97c0.142,0.142,0.22,0.33,0.22,0.53s-0.078,0.389-0.22,0.53S16.698,17.25,16.498,17.25z"
        />
      </g>
    </svg>
  </SvgIcon>
);
