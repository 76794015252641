import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Logo from "../components/logo/Logo";

const useStyles = makeStyles(() => ({
  forbiddenMessage: {
    textAlign: "center",
  },
}));

export const ForbiddenPage = () => {
  const classes = useStyles();

  return (
    <div className="App">
      <header className="App-header">
        <Logo />
      </header>
      <h3 className={classes.forbiddenMessage}>Access forbidden!</h3>
    </div>
  );
};
