import { useCallback, useState } from "react";
import { ReservationApi } from "../api/reservation.api";
import { Reservation } from "../model/reservation";

export const useReservationPreview = () => {
  const [data, setData] = useState<Reservation>();
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async (reservationId: string) => {
    try {
      const response = await ReservationApi.getReservation(reservationId);
      setData(response);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    data,
    fetchData,
    loading,
  };
};
