import { useCallback, useState } from "react";
import { Person } from "../model/person";
import { GuestIdentityApi } from "../api/guest-identity.api";
import { BaseIdentity } from "../model/base-identity";

interface UseConflictResolutionPage {
  person: Person | undefined;
  potentialMatches: BaseIdentity[];
  selectedMatches: BaseIdentity[];
  loading: boolean;
  fetchPerson: (id: number) => Promise<void>;
  fetchConflicts: (id: number) => Promise<void>;
  handleSelectedMatchesChange: (selectedMatches: BaseIdentity[]) => void;
  resolveRecordConflict: (id: number) => Promise<void>;
}

export const useConflictResolutionPage: () => UseConflictResolutionPage =
  () => {
    const [person, setPerson] = useState<Person>();
    const [loading, setLoading] = useState<boolean>(false);
    const [potentialMatches, setPotentialMatches] = useState<BaseIdentity[]>(
      []
    );
    const [selectedMatches, setSelectedMatches] = useState<BaseIdentity[]>([]);

    const fetchPerson = useCallback(async (id: number) => {
      setLoading(true);
      try {
        const response = await GuestIdentityApi.getPersonById(id);
        setPerson(response);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }, []);

    const fetchConflicts = useCallback(async (id: number) => {
      setLoading(true);
      try {
        const response = await GuestIdentityApi.predictPerson(id);
        setPotentialMatches(response.guestIdentities);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }, []);

    const resolveRecordConflict = useCallback(
      async (id: number) => {
        setLoading(true);
        try {
          await GuestIdentityApi.resolveRecordConflict(
            id,
            selectedMatches.map(
              (selectedMatch: BaseIdentity) => selectedMatch.id
            )
          );
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      },
      [selectedMatches]
    );

    const handleSelectedMatchesChange = useCallback((matches: any) => {
      setSelectedMatches(matches);
    }, []);

    return {
      person,
      potentialMatches,
      loading,
      selectedMatches,
      fetchPerson,
      fetchConflicts,
      resolveRecordConflict,
      handleSelectedMatchesChange,
    };
  };
