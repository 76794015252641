import React, { useCallback, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Loading } from "../loading";
import { Box, Button } from "@mui/material";
import {
  SubscriptionList,
  SubscriptionListAccessors,
} from "./subscription-list";
import { CreateWebhookSubscription } from "./create-webhook-subscription";
import { ADMIN_ROLE, useAuth } from "@likemagic-tech/sv-magic-library";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  titleWrapper: {
    display: "flex",
    marginBottom: theme.spacing(2),
    justifyContent: "flex-end",
  },
}));

interface WebhooksPageProps<Subscription, Subject, Event> {
  subscriptions: Subscription[];
  loading: boolean;
  fetchSubscriptions: () => Promise<void>;
  removeSubscription: (id: string) => Promise<void>;
  subscriptionListAccessors: SubscriptionListAccessors;
  fetchSubjects: () => Promise<Subject[]>;
  fetchEvents: () => Promise<Event[]>;
  createSubscription: (formValue: {
    subjectId: string;
    events: Event[];
  }) => Promise<void>;
  formComp: React.FC<any>;
}

export function WebhooksPage<Subscription, Subject, Event>({
  subscriptions,
  loading: loadingSubscriptions,
  fetchSubscriptions,
  removeSubscription,
  subscriptionListAccessors,
  fetchSubjects,
  fetchEvents,
  formComp,
  createSubscription,
}: WebhooksPageProps<Subscription, Subject, Event>): React.ReactElement<
  WebhooksPageProps<Subscription, Subject, Event>
> {
  const classes = useStyles();
  const auth = useAuth();

  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  const onHideCreateDialog = useCallback(
    (reason?: string) => {
      setShowCreateDialog(false);
      if (reason === "submitted") {
        fetchSubscriptions();
      }
    },
    [fetchSubscriptions]
  );

  if (loadingSubscriptions) {
    return <Loading />;
  }

  return (
    <Box>
      <Box className={classes.titleWrapper}>
        <Button
          variant="outlined"
          onClick={() => setShowCreateDialog(true)}
          disabled={!auth.hasRole(ADMIN_ROLE)}
        >
          Create
        </Button>
      </Box>

      <SubscriptionList
        subscriptions={subscriptions}
        onDelete={removeSubscription}
        accessors={subscriptionListAccessors}
      />

      <CreateWebhookSubscription
        show={showCreateDialog}
        onHide={onHideCreateDialog}
        fetchSubjects={fetchSubjects}
        fetchEvents={fetchEvents}
        createSubscription={createSubscription}
        formComp={formComp}
      />
    </Box>
  );
}
