import { parseDate } from "../util/date-util";

export enum ReferenceType {
  Booking = "BOOKING",
  Reservation = "RESERVATION",
}

export enum Origin {
  StayKooook = "STAY_KOOOOK",
}

export interface ObjectReference {
  id: number;
  objectId: string;
  referenceType: ReferenceType;
  origin: Origin;
  metadata: Object | null;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export function ObjectReferenceFromJSON(json: any): ObjectReference {
  if (!json) {
    return json;
  }

  return {
    id: json["id"],
    objectId: json["objectId"],
    referenceType: json["referenceType"] as ReferenceType,
    origin: json["origin"] as Origin,
    metadata: json["metadata"],
    createdAt: parseDate(json["createdAt"]),
    updatedAt: parseDate(json["updatedAt"]),
  };
}
