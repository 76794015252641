export interface ApaleoAddress {
  addressLine1: string;
  addressLine2?: string;
  postalCode: string;
  city: string;
  countryCode: string;
}

export const ApaleoAddressFromJSON = (json: any): ApaleoAddress => {
  if (!json) {
    return json;
  }
  return {
    addressLine1: json["addressLine1"],
    addressLine2: json["addressLine2"],
    postalCode: json["postalCode"],
    city: json["city"],
    countryCode: json["countryCode"],
  };
};
