export interface HealthCheckReportIncident {
  from: Date;
  to: Date;
}

export interface HealthCheckReportItem {
  origin: string;
  details?: HealthCheckReportItemDetails;
  incidents: HealthCheckReportIncident[];
}
export interface HealthCheckReportItemDetails {
  deviceId: string;
  deviceName: string;
}

export interface HealthCheckReport {
  [key: string]: HealthCheckReportItem | null;
}

const HealthCheckReportIncidentFromJSON = (
  json: any
): HealthCheckReportIncident => {
  return {
    from: new Date(json["from"]),
    to: new Date(json["to"]),
  };
};

export const HealthCheckReportItemFromJSON = (
  json: any
): HealthCheckReportItem | null => {
  if (!json) {
    return null;
  }

  return {
    origin: json["origin"],
    details: json["details"],
    incidents: (json["incidents"] || []).map(HealthCheckReportIncidentFromJSON),
  };
};

export const HealthCheckReportFromJSON = (json: any): HealthCheckReport => {
  return Object.keys(json).reduce(
    (acc, next) => ({
      ...acc,
      [next]: HealthCheckReportItemFromJSON(json[next]),
    }),
    {}
  );
};
