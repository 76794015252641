import {
  LabelHeader,
  SortingHeader,
} from "../../components/table/table-headers";
import { Column } from "react-table";
import { Reservation } from "../model/reservation";
import {
  DateAccessor,
  IdentityAccessor,
} from "../../components/table/table-accessors";
import { IdCheckAccessor } from "../components/operation-table-accessors";
import {
  DateFilter,
  ReservationGuestJourneyIdCheckStatusSelectFilter,
  ReservationGuestJourneyStatusSelectFilter,
} from "../../components/table/table-filters";

export const operationsTableColumns: Column<Reservation>[] = [
  { Header: LabelHeader("Actions"), id: "actions" },

  // Reservation ID
  {
    Header: SortingHeader("Reservation ID"),
    accessor: IdentityAccessor("id"),
    id: "id",
    disableFilters: true,
  },
  //Is ID Checked
  {
    Header: SortingHeader("ID Check"),
    accessor: IdCheckAccessor("idCheckStatus"),
    id: "idCheckStatus",
    Filter: ReservationGuestJourneyIdCheckStatusSelectFilter,
  },
  // Property ID
  {
    Header: SortingHeader("Property ID"),
    accessor: IdentityAccessor("propertyId"),
    id: "propertyId",
    disableFilters: true,
  },
  // Status
  {
    Header: SortingHeader("Status"),
    accessor: IdentityAccessor("status"),
    id: "status",
    Filter: ReservationGuestJourneyStatusSelectFilter,
  },
  // Arrival date and time
  {
    Header: SortingHeader("Arrival date"),
    accessor: DateAccessor("arrival"),
    id: "arrival",
    Filter: DateFilter,
  },
  // Departure date and time
  {
    Header: SortingHeader("Departure date"),
    accessor: DateAccessor("departure"),
    id: "departure",
    disableFilters: true,
  },
  // Check-in date and time
  {
    Header: SortingHeader("Check-in time"),
    accessor: DateAccessor("checkInTime"),
    id: "checkInTime",
    disableFilters: true,
  },
  // Check-out date and time
  {
    Header: SortingHeader("Check-out time"),
    accessor: DateAccessor("checkOutTime"),
    id: "checkOutTime",
    disableFilters: true,
  },
  // Primary guest first name and and last name
  {
    Header: "Primary guest",
    accessor: (reservation) =>
      reservation?.primaryGuest?.firstName +
      " " +
      reservation?.primaryGuest?.lastName,
    id: "primaryGuest",
    disableFilters: true,
  },
  //Magic link
  {
    Header: "Magic link",
    accessor: IdentityAccessor("magicLink"),
    id: "magicLink",
    disableFilters: true,
  },
  // Created at
  {
    Header: SortingHeader("Created at"),
    accessor: DateAccessor("createdAt"),
    id: "createdAt",
    disableFilters: true,
  },
  // Updated at
  {
    Header: SortingHeader("Updated at"),
    accessor: DateAccessor("updatedAt"),
    id: "updatedAt",
    disableFilters: true,
  },
];
