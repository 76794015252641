import React, { PropsWithChildren, useCallback, useState } from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ApaleoWebhooksPage } from "../apaleo/pages/apaleo-webhooks.page";
import { MessageBirdWebhooksPage } from "../messagebird/pages/messagebird-webhooks.page";
import { MessageBirdV2WebhooksPage } from "../messagebird/pages/messagebird-v2-webhooks.page";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    display: "flex",
    minHeight: theme.spacing(8),
  },
  tabPanel: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
}));

interface TabPanelProps {
  index: number;
  value: number;
}

const TabPanel: React.FC<PropsWithChildren<TabPanelProps>> = ({
  children,
  index,
  value,
}) => {
  const classes = useStyles();
  return (
    <div hidden={value !== index} className={classes.tabPanel}>
      {children}
    </div>
  );
};

export const WebhooksPage: React.FC = () => {
  const classes = useStyles();
  const [tab, setTab] = useState<number>(0);
  const handleChangeTab = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setTab(newValue);
    },
    []
  );
  return (
    <div>
      <Typography component="h1" variant="h5" noWrap className={classes.title}>
        Webhook Subscriptions
      </Typography>
      <div className={classes.root}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          orientation="vertical"
          variant="scrollable"
        >
          <Tab label="Apaleo" />
          <Tab label="MessageBird" />
          <Tab label="MessageBird V2" />
        </Tabs>
        <TabPanel index={0} value={tab}>
          <ApaleoWebhooksPage />
        </TabPanel>
        <TabPanel index={1} value={tab}>
          <MessageBirdWebhooksPage />
        </TabPanel>
        <TabPanel index={2} value={tab}>
          <MessageBirdV2WebhooksPage />
        </TabPanel>
      </div>
    </div>
  );
};
