import React from "react";
import { TableSortLabel } from "@mui/material";
import { HeaderProps } from "react-table";

export const LabelHeader = (label: string) => label;

export function SortingHeader<D extends object>(label: string) {
  return (instance: HeaderProps<D>) => {
    const { column } = instance;
    return (
      <TableSortLabel
        active={column.isSorted}
        direction={column.isSortedDesc ? "desc" : "asc"}
        {...column.getSortByToggleProps()}
      >
        {label}
      </TableSortLabel>
    );
  };
}
