import React, { useCallback, useEffect, useRef } from "react";
import {
  useAsyncDebounce,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Page } from "../../core/page";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { JsonTreeView } from "../../components/json-tree-view";
import { ExpandingRow } from "../../components/table/expanding-row";
import { messagesTableColumns } from "../config/messages-table-config";
import { DefaultColumnFilter } from "../../components/table/table-filters";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Message } from "../model/message";
import ReplayIcon from "@mui/icons-material/Replay";
import { useResendMessages } from "../hooks/use-resend-messages";
import { ResendMessageFullScreenConfirm } from "./resend-message-full-screen-confirm";
import { useAuth } from "@likemagic-tech/sv-magic-library";
import { useNavigate } from "react-router-dom";

// todo check if can be comminized
interface MessagesTableProps {
  data: Page<Message>;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  handleFilterChange: (event: any) => void;
  handleSortChange: (sort: { id: string; desc?: boolean }[]) => void;
}

export const MessagesTable: React.FC<MessagesTableProps> = ({
  data,
  handleSortChange,
  handleChangeRowsPerPage,
  handleChangePage,
  handleFilterChange,
}) => {
  const didMountRef = useRef(false);

  const handleChangePageAdapter = useCallback(
    (event: any, newPage: number) => handleChangePage(newPage),
    [handleChangePage]
  );

  const handleChangeRowsPerPageAdapter = useCallback(
    (event: any) => handleChangeRowsPerPage(parseInt(event.target.value, 10)),
    [handleChangeRowsPerPage]
  );

  const handleFilterChangeAdapter = useAsyncDebounce(
    (filtersObject) => handleFilterChange(filtersObject),
    300
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const computeData: any = React.useMemo(
    () => data.content.map((message: Message) => message),
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, filters },
  } = useTable(
    {
      columns: messagesTableColumns,
      data: computeData,
      manualFilters: true,
      manualSortBy: true,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: "latestChangeTimestamp",
            desc: true,
          },
        ],
        filters: [],
      },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (didMountRef.current) {
      handleSortChange(sortBy);
    }
  }, [sortBy, handleSortChange]);

  useEffect(() => {
    if (didMountRef.current) {
      handleFilterChangeAdapter(filters);
    } else {
      didMountRef.current = true;
    }
  }, [filters, handleFilterChangeAdapter]);

  interface PrepareIconProps {
    openLink: () => void;
    icon: any;
    tooltip: string;
  }

  const {
    messageToResend,
    resendMessage,
    discardMessageToResend,
    setMessageToResend,
  } = useResendMessages();
  const auth = useAuth();

  const prepareIconsData = useCallback(
    (message: Message): any => {
      const arrayOfClickableIcons: Array<PrepareIconProps> = [];

      arrayOfClickableIcons.push({
        openLink: () => {
          navigate(`/reservations/${message.reservationId}`);
        },
        icon: <OpenInNewIcon />,
        tooltip: "Open Reservation",
      });

      if (auth.hasAdminOrOperatorRole()) {
        arrayOfClickableIcons.push({
          openLink: () => {
            setMessageToResend(message);
          },
          icon: <ReplayIcon />,
          tooltip: "Resend message",
        });
      }

      return arrayOfClickableIcons;
    },
    [navigate, auth, setMessageToResend]
  );
  return (
    <Paper>
      <ResendMessageFullScreenConfirm
        resendMessage={resendMessage}
        messageToResend={messageToResend}
        discardMessageToResend={discardMessageToResend}
        open={Boolean(messageToResend)}
      />
      <TableContainer>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    <>
                      {column.render("Header")}
                      <br />
                      {column.canFilter ? column.render("Filter", {}) : null}
                    </>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                <ExpandingRow
                  {...row.getRowProps()}
                  key={"" + row.getRowProps().key + row.original.messageId}
                  renderSummary={() => {
                    const tail = row.cells.slice(1);
                    return tail.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          <Typography noWrap>
                            <>{cell.render("Cell")}</>
                          </Typography>
                        </TableCell>
                      );
                    });
                  }}
                  renderDetails={() => (
                    <Box p={2}>
                      <JsonTreeView data={row.original} />
                    </Box>
                  )}
                  icons={prepareIconsData(row.original)}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.totalElements}
        rowsPerPage={data.size}
        page={data.number}
        onPageChange={handleChangePageAdapter}
        onRowsPerPageChange={handleChangeRowsPerPageAdapter}
      />
    </Paper>
  );
};
