import React, { PropsWithChildren } from "react";
import { CssBaseline, StyledEngineProvider, Theme } from "@mui/material";
import {
  Application,
  AuthProvider,
  CheckKeycloakInstance,
  TenantProvider,
  TenantThemeProvider,
} from "@likemagic-tech/sv-magic-library";
import { MetaUpdate } from "../util/meta-update";
import { FaviconSetter } from "../components/favicon/favicon-setter";
import { themeOverride } from "./theme-override";
import { BrowserRouter } from "react-router-dom";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const ApplicationProviders: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <TenantProvider application={Application.MONITORING}>
      <AuthProvider>
        <CheckKeycloakInstance>
          <TenantThemeProvider
            overrideComponents={themeOverride}
            overrideTheme="likemagic-theme"
          >
            <FaviconSetter />
            <MetaUpdate />
            <StyledEngineProvider injectFirst>
              <CssBaseline />
              <BrowserRouter>{children}</BrowserRouter>
            </StyledEngineProvider>
          </TenantThemeProvider>
        </CheckKeycloakInstance>
      </AuthProvider>
    </TenantProvider>
  );
};
