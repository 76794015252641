import { SortingRule } from "react-table";
import { BaseApiClient, JSONApiResponse } from "../../core/api";
import { Page, PageFromJSON } from "../../core/page";
import { ConflictFromJSON, Conflict } from "../model/conflict";
import { PersonFromJSON, Person } from "../model/person";
import {
  GuestIdentityFieldOptions,
  GuestIdentityFieldOptionsFromJSON,
} from "../model/guest-identity";
import { Prediction, PredictionFromJSON } from "../model/prediction";
import { BaseIdentity, BaseIdentityFromJSON } from "../model/base-identity";
import {
  GetRequestParams,
  parseQueryParams,
} from "../../api/search-criteria-parsing";

const BASE_URL = "/api/guest-identity-service";

interface PageableRequestParams<T> {
  pageNumber: number;
  pageSize: number;
  sortingRules: SortingRule<T>[];
  freeTextSearch?: string;
}

class GuestIdentityApiClient extends BaseApiClient {
  async getConflict(): Promise<Conflict> {
    const response = await this.fetchApi(`${BASE_URL}/persons/conflicts`);
    return new JSONApiResponse(response, ConflictFromJSON).value();
  }

  async getPagedEntities<T>(
    resourceUrl: string,
    responseMapper: (json: any) => T,
    requestParams?: GetRequestParams<T>
  ): Promise<Page<T>> {
    let url = `${BASE_URL}/${resourceUrl}`;
    if (requestParams) {
      url += "?" + parseQueryParams(requestParams);
    }
    const response = await this.fetchApi(url);
    return new JSONApiResponse(response, PageFromJSON(responseMapper)).value();
  }

  async getGuestIdentityById(guestIdentityId: number): Promise<BaseIdentity> {
    const response = await this.fetchApi(
      `${BASE_URL}/guest-identities/${guestIdentityId}`
    );
    return new JSONApiResponse(response, BaseIdentityFromJSON).value();
  }

  async getFieldOptionsByGuestIdentity(
    guestIdentityId: number
  ): Promise<GuestIdentityFieldOptions> {
    const response = await this.fetchApi(
      `${BASE_URL}/guest-identities/field-options/${guestIdentityId}`
    );
    return new JSONApiResponse(
      response,
      GuestIdentityFieldOptionsFromJSON
    ).value();
  }

  async getPersonById(id: number): Promise<Person> {
    let url = `${BASE_URL}/persons/${id}`;
    const response = await this.fetchApi(url);
    return new JSONApiResponse(response, PersonFromJSON).value();
  }

  async predictPerson(id: number): Promise<Prediction> {
    let url = `${BASE_URL}/prediction/${id}`;
    const response = await this.fetchApi(url, {
      method: "POST",
    });
    return new JSONApiResponse(response, PredictionFromJSON).value();
  }

  async resolveRecordConflict(
    personId: number,
    guestIdentities: number[]
  ): Promise<Person> {
    let url = `${BASE_URL}/resolve-record-conflict`;
    const response = await this.fetchApi(url, {
      method: "POST",
      body: JSON.stringify({
        personId,
        guestIdentities,
      }),
    });
    return new JSONApiResponse(response, PersonFromJSON).value();
  }

  async getPersonsByGuestIdentity(
    guestIdentityId: number,
    requestParams: PageableRequestParams<Person>
  ): Promise<Page<Person>> {
    let url = `${BASE_URL}/persons`;
    if (requestParams) {
      url +=
        `?search=guestIdentityId:${guestIdentityId}&` +
        GuestIdentityApiClient.parsePageableParams(requestParams);
    }
    const response = await this.fetchApi(url);
    return new JSONApiResponse(response, PageFromJSON(PersonFromJSON)).value();
  }

  async updateGuestIdentity(
    guestIdentity: BaseIdentity
  ): Promise<BaseIdentity> {
    let url = `${BASE_URL}/guest-identities`;
    const response = await this.fetchApi(url, {
      method: "PATCH",
      body: JSON.stringify(guestIdentity),
    });
    return new JSONApiResponse(response, BaseIdentityFromJSON).value();
  }

  async detachPersons(personIds: number[]): Promise<Person[]> {
    let url = `${BASE_URL}/detach-persons`;
    const response = await this.fetchApi(url, {
      method: "POST",
      body: JSON.stringify({
        personIds,
      }),
    });
    return new JSONApiResponse(response, (json) =>
      (json as any[]).map(PersonFromJSON)
    ).value();
  }

  async getDistinctStatus(): Promise<string[]> {
    let url = BASE_URL + "/persons/distinct-status";
    const response = await this.fetchApi(url);
    return response.json();
  }

  private static parsePageableParams(
    requestParams?: PageableRequestParams<any>
  ): string {
    if (!requestParams) {
      return "";
    }
    const result = [];
    if (requestParams.pageNumber !== undefined) {
      result.push(`page=${encodeURIComponent(requestParams.pageNumber)}`);
    }
    if (requestParams.pageSize !== undefined) {
      result.push(`size=${encodeURIComponent(requestParams.pageSize)}`);
    }
    if (requestParams.sortingRules !== undefined) {
      result.push(
        ...requestParams.sortingRules.map(
          (rule) => `sort=${rule.id},${rule.desc ? "desc" : "asc"}`
        )
      );
    }
    if (requestParams.freeTextSearch) {
      result.push(
        `search=freeTextSearch~${encodeURIComponent(
          requestParams.freeTextSearch
        )}`
      );
    }
    return result.join("&");
  }
}

export const GuestIdentityApi = new GuestIdentityApiClient();
