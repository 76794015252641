import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import {
  Link as MuiLink,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
} from "@mui/material";

interface ListItemLinkProps extends ListItemTextProps {
  to: RouterLinkProps["to"] | string;
  icon: React.ReactNode;
  external?: boolean;
}

export const ListItemLink: React.FC<ListItemLinkProps> = ({
  icon,
  primary,
  to,
  external,
}: ListItemLinkProps) => {
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref: React.Ref<HTMLAnchorElement>) =>
        external ? (
          <MuiLink
            href={to as string}
            target="_blank"
            {...linkProps}
            rel="noopener noreferrer"
            color="inherit"
          />
        ) : (
          <RouterLink ref={ref} to={to} {...linkProps} />
        )
      ),
    [to, external]
  );

  return (
    <li>
      <ListItem button component={CustomLink}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};
