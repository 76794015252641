import { Notification, NotificationFromJSON } from "./notification";
import { Folio, FolioFromJSON } from "./folio";

export interface Reservation {
  id: string;
  reservationId: string;
  magicId: string;
  flowLink: string;
  flowLinksEN: string;
  flowLinksDE: string;
  bookingId: string;
  bookingMagicId: string;
  bookerFlowLink: string;
  bookerFlowLinksEN: string;
  bookerFlowLinksDE: string;
  apaleoUrl: string;
  monitoringUrl: string;
  groupBooking: boolean;
  guestFlowCompleted: boolean;
  siblingReservationCount: number;
  status: string;
  apaleoCreatedAt: Date | null;
  timestamp: Date | null;
  createdAt: Date | null;
  updatedAt: Date | null;
  comment: string | null;
  guestComment: string | null;
  propertyId: string;
  channelCode: string;
  source: string;
  checkInTime: Date | null;
  arrival: Date | null;
  checkOutTime: Date | null;
  departure: Date | null;
  primaryGuest: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  booking: {
    booker: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
  };
  additionalGuests: Object;
  adults: number;
  childrenAges: Object;
  unit: Object;
  unitGroup: Object;
  balance: Object;
  property: Object;
  ratePlan: Object;
  cancellationTime: Date | null;
  cancellationFee: Object;
  paymentAccount: Object;
  payableAmount: Object;
  timeSlices: Object;
  taxDetails: Object;
  noShowFee: Object;
  services: Object;
  stateChanges: Array<ReservationState>;
  notification: Notification | null;
  folios: Folio[] | null;
}

export function ReservationFromJSON(json: any): Reservation {
  if (!json) {
    return json;
  }

  return {
    id: json["id"],
    reservationId: json["id"], //TODO remap it to reservationId
    magicId: json["magicId"],
    flowLink: json["flowLink"],
    flowLinksEN: json["flowLinksEN"],
    flowLinksDE: json["flowLinksDE"],
    bookingId: json["bookingId"],
    bookingMagicId: json["bookingMagicId"],
    bookerFlowLink: json["bookerFlowLink"],
    bookerFlowLinksEN: json["bookerFlowLinksEN"],
    bookerFlowLinksDE: json["bookerFlowLinksDE"],
    monitoringUrl: json["monitoringUrl"],
    apaleoUrl: json["apaleoUrl"],
    groupBooking: json["groupBooking"],
    guestFlowCompleted: json["guestFlowCompleted"],
    siblingReservationCount: json["siblingReservationCount"],
    status: json["status"],
    apaleoCreatedAt: parseDate(json["exactCreatedAt"]),
    timestamp: parseDate(json["timestamp"]),
    createdAt: parseDate(json["createdAt"]),
    updatedAt: parseDate(json["updatedAt"]),
    comment: json["comment"],
    guestComment: json["guestComment"],
    propertyId: json["propertyId"],
    channelCode: json["channelCode"],
    source: json["source"],
    checkInTime: parseDate(json["checkInTime"]),
    arrival: parseDate(json["arrival"]),
    checkOutTime: parseDate(json["checkOutTime"]),
    departure: parseDate(json["departure"]),
    primaryGuest: json["primaryGuest"],
    booking: json["booking"],
    additionalGuests: json["additionalGuests"],
    adults: json["adults"],
    childrenAges: json["childrenAges"],
    unit: json["unit"],
    unitGroup: json["unitGroup"],
    balance: json["balance"],
    property: json["property"],
    ratePlan: json["ratePlan"],
    cancellationTime: parseDate(json["cancellationTime"]),
    cancellationFee: json["cancellationFee"],
    paymentAccount: json["paymentAccount"],
    payableAmount: json["payableAmount"],
    timeSlices: json["timeSlices"],
    taxDetails: json["taxDetails"],
    noShowFee: json["noShowFee"],
    services: json["services"],
    stateChanges: json["stateChanges"]
      ? json["stateChanges"].map((item: any) => ReservationStateFromJSON(item))
      : [],
    notification: NotificationFromJSON(json["notification"]),
    folios: json["folios"]
      ? json["folios"].map((item: any) => FolioFromJSON(item))
      : [],
  };
}

export interface ReservationState {
  type: string;
  eventId: number;
  timestamp: number;
  apaleoEventId: string;
}

const parseDate = (dateString: string) =>
  dateString ? new Date(dateString) : null;

export function ReservationStateFromJSON(json: any) {
  if (!json) {
    return json;
  }

  return {
    type: json["type"],
    eventId: json["eventId"],
    timestamp: new Date(json["timestamp"]),
    apaleoEventId: json["apaleoEventId"],
  };
}
