import React, { useEffect, useState, FC } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { InputBase, Box } from "@mui/material";
import { useAsyncDebounce } from "react-table";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    border: "1px solid #0000003b",
    borderRadius: 5,
    width: "30%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    width: "100%",
  },
}));

export const SearchBar: FC<{
  className?: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}> = ({ className, value, onChange, placeholder = "Find..." }) => {
  const classes = useStyles();

  const [internalValue, setInternalValue] = useState(value);

  const onInternalValueChange = useAsyncDebounce((updatedValue) => {
    onChange(updatedValue || undefined);
  }, 200);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Box className={className}>
      <InputBase
        placeholder={placeholder}
        value={internalValue || ""}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        onChange={(event) => {
          setInternalValue(event.target.value);
          onInternalValueChange(event.target.value);
        }}
      />
    </Box>
  );
};
