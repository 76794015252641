import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../components/loading";
import { LoadingShadow } from "../components/loading-shadow";
import { JsonTreeView } from "../components/json-tree-view";
import { useReservationPreview } from "../reservation/hooks/use-reservation-preview-page";
import { Box } from "@mui/material";

export const ReservationsPreviewPage: React.FC = () => {
  const { data, fetchData, loading } = useReservationPreview();

  const params: any = useParams();
  useEffect(() => {
    if (params.reservationId) {
      fetchData(params.reservationId);
    }
  }, [fetchData, params.reservationId]);

  if (!params.reservationId) {
    return <Box>No content</Box>;
  } else if (!data) {
    return <Loading />;
  }

  return (
    <LoadingShadow loading={loading}>
      <JsonTreeView data={data} />
    </LoadingShadow>
  );
};
