import React from "react";
import { Typography } from "@mui/material";

export const Loading: React.FC = () => {
  return (
    <Typography variant="h5" component="h2">
      Loading
    </Typography>
  );
};
