import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { previewDateFormat, UtcDateFormat } from "../formats";
import moment from "moment";
import { MessageApi } from "../../messagebird/api/message.api";
import { ReservationApi } from "../../reservation/api/reservation.api";
import { GuestIdentityApi } from "../../record-matching/api/guest-identity.api";
import { GuestJourneyApi } from "../../operation/api/guest-journey.api";
import { IdCheckStatus } from "../../operation/model/reservation";

// subset of UseFiltersColumnProps<D> in react-table/index.d.ts
export interface CustomUseFiltersColumnProps {
  setFilter: (newFilterValue: FilterValue | null) => void;
  filterValue: FilterValue;
}

export interface FilterValue {
  type: "date" | "exact" | "lax";
  value: any;
}

const filterInput: any = ({
  filterValue,
  setFilter,
  placeholder,
}: any): any => (
  <input
    value={filterValue || ""}
    onChange={(e) => setFilter(e.target.value)}
    placeholder={placeholder}
  />
);

export function DefaultColumnFilter({
  column: { filterValue, setFilter },
}: {
  column: CustomUseFiltersColumnProps;
}) {
  return filterInput({
    filterValue: filterValue?.value,
    setFilter: (value: string) =>
      value ? setFilter({ value: value, type: "lax" }) : setFilter(null),
    placeholder: `Search...`,
  });
}

export function ReservationApaleoStatusSelectFilter({
  column: { filterValue, setFilter },
}: {
  column: CustomUseFiltersColumnProps;
}) {
  const options = useMemo(async () => ReservationApi.getDistinctStatus(), []);

  return (
    <SelectComponent
      options={options}
      filterValue={filterValue?.value}
      setFilter={(value: string) =>
        value
          ? setFilter({
              value: value,
              type: "exact",
            })
          : setFilter(null)
      }
    />
  );
}

export function ReservationGuestJourneyStatusSelectFilter({
  column: { filterValue, setFilter },
}: {
  column: CustomUseFiltersColumnProps;
}) {
  const options = useMemo(async () => GuestJourneyApi.getDistinctStatus(), []);

  return (
    <SelectComponent
      options={options}
      filterValue={filterValue?.value}
      setFilter={(value: string) =>
        value
          ? setFilter({
              value: value,
              type: "exact",
            })
          : setFilter(null)
      }
    />
  );
}

export function ReservationGuestJourneyIdCheckStatusSelectFilter({
  column: { filterValue, setFilter },
}: {
  column: CustomUseFiltersColumnProps;
}) {
  const options = [
    IdCheckStatus.CONFIRMED,
    IdCheckStatus.UNCONFIRMED,
    IdCheckStatus.DECLINED,
  ];

  return (
    <SelectComponent
      options={options}
      filterValue={filterValue?.value}
      setFilter={(value: string) =>
        value
          ? setFilter({
              value: value,
              type: "exact",
            })
          : setFilter(null)
      }
    />
  );
}

export function MessageTemplateTypeSelectFilter({
  column: { filterValue, setFilter },
}: {
  column: CustomUseFiltersColumnProps;
}) {
  const options = useMemo(async () => MessageApi.getDistinctTemplate(), []);

  return (
    <SelectComponent
      options={options}
      filterValue={filterValue?.value}
      setFilter={(value: string) =>
        value
          ? setFilter({
              value: value,
              type: "exact",
            })
          : setFilter(null)
      }
    />
  );
}

export function MessagePlatformSelectFilter({
  column: { filterValue, setFilter },
}: {
  column: CustomUseFiltersColumnProps;
}) {
  const options = useMemo(async () => MessageApi.getDistinctPlatform(), []);

  return (
    <SelectComponent
      options={options}
      filterValue={filterValue?.value}
      setFilter={(value: string) =>
        value
          ? setFilter({
              value: value,
              type: "exact",
            })
          : setFilter(null)
      }
    />
  );
}

export function MessageStatusSelectFilter({
  column: { filterValue, setFilter },
}: {
  column: CustomUseFiltersColumnProps;
}) {
  const options = useMemo(async () => MessageApi.getDistinctStatus(), []);

  return (
    <SelectComponent
      options={options}
      filterValue={filterValue?.value}
      setFilter={(value: string) =>
        value
          ? setFilter({
              value: value,
              type: "exact",
            })
          : setFilter(null)
      }
    />
  );
}

export function PersonStatusSelectFilter({
  column: { filterValue, setFilter },
}: {
  column: CustomUseFiltersColumnProps;
}) {
  const options = useMemo(async () => GuestIdentityApi.getDistinctStatus(), []);

  return (
    <SelectComponent
      options={options}
      filterValue={filterValue?.value}
      setFilter={(value: string) =>
        value
          ? setFilter({
              value: value,
              type: "exact",
            })
          : setFilter(null)
      }
    />
  );
}

const SelectComponent: React.FC<{
  options: string[] | Promise<string[]>;
  filterValue: string;
  setFilter: (any: any) => void;
}> = ({ options, filterValue, setFilter }) => {
  const [realOptions, setRealOptions] = useState<string[]>([]);
  useEffect(() => {
    const wait = async () => {
      setRealOptions(await options);
    };
    wait();
  });

  return (
    <select
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
    >
      <option value="">All</option>
      {realOptions.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export function DateFilter({
  column: { filterValue, setFilter },
}: {
  column: CustomUseFiltersColumnProps;
}) {
  const parsedToDat =
    filterValue && filterValue.value
      ? moment(filterValue.value, UtcDateFormat).toDate()
      : undefined;

  return (
    <DatePicker
      selected={parsedToDat}
      dateFormat={previewDateFormat}
      onChange={(date: Date) => {
        const dateObject = date && moment(date);
        if (dateObject && dateObject.isValid()) {
          setFilter({ value: dateObject.format(UtcDateFormat), type: "date" });
        } else {
          setFilter(null);
        }
      }}
      placeholderText={"DD/MM/YYYY"}
    />
  );
}
