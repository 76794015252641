import {
  BaseApiClient,
  JSONApiResponse,
  VoidApiResponse,
} from "../../core/api";
import {
  MessageBirdSubscription,
  MessageBirdSubscriptionFromJSON,
} from "../model/messagebird-subscription";
import {
  MessageBirdConversationChannel,
  MessageBirdConversationChannelFromJSON,
} from "../model/messagebird-conversation-channel";

class MessagebirdApiClient extends BaseApiClient {
  webhookUrl = "/api/integration-service/webhook/messagebird";

  async getSubscriptions(): Promise<MessageBirdSubscription[]> {
    const response = await this.fetchApi(`${this.webhookUrl}/subscriptions`);
    return new JSONApiResponse(response, (json) =>
      (json["items"] as any[]).map(MessageBirdSubscriptionFromJSON)
    ).value();
  }

  async getChannels(): Promise<MessageBirdConversationChannel[]> {
    const response = await this.fetchApi(`${this.webhookUrl}/channels`);

    return new JSONApiResponse(response, (jsonValue) =>
      (jsonValue as any[]).map(MessageBirdConversationChannelFromJSON)
    ).value();
  }

  async getEvents(): Promise<string[]> {
    const response = await this.fetchApi(`${this.webhookUrl}/events`);

    return new JSONApiResponse(
      response,
      (jsonValue) => jsonValue as string[]
    ).value();
  }

  async createSubscription(body: { channelId: string; events: string[] }) {
    const response = await this.fetchApi(`${this.webhookUrl}/subscriptions`, {
      method: "POST",
      body: JSON.stringify(body),
    });

    return new VoidApiResponse(response).value();
  }

  async deleteSubscription(id: string): Promise<void> {
    const response = await this.fetchApi(
      `${this.webhookUrl}/subscriptions/${id}`,
      {
        method: "DELETE",
      }
    );
    return new VoidApiResponse(response).value();
  }
}

export const MessageBirdApi = new MessagebirdApiClient();
