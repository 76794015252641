import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

// SVG received from Nemanja
export const ApaleoIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g
      transform="translate(0.000000,543.000000) scale(0.100000,-0.100000)"
      fill="#3A3A3A"
      stroke="none"
    >
      <path
        d="M2625 5314 c-258 -22 -558 -90 -793 -182 l-73 -28 110 -98 c185 -165
386 -406 531 -638 84 -135 223 -406 300 -588 34 -80 63 -146 65 -148 1 -2 22
46 45 105 130 327 318 661 518 920 94 121 279 311 386 397 47 38 86 73 86 77
0 11 -230 85 -358 115 -188 45 -352 64 -567 68 -110 2 -222 2 -250 0z"
      />
      <path
        d="M3995 5011 c-105 -64 -336 -272 -482 -433 -234 -257 -406 -495 -513
-712 -54 -109 -99 -246 -81 -246 3 0 53 20 109 45 183 80 451 153 852 231 222
43 241 48 345 99 208 101 354 134 718 161 42 3 77 9 77 12 0 4 -27 43 -60 86
-218 289 -492 531 -801 708 -120 70 -127 72 -164 49z"
      />
      <path
        d="M1425 4929 c-286 -176 -532 -399 -741 -671 -86 -113 -96 -100 86
-118 219 -22 365 -62 542 -151 87 -43 116 -52 290 -84 458 -87 745 -165 952
-259 l59 -27 -7 41 c-23 142 -203 450 -423 725 -180 225 -581 605 -637 605
-12 0 -67 -28 -121 -61z"
      />
      <path
        d="M498 3973 c-25 -28 -121 -228 -171 -353 -46 -116 -133 -417 -124
-426 3 -3 34 3 69 14 189 59 404 86 683 86 l231 1 31 126 c17 69 56 191 87
270 64 167 69 152 -58 197 -174 63 -411 102 -626 102 -89 0 -109 -3 -122 -17z"
      />
      <path
        d="M4690 3979 c-169 -20 -403 -79 -492 -125 l-27 -14 30 -83 c72 -192
76 -206 133 -407 l15 -55 233 -1 c320 -1 503 -28 751 -110 60 -20 111 -33 114
-30 10 10 -47 234 -94 371 -50 144 -179 421 -208 445 -14 12 -55 15 -199 17
-100 1 -215 -3 -256 -8z"
      />
      <path
        d="M895 3125 c-274 -32 -377 -50 -470 -81 -95 -31 -239 -99 -257 -121
-10 -12 -13 -77 -13 -282 1 -227 4 -285 23 -396 78 -458 249 -855 516 -1203
69 -90 212 -254 225 -259 3 -2 21 45 39 103 108 347 324 677 601 919 50 43 91
84 91 91 0 6 -28 80 -61 165 -34 85 -130 359 -213 609 -84 250 -155 461 -159
468 -11 17 -88 14 -322 -13z"
      />
      <path
        d="M4157 2672 c-87 -262 -185 -544 -217 -625 -35 -87 -57 -154 -53 -162
5 -7 50 -51 102 -98 288 -262 464 -537 581 -907 29 -91 57 -169 62 -175 11
-12 163 145 272 280 325 402 516 860 577 1380 6 55 11 182 11 283 1 213 7 197
-117 272 -127 77 -251 126 -405 160 -125 27 -495 70 -604 70 l-51 0 -158 -478z"
      />
      <path
        d="M1640 2923 c0 -77 32 -129 111 -182 67 -45 120 -63 245 -85 95 -16
139 -35 183 -78 32 -31 131 -189 131 -208 0 -6 5 -10 10 -10 6 0 10 2 10 4 0
2 -9 105 -20 227 -13 139 -25 228 -33 235 -7 6 -147 41 -312 78 -165 37 -306
69 -312 71 -9 3 -13 -11 -13 -52z"
      />
      <path
        d="M3566 2904 c-176 -41 -321 -76 -322 -77 -6 -5 -45 -438 -42 -448 2
-6 38 41 81 105 50 75 87 121 108 131 17 9 85 27 152 41 146 30 210 57 276
119 59 55 73 86 69 152 l-3 50 -319 -73z"
      />
      <path
        d="M1533 1583 c-207 -202 -330 -421 -382 -681 -23 -114 -36 -250 -27
-285 12 -48 279 -229 502 -340 126 -63 372 -161 435 -174 30 -6 32 -5 25 18
-15 46 -19 276 -6 364 l12 87 47 -5 c25 -4 100 -14 166 -24 96 -14 187 -17
460 -17 333 0 435 7 629 43 l44 8 6 -46 c3 -25 9 -111 12 -191 5 -128 4 -152
-14 -207 l-20 -63 23 0 c35 0 244 65 375 117 209 82 537 268 574 326 14 21 15
43 6 178 -5 85 -17 185 -25 224 -50 231 -168 452 -334 622 -78 80 -159 148
-167 140 -3 -3 6 -41 20 -84 54 -163 67 -277 46 -413 -14 -86 -62 -255 -103
-360 l-33 -85 -49 -12 c-132 -34 -330 -55 -455 -50 -177 8 -449 58 -489 90
-36 30 15 283 71 351 41 50 79 67 258 115 186 50 229 73 270 144 28 49 40 120
23 130 -8 5 -32 -3 -223 -71 -151 -54 -225 -65 -435 -65 -264 0 -397 25 -584
110 -41 18 -81 31 -88 29 -20 -8 -16 -74 8 -124 27 -54 85 -97 158 -118 144
-40 338 -107 355 -122 46 -42 92 -173 102 -291 5 -62 4 -79 -9 -89 -20 -17
-216 -58 -373 -78 -90 -11 -163 -14 -239 -10 -144 8 -366 50 -382 71 -21 30
-112 302 -127 385 -30 159 -17 322 37 473 23 62 24 77 7 77 -3 0 -51 -44 -107
-97z"
      />
    </g>
  </SvgIcon>
);
