import React, { useEffect } from "react";
import { useMessagesPage } from "../messagebird/hooks/use-messages-page";
import { LoadingShadow } from "../components/loading-shadow";
import { MessagesTable } from "../messagebird/components/messages-table";
import { Loading } from "../components/loading";

export const MessagesPage: React.FC = () => {
  const {
    data,
    fetchData,
    loading,
    sortingRule,
    handleChangePage,
    handleFilterChange,
    handleSortChange,
    handleChangeRowsPerPage,
    searchParams,
  } = useMessagesPage();

  useEffect(() => {
    fetchData(
      searchParams.page,
      searchParams.rowsPerPage,
      sortingRule,
      searchParams.filterParams
    );
  }, [fetchData, searchParams, sortingRule]);

  if (!data) {
    return <Loading />;
  }
  return (
    <LoadingShadow loading={loading}>
      <MessagesTable
        data={data}
        handleFilterChange={handleFilterChange}
        handleSortChange={handleSortChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
      />
    </LoadingShadow>
  );
};
