import React from "react";
import ReactJson from "react-json-view";

export const JsonTreeView: React.FC<{
  data: any;
}> = ({ data }) => (
  <ReactJson
    src={data}
    displayObjectSize={false}
    displayDataTypes={false}
    collapsed={1}
  />
);
