export interface Message {
  messageId: string;
  reservationId: string;
  bookingId: string;
  timestamp: Date | null;
  messageCreatedTimestamp: Date | null;
  createdAt: Date | null;
  updatedAt: Date | null;
  message: Object;
  template: string;
  contact: string;
  latestStatus: string;
  latestChangeTimestamp: Date | null;
  platform: string;
  to?: string;
}

export function MessageFromJSON(json: any): Message {
  if (!json) {
    return json;
  }

  return {
    messageId: json["messageId"],
    reservationId: json["reservationId"],
    bookingId: json["bookingId"],
    timestamp: parseDate(json["timestamp"]),
    messageCreatedTimestamp: parseDate(json["messageCreatedTimestamp"]),
    createdAt: parseDate(json["createdAt"]),
    updatedAt: parseDate(json["updatedAt"]),
    message: json["message"],
    template: json["template"],
    contact: json["contact"],
    latestStatus: json["latestStatus"],
    latestChangeTimestamp: parseDate(json["latestChangeTimestamp"]),
    platform: json["platform"],
  };
}

const parseDate = (dateString: string) =>
  dateString ? new Date(dateString) : null;
