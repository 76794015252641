import {
  BaseIdentity,
  BaseIdentityFieldsFromJSON,
  BaseIdentityFromJSON,
} from "./base-identity";

export interface GuestIdentityAcknowlegdment {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  phone: boolean;
  title: boolean;
  gender: boolean;
  birthdate: boolean;
  city: boolean;
  postalCode: boolean;
  countryCode: boolean;
  addressLine1: boolean;
  addressLine2: boolean;
  preferredLanguage: boolean;
  identificationType: boolean;
  identificationNumber: boolean;
  nationalityCountryCode: boolean;
}

export interface GuestIdentityFieldOptions {
  firstName: string[];
  lastName: string[];
  email: string[];
  phone: string[];
  title: string[];
  gender: string[];
  birthdate: string[];
  city: string[];
  postalCode: string[];
  countryCode: string[];
  addressLine1: string[];
  addressLine2: string[];
  preferredLanguage: string[];
  identificationType: string[];
  identificationNumber: string[];
  nationalityCountryCode: string[];
}

export interface GuestIdentity extends BaseIdentity {
  guestIdentityAcknowlegdment: GuestIdentityAcknowlegdment;
}

export interface GuestIdentityWithPersonCount extends BaseIdentity {
  persons: number;
}

export function GuestIdentityAcknowledgmentFromJSON(
  json: any
): GuestIdentityAcknowlegdment {
  if (!json) {
    return json;
  }

  return {
    ...BaseIdentityFromJSON(json),
  };
}

export function GuestIdentityFromJSON(json: any): GuestIdentity {
  if (!json) {
    return json;
  }

  return {
    ...BaseIdentityFromJSON(json),
    guestIdentityAcknowlegdment: GuestIdentityAcknowledgmentFromJSON(
      json["guestIdentityAcknowledgment"]
    ),
  };
}

export function GuestIdentityWithPersonCountFromJSON(
  json: any
): GuestIdentityWithPersonCount {
  if (!json) {
    return json;
  }

  return {
    ...BaseIdentityFromJSON(json),
    persons: json["persons"],
  };
}

export function GuestIdentityFieldOptionsFromJSON(json: any): any {
  if (!json) {
    return json;
  }

  return {
    ...BaseIdentityFieldsFromJSON(json),
  };
}
