import { Column } from "react-table";
import { Person } from "../model/person";
import {
  IdentityAccessor,
  DateAccessor,
} from "../../components/table/table-accessors";
import {
  SortingHeader,
  LabelHeader,
} from "../../components/table/table-headers";
import { PersonStatusSelectFilter } from "../../components/table/table-filters";

export const personsTableColumns: Column<Person>[] = [
  { Header: LabelHeader(""), id: "expander", disableFilters: true },
  {
    Header: SortingHeader("Last Updated"),
    accessor: DateAccessor("updatedAt"),
    id: "updatedAt",
    disableFilters: true,
  },
  {
    Header: LabelHeader("First Name"),
    accessor: IdentityAccessor("firstName"),
    id: "firstName",
    disableFilters: true,
  },
  {
    Header: LabelHeader("Last Name"),
    accessor: IdentityAccessor("lastName"),
    id: "lastName",
    disableFilters: true,
  },
  {
    Header: LabelHeader("Email"),
    accessor: IdentityAccessor("email"),
    id: "email",
    disableFilters: true,
  },
  {
    Header: LabelHeader("Phone"),
    accessor: IdentityAccessor("phone"),
    id: "phone",
    disableFilters: true,
  },
  {
    Header: LabelHeader("City"),
    accessor: IdentityAccessor("city"),
    id: "city",
    disableFilters: true,
  },
  {
    Header: LabelHeader("Address Line 1"),
    accessor: IdentityAccessor("addressLine1"),
    id: "addressLine1",
    disableFilters: true,
  },
];

export const personsWithStatusTableColumns: Column<Person>[] = [
  ...personsTableColumns,
  {
    Header: LabelHeader("Status"),
    accessor: IdentityAccessor("status"),
    id: "status",
    Filter: PersonStatusSelectFilter,
  },
];
