import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const AproveIdIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M19.5 9H21.417C21.9031 9 22.3694 9.19312 22.7131 9.53687C23.0569 9.88063 23.25 10.3469 23.25 10.833V21.417C23.25 21.9031 23.0569 22.3694 22.7131 22.7131C22.3694 23.0569 21.9031 23.25 21.417 23.25H16.833C16.3469 23.25 15.8806 23.0569 15.5369 22.7131C15.1931 22.3694 15 21.9031 15 21.417V18"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.993 17.2609V14.9839L11.757 15.1119C12.0831 15.1662 12.4172 15.1489 12.7359 15.061C13.0546 14.9731 13.3504 14.8169 13.6026 14.6031C13.8548 14.3894 14.0574 14.1232 14.1963 13.8232C14.3352 13.5232 14.4071 13.1965 14.407 12.8659V10.9999L15.117 10.8229C15.2939 10.7785 15.4576 10.6923 15.5942 10.5715C15.7309 10.4507 15.8365 10.2989 15.9023 10.1288C15.9681 9.95865 15.992 9.77522 15.9721 9.59392C15.9522 9.41261 15.8891 9.23874 15.788 9.08692L14.407 7.01692V6.44892C14.407 3.09092 11.007 0.410917 6.958 0.784917C5.51368 0.915203 4.14843 1.50222 3.06027 2.46081C1.9721 3.41941 1.21761 4.69974 0.906235 6.11609C0.594858 7.53245 0.742786 9.01117 1.32856 10.3378C1.91434 11.6644 2.9075 12.7699 4.164 13.4939V17.2609"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 15.375L18.375 18L17.25 16.5"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
