import React from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import makeStyles from "@mui/styles/makeStyles";
import { useAuth } from "@likemagic-tech/sv-magic-library";
import { MainListItems } from "./main-list-items";
import { NavbarReservationSearch } from "../components/navbar-reservation-search";
import Logo from "../components/logo/Logo";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  spaceBetween: {
    flexGrow: 1,
  },
  logo: {
    pointerEvents: "none",
    height: theme.spacing(4),
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    width: "100%",
    display: "block",
    boxSizing: "border-box",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(2),
    minHeight: `calc(100% - ${theme.spacing(8)})`,
    height: "1px",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
}));

export const Layout: React.FC<any> = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { initialized, authenticated, hasAdminOrOperatorRole, logout, login } =
    useAuth();
  const isAuthorized = initialized && authenticated && hasAdminOrOperatorRole();

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={`${classes.appBar} ${open && classes.appBarShift}`}
      >
        <Toolbar className={classes.toolbar}>
          {isAuthorized && (
            <IconButton
              edge="start"
              sx={{ color: "white" }}
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={`${classes.menuButton} ${
                open && classes.menuButtonHidden
              }`}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box maxHeight={60}>
            <Logo />
          </Box>
          <div className={classes.spaceBetween} />
          {!authenticated && (
            <Button sx={{ color: "white" }} onClick={async () => await login()}>
              Login
            </Button>
          )}
          {authenticated && (
            <>
              {isAuthorized && <NavbarReservationSearch />}
              <Button sx={{ color: "white" }} onClick={() => logout()}>
                Logout
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      {isAuthorized && (
        <Drawer
          variant="permanent"
          classes={{
            paper: `${classes.drawerPaper} ${
              !open && classes.drawerPaperClose
            }`,
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose} size="large">
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <MainListItems />
          </List>
        </Drawer>
      )}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.container}>{children}</div>
      </main>
    </div>
  );
};
