import React from "react";
import { useWebhooksPage } from "../hooks/use-webhooks-page";
import {
  SubscriptionListAccessors,
  WebhooksPage,
} from "../../components/webhook-subscriptions";
import { ApaleoSubscription } from "../interface/apaleo-subscription";
import { ApaleoApi } from "../api/apaleo.api";
import { ApaleoTopic } from "../interface/apaleo-topic";
import { ApaleoWebhookSubscriptionForm } from "../components/apaleo-webhook-subscription-form";

const subscriptionListAccessors: SubscriptionListAccessors<ApaleoSubscription> =
  {
    id: (subscription) => subscription.id,
    endpointUrl: (subscription) => subscription.endpointUrl,
    subject: (subscription) => subscription.propertyIds?.join(", "),
    events: (subscription) =>
      subscription.events
        ? "Events: " + subscription.events?.join(", ")
        : " Topics: " + subscription.topics?.join(", "),
  };

export const ApaleoWebhooksPage: React.FC = () => {
  const { subscriptions, fetchSubscriptions, removeSubscription, loading } =
    useWebhooksPage();

  return (
    <WebhooksPage
      subscriptions={subscriptions}
      loading={loading}
      fetchSubscriptions={fetchSubscriptions}
      removeSubscription={removeSubscription}
      subscriptionListAccessors={subscriptionListAccessors}
      fetchSubjects={ApaleoApi.getProperties.bind(ApaleoApi)}
      fetchEvents={ApaleoApi.getTopics.bind(ApaleoApi)}
      createSubscription={({ subjectId, events }) =>
        ApaleoApi.createSubscription({
          propertyId: subjectId,
          topics: events as unknown as ApaleoTopic[],
        })
      }
      formComp={ApaleoWebhookSubscriptionForm}
    />
  );
};
