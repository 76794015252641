import React, { useCallback, useEffect, useRef } from "react";
import { MagicEvent } from "../model/magic-event";
import { useSortBy, useTable } from "react-table";
import {
  Box,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { magicEventTableColumns } from "../config/magic-event-table-config";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Page } from "../../core/page";
import makeStyles from "@mui/styles/makeStyles";
import { JsonTreeView } from "../../components/json-tree-view";
import { useNavigate } from "react-router-dom";
import { ExpandingRow } from "../../components/table/expanding-row";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      marginLeft: theme.spacing(1),
    },
    inputRoot: {
      color: "inherit",
      border: "1px solid #0000003b",
      "border-radius": "5px",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 1),
      width: "100%",
    },
  })
);

interface MagicEventsTableProps {
  data: Page<MagicEvent>;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  handleFilterChange: (event: {
    objectId?: string;
    reservationId?: string;
  }) => void;
  handleSortChange: (sort: { id: string; desc?: boolean }[]) => void;
}

function getReservationId(magicEvent: MagicEvent): string {
  if (magicEvent.origin === "APALEO") {
    return magicEvent.objectId;
  }
  if (magicEvent.origin === "MESSAGEBIRD") {
    if (!magicEvent.payload?.source?.reservationId) {
      console.log(
        "Reservation id has not been found for magic event with objectId = ".concat(
          magicEvent.objectId
        )
      );
    }
    return magicEvent.payload?.source?.reservationId;
  }
  return "";
}

export const MagicEventsTable: React.FC<MagicEventsTableProps> = ({
  data,
  handleChangePage,
  handleChangeRowsPerPage,
  handleFilterChange,
  handleSortChange,
}) => {
  const didMountRef = useRef(false);

  const classes = useStyles();
  const navigate = useNavigate();

  const handleObjectIdChange = useCallback(
    (e: any) => {
      handleFilterChange({ objectId: e.target.value });
    },
    [handleFilterChange]
  );

  const handleReservationIdChange = useCallback(
    (e: any) => {
      handleFilterChange({ reservationId: e.target.value });
    },
    [handleFilterChange]
  );

  const handleChangeRowsPerPageAdapter = useCallback(
    (event: any) => handleChangeRowsPerPage(parseInt(event.target.value, 10)),
    [handleChangeRowsPerPage]
  );
  const handleChangePageAdapter = useCallback(
    (event: any, newPage: number) => handleChangePage(newPage),
    [handleChangePage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns: magicEventTableColumns,
      data: data?.content,
      manualSortBy: true,
      initialState: {
        sortBy: [
          {
            id: "timestamp",
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );

  useEffect(() => {
    if (didMountRef.current) handleSortChange(sortBy);
    else didMountRef.current = true;
  }, [sortBy, handleSortChange]);

  interface PrepareIconProps {
    openLink: () => void;
    icon: any;
    tooltip: string;
  }

  const prepareIconsData = useCallback(
    (event: MagicEvent): any => {
      const arrayOfClickableIcons: Array<PrepareIconProps> = [];

      arrayOfClickableIcons.push({
        openLink: () => {
          navigate(`/reservations/${getReservationId(event)}`);
        },
        icon: <OpenInNewIcon />,
        tooltip: "Open Reservation",
      });

      return arrayOfClickableIcons;
    },
    [navigate]
  );

  return (
    <Paper>
      <Toolbar>
        <FilterListIcon />
        <div className={classes.search}>
          <InputBase
            placeholder="Object Id…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={handleObjectIdChange}
          />
        </div>
        <div className={classes.search}>
          <InputBase
            placeholder="Reservation Id…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={handleReservationIdChange}
          />
        </div>
      </Toolbar>
      <TableContainer>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    {column.render("Header") as any}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <ExpandingRow
                  {...row.getRowProps()}
                  key={"" + row.getRowProps().key + row.original.id}
                  renderSummary={() => {
                    const tail = row.cells.slice(1);
                    return tail.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          <Typography noWrap>
                            <>{cell.render("Cell")}</>
                          </Typography>
                        </TableCell>
                      );
                    });
                  }}
                  renderDetails={() => (
                    <Box p={2}>
                      {row.original.message && (
                        <>
                          <Typography>Message:</Typography>
                          <JsonTreeView data={row.original.message} />
                        </>
                      )}
                      {row.original.payload && (
                        <>
                          <Typography>Payload:</Typography>
                          <JsonTreeView data={row.original.payload} />
                        </>
                      )}
                    </Box>
                  )}
                  icons={prepareIconsData(row.original)}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.totalElements}
        rowsPerPage={data.numberOfElements}
        page={data.number}
        onPageChange={handleChangePageAdapter}
        onRowsPerPageChange={handleChangeRowsPerPageAdapter}
      />
    </Paper>
  );
};
