import { useCallback, useState } from "react";
import { ReservationApi } from "../api/reservation.api";
import { Reservation } from "../model/reservation";
import { Page } from "../../core/page";
import { SortingRule } from "react-table";
import { FilterObject } from "../../api/search-criteria-parsing";

interface UseReservationsPage {
  fetchData: (
    pageNumber: number,
    pageSize: number,
    newSorting: SortingRule<Reservation>[],
    filterParams: FilterObject[],
    freeTextSearch?: string
  ) => Promise<void>;
  data: Page<Reservation> | undefined;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  loading: boolean;
  sortingRule: SortingRule<Reservation>[];
  handleChangePage: (newPage: number) => void;
  handleFilterChange: (event: any) => void;
  handleSortChange: (sort: SortingRule<Reservation>[]) => void;
  searchParams: {
    filterParams: FilterObject[];
    page: number;
    rowsPerPage: number;
  };
}

// @ts-ignore
export const useReservationsPage: () => UseReservationsPage = () => {
  const [data, setData] = useState<Page<Reservation>>();
  const [loading, setLoading] = useState(false);
  const defaultSort: SortingRule<Reservation> = { id: "timestamp", desc: true };
  const [sortingRule, setSortingRule] = useState<SortingRule<Reservation>[]>([
    defaultSort,
  ]);

  const [searchParams, setSearchParam] = useState({
    page: 0,
    rowsPerPage: 10,
    filterParams: [],
  });

  const fetchData = useCallback(
    async (
      pageNumber: number,
      pageSize: number,
      sorting: SortingRule<Reservation>[],
      filterArray: Array<FilterObject>,
      freeTextSearch?: string
    ) => {
      setLoading(true);
      try {
        const freeTextSearchFilterParams: Array<FilterObject> = freeTextSearch
          ? freeTextSearch.split(" ").map((string) => {
              return {
                id: "freeTextSearch",
                value: { value: string, type: "lax" },
              };
            })
          : [];
        const response = await ReservationApi.getReservations({
          pageable: { pageNumber, pageSize },
          sortingRules: sorting,
          filterParams: [...filterArray, ...freeTextSearchFilterParams],
        });
        setData(response);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handleChangePage = useCallback((newPage: number) => {
    setSearchParam((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setSearchParam((prevState) => ({
      ...prevState,
      rowsPerPage: newRowsPerPage,
      page: 0,
    }));
  }, []);

  const handleFilterChange = useCallback((event: []) => {
    setSearchParam((prevState) => ({
      ...prevState,
      page: 0,
      filterParams: event,
    }));
  }, []);

  const handleSortChange = useCallback((sort: SortingRule<Reservation>[]) => {
    setSortingRule(sort);
  }, []);

  return {
    data,
    fetchData,
    loading,
    handleChangePage,
    handleFilterChange,
    handleSortChange,
    handleChangeRowsPerPage,
    sortingRule,
    searchParams,
  };
};
