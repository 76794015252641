import React, { useEffect, useState } from "react";
import { Box, InputBase, Paper, Typography } from "@mui/material";
import { Message } from "../model/message";
import { FullscreenConfirm } from "../../components/fullscreen-confirm";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: "70%",
    margin: "8px 0px",
    border: "1px solid #0000003b",
    "border-radius": "5px",
  },
  input: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  info: {
    display: "flex",
  },
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));

interface ResendMessageFullScreenConfirmProps {
  resendMessage: (message: Message, toAddress: string) => void;
  messageToResend: Message | undefined;
  discardMessageToResend: () => void;
  open: boolean;
}

export const ResendMessageFullScreenConfirm: React.FC<
  ResendMessageFullScreenConfirmProps
> = ({ resendMessage, messageToResend, discardMessageToResend, open }) => {
  const classes = useStyles();

  const [toAddress, setToAddress] = useState<string>();
  useEffect(() => {
    setToAddress(messageToResend?.contact);
  }, [messageToResend]);

  return (
    <FullscreenConfirm
      open={open}
      onOk={() =>
        messageToResend &&
        toAddress &&
        resendMessage(messageToResend, toAddress)
      }
      onCancel={discardMessageToResend}
    >
      <Box mb={2} className={classes.container}>
        <Typography>
          The {messageToResend?.template} message will be sent as{" "}
          {messageToResend?.platform?.toUpperCase()} to
        </Typography>
        <Paper component="form" className={classes.inputRoot}>
          <InputBase
            value={toAddress}
            onChange={(event) => setToAddress(event.target.value)}
            className={classes.input}
            disabled
          />
        </Paper>
        <Box mt={2}>
          <Typography variant="h6">Are you sure?</Typography>
        </Box>
      </Box>
    </FullscreenConfirm>
  );
};
