import { BaseApiClient, JSONApiResponse } from "../../core/api";
import { Page, PageFromJSON } from "../../core/page";
import { MagicEvent, MagicEventFromJSON } from "../model/magic-event";
import { SortingRule } from "react-table";

const BASE_URL = "/api/integration-service/magic-events";

interface PageableRequestParams {
  pageNumber: number;
  pageSize: number;
}

interface GetMagicEventsRequestParams {
  pageable?: PageableRequestParams;
  sortingRules: SortingRule<MagicEvent>[];
  reservationId?: string;
  objectId?: string;
}

class MagicEventApiClient extends BaseApiClient {
  async getMagicEvents(
    requestParams?: GetMagicEventsRequestParams
  ): Promise<Page<MagicEvent>> {
    let url = BASE_URL;
    if (requestParams) {
      url += "?" + MagicEventApiClient.parseQueryParams(requestParams);
    }

    const response = await this.fetchApi(url);
    return new JSONApiResponse(
      response,
      PageFromJSON(MagicEventFromJSON)
    ).value();
  }

  private static parseQueryParams(
    requestParams?: GetMagicEventsRequestParams
  ): string {
    if (!requestParams) {
      return "";
    }
    const result = [];
    if (requestParams.objectId) {
      result.push(`object-id=${encodeURIComponent(requestParams.objectId)}`);
    }
    if (requestParams.reservationId) {
      result.push(
        `reservation-id=${encodeURIComponent(requestParams.reservationId)}`
      );
    }
    if (requestParams.pageable?.pageNumber !== undefined) {
      result.push(
        `page=${encodeURIComponent(requestParams.pageable.pageNumber)}`
      );
    }
    if (requestParams.pageable?.pageSize !== undefined) {
      result.push(
        `size=${encodeURIComponent(requestParams.pageable.pageSize)}`
      );
    }
    if (requestParams.sortingRules !== undefined) {
      result.push(
        ...requestParams.sortingRules.map(
          (rule) => `sort=${rule.id},${rule.desc ? "desc" : "asc"}`
        )
      );
    }
    return result.join("&");
  }
}

export const MagicEventApi = new MagicEventApiClient();
