import React from "react";
import { ApaleoTopic } from "../interface/apaleo-topic";
import { ApaleoProperty } from "../interface/apaleo-property";
import {
  WebhookSubscriptionForm,
  WebhookSubscriptionFormAccessors,
  WebhookSubscriptionFormProps,
} from "../../components/webhook-subscriptions";

let accessors: WebhookSubscriptionFormAccessors<ApaleoProperty> = {
  subjectFormLabel: () => "Property",
  subjectId: (subscription) => subscription.id,
  subjectLabel: (subject) => `${subject.name} [${subject.code}]`,
  eventsFormLabel: () => "Topics",
};

export const ApaleoWebhookSubscriptionForm: React.FC<
  WebhookSubscriptionFormProps<ApaleoProperty, ApaleoTopic>
> = ({ subjects, events, onChange }) => {
  return (
    <WebhookSubscriptionForm
      subjects={subjects}
      events={events}
      onChange={onChange}
      accessors={accessors}
    />
  );
};
