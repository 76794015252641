import { MagicFileType } from "./magic-file-type";

export interface MagicFile {
  fileName: string;
  contentType: string;
  metaData: {
    ownerId: string;
    magicFileType: MagicFileType;
  };
}

export function MagicFileFromJSON(json: any): MagicFile {
  if (!json) {
    return json;
  }
  return {
    fileName: json["fileName"],
    contentType: json["contentType"],
    metaData: {
      ownerId: json["metaData"]["ownerId"],
      magicFileType: json["metaData"]["magicFileType"],
    },
  };
}
