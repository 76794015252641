import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const OperationIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      height="24"
      width="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
    >
      <g>
        <path d="M20,6h-8l-2-2H4C2.9,4,2.01,4.9,2.01,6L2,18c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M14,16H6v-2h8V16z M18,12H6v-2h12V12z" />
      </g>
    </svg>
  </SvgIcon>
);
