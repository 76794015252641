import { GuestIdentity } from "./guest-identity";
import { BaseIdentity, BaseIdentityFromJSON } from "./base-identity";
import { ObjectReference, ObjectReferenceFromJSON } from "./object-reference";
import { parseDate } from "../util/date-util";

export enum Status {
  Ready = "READY",
  Invalid = "INVALID",
  Matched = "MATCHED",
  FieldConflict = "FIELD_CONFLICT",
  RecordConflict = "RECORD_CONFLICT",
}

export interface Person extends BaseIdentity {
  timestamp: Date | null;
  guestIdentity: GuestIdentity | null;
  objectReference: ObjectReference;
  status: Status;
}

export function PersonFromJSON(json: any): Person {
  const base = BaseIdentityFromJSON(json);

  if (!base) {
    return base;
  }

  return {
    ...base,
    timestamp: parseDate(json["timestamp"]),
    guestIdentity: BaseIdentityFromJSON(json["guestIdentity"]),
    objectReference: ObjectReferenceFromJSON(json["objectReference"]),
    status: json["status"] as Status,
  };
}
