import React, { useCallback, useState } from "react";
import { Page } from "../../core/page";
import { MagicEvent } from "../model/magic-event";
import { MagicEventApi } from "../api/magic-event.api";
import { useDebounce } from "../../util/use-debounce";
import { SortingRule } from "react-table";

interface UseMagicEventsPage {
  fetchData: (
    pageNumber: number,
    pageSize: number,
    newSorting: SortingRule<MagicEvent>[],
    objectIdFilter: string,
    reservationIdFilter: string
  ) => Promise<void>;
  data: Page<MagicEvent> | undefined;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  debouncedObjectId: string;
  page: number;
  loading: boolean;
  rowsPerPage: number;
  sortingRule: SortingRule<MagicEvent>[];
  handleChangePage: (newPage: number) => void;
  handleFilterChange: (event: {
    objectId?: string;
    reservationId?: string;
  }) => void;
  handleSortChange: (sort: SortingRule<MagicEvent>[]) => void;
  debouncedReservationId: string;
}

export const useMagicEventsPage: () => UseMagicEventsPage = () => {
  const [data, setData] = useState<Page<MagicEvent>>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const [objectId, setObjectId] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [sortingRule, setSortingRule] = useState<SortingRule<MagicEvent>[]>([]);
  const debouncedObjectId = useDebounce(objectId, 300);
  const debouncedReservationId = useDebounce(reservationId, 300);

  const handleChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: any) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }, []);

  const handleFilterChange = useCallback(
    (event: { objectId?: string; reservationId?: string }) => {
      if (event.objectId !== undefined) {
        setObjectId(event.objectId);
      }
      if (event.reservationId !== undefined) {
        setReservationId(event.reservationId);
      }
      setPage(0);
    },
    []
  );

  const handleSortChange = useCallback((sort: SortingRule<MagicEvent>[]) => {
    setSortingRule(sort);
  }, []);

  const fetchData = useCallback(
    async (
      pageNumber: number,
      pageSize: number,
      newSorting: SortingRule<MagicEvent>[],
      objectIdFilter: string,
      reservationIdFilter: string
    ) => {
      try {
        setLoading(true);
        const response = await MagicEventApi.getMagicEvents({
          pageable: { pageNumber, pageSize },
          sortingRules: newSorting,
          reservationId: reservationIdFilter,
          objectId: objectIdFilter,
        });
        setData(response);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    data,
    fetchData,
    loading,
    page,
    rowsPerPage,
    sortingRule,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilterChange,
    handleSortChange,
    debouncedObjectId,
    debouncedReservationId,
  };
};
