import { Folio, FolioFromJSON } from "./folio";
import { MagicFile, MagicFileFromJSON } from "./magic-file";
import { Person, PersonFromJSON } from "../../record-matching/model/person";
import { Unit } from "./unit";
import {
  ReservationExtras,
  ReservationExtrasFromJSON,
} from "./reservation-extras";

export interface AccessibleDoor {
  id: number;
  title: string;
  is_general: boolean;
}

export enum DoorAccessStatus {
  OK = "OK",
  NOT_OK = "NOT_OK",
}

export enum NotificationPlatform {
  SMS = "sms",
  EMAIL = "email",
  WHATSAPP = "whatsapp",
  FACEBOOK_MESSENGER = "facebook",
}

export enum IdCheckStatus {
  UNCONFIRMED = "UNCONFIRMED",
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
}

export interface Reservation {
  id: string;
  magicEventId: number;
  magicLink: string;
  timestamp: Date | null;
  bookingId: string;
  source: string;
  guaranteeType: string;
  propertyId: string;
  status: string;
  siblingReservationCount: number;
  arrival: Date | null;
  departure: Date | null;
  checkInTime: Date | null;
  checkOutTime: Date | null;
  cancellationTime: Date | null;
  comment: string | null;
  guestComment: string | null;
  adults: number;
  childrenAges: Object;
  balance: Object;
  payableAmount: Object;
  paymentAccount: Object;
  primaryGuest: Person;
  additionalGuests: Array<Person>;
  unitCleanOnCheckin: boolean;
  unit: Unit;
  unitGroup: {
    id: string;
    maxPersons: number;
  };
  flowState: {
    id: number;
    travelBuddyId: string;
    completed: boolean;
    notificationSent: boolean;
    notificationPending: boolean;
    context: Object;
  };
  files: MagicFile[];
  folios: Folio[] | null;
  services: Object;
  extras: ReservationExtras | null;
  timeSlices: Object;
  fourSuitesAccessToken: {
    accessToken: string;
    expirationTime: Date | null;
  };
  accessibleDoors: AccessibleDoor[] | null;
  doorAccessesLog: {
    doorId: string;
    status: DoorAccessStatus;
    timestamp: Date | null;
  };
  idCheckStatus: IdCheckStatus;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export function ReservationFromJSON(json: any): Reservation {
  if (!json) {
    return json;
  }

  return {
    id: json["id"],
    magicEventId: json["magicEventId"],
    magicLink: json["magicLink"],
    timestamp: parseDate(json["timestamp"]),
    bookingId: json["bookingId"],
    source: json["source"],
    guaranteeType: json["guaranteeType"],
    propertyId: json["propertyId"],
    status: json["status"],
    siblingReservationCount: json["siblingReservationCount"],
    arrival: parseDate(json["arrival"]),
    departure: parseDate(json["departure"]),
    checkInTime: parseDate(json["checkInTime"]),
    checkOutTime: parseDate(json["checkOutTime"]),
    cancellationTime: parseDate(json["cancellationTime"]),
    comment: json["comment"],
    guestComment: json["guestComment"],
    adults: json["adults"],
    childrenAges: json["childrenAges"],
    balance: json["balance"],
    paymentAccount: json["paymentAccount"],
    payableAmount: json["payableAmount"],
    primaryGuest: json["primaryGuest"],
    additionalGuests: Array.isArray(json["additionalGuests"])
      ? json["additionalGuests"].map(PersonFromJSON)
      : [],
    unitCleanOnCheckin: json["unitCleanOnCheckin"],
    unit: json["unit"],
    unitGroup: json["unitGroup"],
    flowState: json["flowState"],
    files: Array.isArray(json["files"])
      ? json["files"].map(MagicFileFromJSON)
      : [],
    folios: Array.isArray(json["folios"])
      ? json["folios"].map(FolioFromJSON)
      : [],
    services: json["services"],
    extras: ReservationExtrasFromJSON(json["extras"]),
    timeSlices: json["timeSlices"],
    fourSuitesAccessToken: json["fourSuitesAccessToken"],
    accessibleDoors: json["accessibleDoors"],
    doorAccessesLog: json["doorAccessesLog"],
    idCheckStatus: json["idCheckStatus"],
    createdAt: json["createdAt"],
    updatedAt: json["updatedAt"],
  };
}

const parseDate = (dateString: string) =>
  dateString ? new Date(dateString) : null;
