import React, { FC } from "react";
import { Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  tabsText: {
    textTransform: "capitalize",
  },
}));

export const TabLink: FC<{
  value: string;
  onClick: () => void;
  label: string;
}> = ({ value, onClick, label }) => {
  const { tabsText } = useStyles({});

  return (
    <Tab
      className={tabsText}
      value={value}
      onClick={onClick}
      label={label}
    ></Tab>
  );
};
