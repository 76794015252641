interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Page<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: Sort;
    unpaged: boolean;
  };
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;
}

function SortFromJSON(json: any): Sort {
  if (!json) {
    return json;
  }
  return {
    sorted: json["sorted"],
    unsorted: json["unsorted"],
    empty: json["empty"],
  };
}

export function PageFromJSON<T>(
  contentMapper: (contentJson: any) => T
): (json: any) => Page<T> {
  return (json: any) => {
    if (!json) {
      return json;
    }
    return {
      content: json["content"].map(contentMapper),
      empty: json["empty"],
      first: json["first"],
      last: json["last"],
      number: json["number"],
      numberOfElements: json["numberOfElements"],
      pageable: PageableFromJSON(json["pageable"]),
      size: json["size"],
      sort: SortFromJSON(json["sort"]),
      totalElements: json["totalElements"],
      totalPages: json["totalPages"],
    };
  };
}

export function PageableFromJSON(json: any) {
  if (!json) {
    return json;
  }

  return {
    offset: json["offset"],
    pageNumber: json["pageNumber"],
    pageSize: json["pageSize"],
    paged: json["paged"],
    sort: SortFromJSON(json["sort"]),
    unpaged: json["unpaged"],
  };
}
