import React, { useEffect } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GuestIdentitiesTable } from "./guest-identities-table";
import { Loading } from "../../components/loading";
import { usePageableFetch } from "../hooks/use-pageable-fetch";
import { LoadingShadow } from "../../components/loading-shadow";
import { SearchBar } from "./search-bar";
import {
  GuestIdentityWithPersonCountFromJSON,
  GuestIdentityWithPersonCount,
} from "../model/guest-identity";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  searchBar: {
    marginBottom: theme.spacing(2),
  },
}));

export const GuestIdentities: React.FC = () => {
  const classes = useStyles();
  const {
    data,
    loading,
    searchValue,
    handlePageNumberChange,
    handlePageSizeChange,
    handleSortChange,
    fetchData,
    handleSearchChange,
  } = usePageableFetch<GuestIdentityWithPersonCount>(
    "guest-identities",
    GuestIdentityWithPersonCountFromJSON
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!data) {
    return <Loading />;
  }

  return (
    <Box>
      <SearchBar
        className={classes.searchBar}
        value={searchValue}
        onChange={handleSearchChange}
        placeholder="Find Guest Identities..."
      />
      <LoadingShadow loading={loading}>
        <GuestIdentitiesTable
          data={data}
          onPageNumberChange={handlePageNumberChange}
          onPageSizeChange={handlePageSizeChange}
          onSortChange={handleSortChange}
        />
      </LoadingShadow>
    </Box>
  );
};
