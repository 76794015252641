import { useCallback, useState } from "react";
import { ApaleoApi } from "../api/apaleo.api";
import { ApaleoSubscription } from "../interface/apaleo-subscription";

export interface UseWebhooksPage {
  subscriptions: any[];
  fetchSubscriptions: () => Promise<void>;
  removeSubscription: (id: string) => Promise<void>;
  loading: boolean;
}

export const useWebhooksPage: () => UseWebhooksPage = () => {
  const [subscriptions, setSubscriptions] = useState<ApaleoSubscription[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchSubscriptions = useCallback(async () => {
    try {
      setLoading(true);
      const subs = await ApaleoApi.getSubscriptions();
      setSubscriptions(subs);
    } catch (e) {
      setSubscriptions([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const removeSubscription = useCallback(
    async (id: string) => {
      const result = await ApaleoApi.deleteSubscription(id);
      fetchSubscriptions();
      return result;
    },
    [fetchSubscriptions]
  );

  return {
    subscriptions,
    fetchSubscriptions,
    removeSubscription,
    loading,
  };
};
