import {
  LabelHeader,
  SortingHeader,
} from "../../components/table/table-headers";
import { Column } from "react-table";
import {
  DateAccessor,
  IdentityAccessor,
} from "../../components/table/table-accessors";
import {
  DateFilter,
  MessagePlatformSelectFilter,
  MessageStatusSelectFilter,
  MessageTemplateTypeSelectFilter,
} from "../../components/table/table-filters";
import { Message } from "../model/message";

export const messagesTableColumns: Column<Message>[] = [
  { Header: LabelHeader("Actions"), id: "actions" },

  // Message ID
  {
    Header: SortingHeader("Message ID"),
    accessor: IdentityAccessor("messageId"),
    id: "messageId",
  },
  // Reservation ID
  {
    Header: SortingHeader("Reservation ID"),
    accessor: IdentityAccessor("reservationId"),
    id: "reservationId",
  },
  // message created timestamp
  {
    Header: SortingHeader("Message created"),
    accessor: DateAccessor("messageCreatedTimestamp"),
    id: "messageCreatedTimestamp",
    Filter: DateFilter,
  },
  // Template type
  {
    Header: SortingHeader("Type"),
    accessor: IdentityAccessor("template"),
    id: "template",
    Filter: MessageTemplateTypeSelectFilter,
  },
  // Platform
  {
    Header: SortingHeader("Platform"),
    accessor: IdentityAccessor("platform"),
    id: "platform",
    Filter: MessagePlatformSelectFilter,
  },
  // Contact
  {
    Header: SortingHeader("Contact"),
    accessor: IdentityAccessor("contact"),
    id: "contact",
  },
  // Latest status
  {
    Header: SortingHeader("Latest status"),
    accessor: IdentityAccessor("latestStatus"),
    id: "latestStatus",
    Filter: MessageStatusSelectFilter,
  },
  // Timestamp latest change
  {
    Header: SortingHeader("Latest status change"),
    accessor: DateAccessor("latestChangeTimestamp"),
    id: "latestChangeTimestamp",
    Filter: DateFilter,
  },
];
