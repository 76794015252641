import { ApaleoAddress, ApaleoAddressFromJSON } from "./apaleo-address";
import {
  ApaleoBankAccount,
  ApaleoBankAccountModelFromJSON,
} from "./apaleo-bank-account";

export interface ApaleoProperty {
  id: string;
  code: string;
  propertyTemplateId?: string;
  isTemplate: boolean;
  name: string;
  description?: string;
  companyName: string;
  managingDirectors?: string;
  commercialRegisterEntry: string;
  taxId: string;
  location: ApaleoAddress;
  bankAccount?: ApaleoBankAccount;
  paymentTerms: { [key: string]: string };
  timeZone: string;
  currencyCode: string;
  created: Date;
}

export const ApaleoPropertyFromJSON = (json: any): ApaleoProperty => {
  if (!json) {
    return json;
  }

  return {
    id: json["id"],
    code: json["code"],
    propertyTemplateId: json["propertyTemplateId"],
    isTemplate: json["isTemplate"],
    name: json["name"],
    description: json["description"],
    companyName: json["companyName"],
    managingDirectors: json["managingDirectors"],
    commercialRegisterEntry: json["commercialRegisterEntry"],
    taxId: json["taxId"],
    location: ApaleoAddressFromJSON(json["location"]),
    bankAccount: ApaleoBankAccountModelFromJSON(json["bankAccount"]),
    paymentTerms: json["paymentTerms"],
    timeZone: json["timeZone"],
    currencyCode: json["currencyCode"],
    created: new Date(json["created"]),
  };
};
