import { useCallback, useState } from "react";
import { MessageBirdApi } from "../api/messagebird.api";

interface UseMessagebirdWebhooksPage {
  subscriptions: any[];
  loading: boolean;
  fetchSubscriptions: () => Promise<void>;
  removeSubscription: (id: string) => Promise<void>;
}

export const useMessageBirdWebhooksPage = (): UseMessagebirdWebhooksPage => {
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchSubscriptions = useCallback(async () => {
    try {
      setLoading(true);
      const subs = await MessageBirdApi.getSubscriptions();
      setSubscriptions(subs);
    } catch (e) {
      setSubscriptions([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const removeSubscription = useCallback(
    async (id: string) => {
      const result = await MessageBirdApi.deleteSubscription(id);
      fetchSubscriptions();
      return result;
    },
    [fetchSubscriptions]
  );

  return {
    subscriptions,
    fetchSubscriptions,
    loading,
    removeSubscription,
  };
};
