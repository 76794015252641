import React, { useCallback, useEffect, useRef } from "react";
import { useSortBy, useTable } from "react-table";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GuestIdentityWithPersonCount } from "../model/guest-identity";
import { Page } from "../../core/page";
import { guestIdentitiesTableColumns } from "../config/guest-identity-table-config";

interface GuestIdentitiesTableProps {
  data: Page<GuestIdentityWithPersonCount>;
  onPageNumberChange: (newPage: number) => void;
  onPageSizeChange: (rowsPerPage: number) => void;
  onSortChange: (sort: { id: string; desc?: boolean }[]) => void;
}

const useStyles = makeStyles(() => ({
  tableRow: {
    cursor: "pointer",
  },
}));

export const GuestIdentitiesTable: React.FC<GuestIdentitiesTableProps> = ({
  data,
  onPageNumberChange,
  onPageSizeChange,
  onSortChange,
}) => {
  const classes = useStyles();
  const didMountRef = useRef(false);
  const navigation = useNavigate();

  const handlePageSizeChange = useCallback(
    (event: any) => onPageSizeChange(parseInt(event.target.value, 10)),
    [onPageSizeChange]
  );

  const handlePageNumberChange = useCallback(
    (_: any, newPage: number) => onPageNumberChange(newPage),
    [onPageNumberChange]
  );

  const handleGuestIdentitySelected = useCallback(
    (id: number) => {
      navigation(`/record-matching/guest-identities/${id}`);
    },
    [navigation]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns: guestIdentitiesTableColumns,
      data: data?.content,
      manualSortBy: true,
      initialState: {
        sortBy: [
          {
            id: "updatedAt",
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );

  useEffect(() => {
    if (didMountRef.current) onSortChange(sortBy);
    else didMountRef.current = true;
  }, [sortBy, onSortChange]);

  return (
    <Paper>
      <TableContainer>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    <>{column.render("Header")}</>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  key={"tr_" + row.original.id}
                  hover
                  className={classes.tableRow}
                  onClick={() => handleGuestIdentitySelected(row.original.id)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        <>{cell.render("Cell")}</>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.totalElements}
        rowsPerPage={data.size}
        page={data.number}
        onPageChange={handlePageNumberChange}
        onRowsPerPageChange={handlePageSizeChange}
      />
    </Paper>
  );
};
