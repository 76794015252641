import {
  BaseApiClient,
  JSONApiResponse,
  VoidApiResponse,
} from "../../core/api";
import {
  ApaleoSubscription,
  ApaleoSubscriptionFromJSON,
} from "../interface/apaleo-subscription";
import { ApaleoTopic } from "../interface/apaleo-topic";
import {
  ApaleoProperty,
  ApaleoPropertyFromJSON,
} from "../interface/apaleo-property";

export interface CreateSubscriptionRequest {
  propertyId: string;
  topics: ApaleoTopic[];
}

class ApaleoApiClient extends BaseApiClient {
  async getSubscriptions(): Promise<ApaleoSubscription[]> {
    const response = await this.fetchApi(
      "/api/integration-service/webhook/apaleo/subscriptions"
    );
    return new JSONApiResponse(response, (json) =>
      (json as any[]).map(ApaleoSubscriptionFromJSON)
    ).value();
  }

  async createSubscription({
    propertyId,
    topics,
  }: CreateSubscriptionRequest): Promise<void> {
    const response = await this.fetchApi(
      `/api/integration-service/webhook/apaleo/subscriptions/${propertyId}`,
      {
        method: "POST",
        body: JSON.stringify({ topics }),
      }
    );

    return new VoidApiResponse(response).value();
  }

  async deleteSubscription(id: string): Promise<void> {
    const response = await this.fetchApi(
      `/api/integration-service/webhook/apaleo/subscriptions/${id}`,
      {
        method: "DELETE",
      }
    );
    return new VoidApiResponse(response).value();
  }

  async getProperties(): Promise<ApaleoProperty[]> {
    const response = await this.fetchApi(
      `/api/integration-service/webhook/apaleo/properties`
    );

    return new JSONApiResponse(response, (jsonValue) =>
      (jsonValue["properties"] as any[]).map(ApaleoPropertyFromJSON)
    ).value();
  }

  async getTopics(): Promise<string[]> {
    const response = await this.fetchApi(
      `/api/integration-service/webhook/apaleo/topics`
    );

    return new JSONApiResponse<string[]>(response).value();
  }
}

export const ApaleoApi = new ApaleoApiClient();
