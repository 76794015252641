import { FC } from "react";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import InfoIcon from "@mui/icons-material/Info";
import HomeIcon from "@mui/icons-material/Home";
import { ListItemLink } from "./list-item-link";
import { OpenApiIcon } from "../components/open-api-icon";
import HistoryIcon from "@mui/icons-material/History";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Mail, People } from "@mui/icons-material";
import { OperationIcon } from "../components/operation-icon";

export const MainListItems: FC = () => {
  return (
    <>
      <ListItemLink primary="Home" icon={<HomeIcon />} to="/" />
      <ListItemLink primary="Status" icon={<InfoIcon />} to="/status" />
      <ListItemLink
        primary="Magic Events"
        icon={<HistoryIcon />}
        to="/magic-events"
      />
      <ListItemLink
        primary="Reservations"
        icon={<DateRangeIcon />}
        to="/reservations"
      />
      <ListItemLink
        primary="Operation"
        icon={<OperationIcon />}
        to="/operation"
      />
      <ListItemLink
        primary="Record Matching"
        icon={<People />}
        to="/record-matching"
      />
      <ListItemLink primary="Messages" icon={<Mail />} to="/messages" />
      <ListItemLink
        primary="Webhooks"
        icon={<DeviceHubIcon />}
        to="/webhooks"
      />
      <ListItemLink
        primary="Open API"
        icon={<OpenApiIcon />}
        to="/api/swagger"
        external
      />
    </>
  );
};
