import React from "react";
import { Route, Routes } from "react-router-dom";

import "./App.css";
import { HomePage } from "./pages/home.page";
import { ApplicationProviders } from "./config/app-providers";
import { Layout } from "./layout/layout";
import { StatusPage } from "./pages/status.page";
import { WebhooksPage } from "./pages/webhooks.page";
import { MagicEventsPage } from "./pages/magic-events.page";
import { ReservationsPage } from "./pages/reservations.page";
import { MessagesPage } from "./pages/messages.page";
import { ReservationsPreviewPage } from "./pages/reservation-preview";
import { RecordMatchingPage } from "./pages/record-matching.page";
import { GuestIdentityDetailsPage } from "./pages/guest-identity-details.page";
import { ConflictResolutionPage } from "./pages/conflict-resolution.page";
import { OperationPage } from "./pages/operation.page";
import { Manifest } from "./components/manifest";

const App = () => (
  <ApplicationProviders>
    <Manifest />
    <Layout>
      <Routes>
        <Route path="/status" element={<StatusPage />} />
        <Route path="/webhooks" element={<WebhooksPage />} />
        <Route path="/magic-events" element={<MagicEventsPage />} />
        <Route path="/reservations" element={<ReservationsPage />}></Route>
        <Route path="/messages" element={<MessagesPage />} />
        <Route
          path="/reservations/:reservationId"
          element={<ReservationsPreviewPage />}
        />
        <Route
          path="/record-matching/guest-identities/:guestIdentityId"
          element={<GuestIdentityDetailsPage />}
        ></Route>
        <Route
          path="/record-matching/conflict-resolution/:personId"
          element={<ConflictResolutionPage />}
        />
        <Route path="/record-matching" element={<RecordMatchingPage />} />
        <Route path="/operation" element={<OperationPage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Layout>
  </ApplicationProviders>
);

export default App;
