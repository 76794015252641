import React, { PropsWithChildren } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Fade } from "@mui/material";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
});
interface FullscreenDialogProps {
  open: boolean;
}
export const FullscreenDialog: React.FC<
  PropsWithChildren<FullscreenDialogProps>
> = ({ open, children }) => {
  const classes = useStyles();
  return (
    <Fade in={open}>
      <div className={classes.root}>{children}</div>
    </Fade>
  );
};
