import { ApaleoTopic } from "./apaleo-topic";

type PropertyId = string;

export interface ApaleoSubscription {
  id: string;
  endpoint?: string;
  endpointUrl: string;
  propertyId?: PropertyId;
  propertyIds: PropertyId[];
  events: string[];
  // Note: will be after all webhooks are migrated (LM-501)
  topic?: ApaleoTopic;
  // Note: will be after all webhooks are migrated (LM-501)
  topics: ApaleoTopic[];
  created: Date;
}

export const ApaleoSubscriptionFromJSON = (json: any): ApaleoSubscription => {
  if (!json) {
    return json;
  }

  return {
    id: json["id"],
    endpoint: json["endpoint"],
    endpointUrl: json["endpointUrl"],
    propertyId: json["propertyId"],
    propertyIds: json["propertyIds"],
    events: json["events"],
    topic: json["topic"],
    topics: json["topics"],
    created: new Date(json["created"]),
  };
};
