import React, { FC, useEffect } from "react";

export const AppDetailsContent = {
  DESCRIPTION: "LIKE MAGIC - Monitoring",
  NAME: "LIKE MAGIC - Monitoring",
  SHORT_NAME: "LIKE MAGIC - Monitoring",
  ICONS: [
    {
      src: `${window.location.origin}/likemagic/favicon.png`,
      sizes: "64x64 32x32 24x24 16x16",
      type: "image/x-icon",
    },
    {
      src: `${window.location.origin}/likemagic/logo192.png`,
      type: "image/png",
      sizes: "192x192",
    },
    {
      src: `${window.location.origin}/likemagic/logo512.png`,
      type: "image/png",
      sizes: "512x512",
    },
    {
      src: `${window.location.origin}/likemagic/maskable_icon.png`,
      type: "image/png",
      sizes: "192x192",
      purpose: "maskable",
    },
  ],
};

export const MetaUpdate: FC<React.PropsWithChildren<unknown>> = () => {
  useEffect(() => {
    const ogTitle = document.createElement("meta");
    const ogImage = document.createElement("meta");
    const ogDescription = document.createElement("meta");
    const description = document.createElement("meta");
    const appName = document.createElement("meta");

    if (AppDetailsContent?.NAME) {
      ogTitle.setAttribute("property", "og:title");
      ogTitle.content = AppDetailsContent?.NAME;
      document.getElementsByTagName("head")[0].appendChild(ogTitle);
    }

    if (AppDetailsContent?.NAME) {
      ogDescription.setAttribute("property", "og:description");
      ogDescription.content = AppDetailsContent?.NAME;
      document.getElementsByTagName("head")[0].appendChild(ogDescription);
    }

    if (AppDetailsContent?.NAME) {
      description.setAttribute("property", "description");
      description.content = AppDetailsContent?.NAME;
      document.getElementsByTagName("head")[0].appendChild(description);
    }

    if (AppDetailsContent?.SHORT_NAME) {
      appName.setAttribute("property", "application-name");
      appName.content = AppDetailsContent?.SHORT_NAME;
      document.getElementsByTagName("head")[0].appendChild(appName);
    }
    return () => {
      for (const metaElement of [
        ogTitle,
        ogImage,
        ogDescription,
        appName,
        description,
      ]) {
        metaElement?.parentNode?.removeChild(metaElement);
      }
    };
  }, []);

  return <> </>;
};
