import React, { PropsWithChildren } from "react";
import { Box } from "@mui/material";

interface TabPanelProps {
  index: string;
  value: any;
}

export const TabPanel: React.FC<PropsWithChildren<TabPanelProps>> = (props) => {
  const { children, value, index } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      mt={2}
      mx={1}
    >
      {value === index && children}
    </Box>
  );
};
