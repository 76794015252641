import React from "react";
import { useMessageBirdWebhooksPage } from "../hooks/use-messagebird-webhooks-page";
import {
  SubscriptionListAccessors,
  WebhooksPage,
} from "../../components/webhook-subscriptions";
import { MessageBirdSubscription } from "../model/messagebird-subscription";
import { MessageBirdApi } from "../api/messagebird.api";
import { MessageBirdWebhookSubscriptionForm } from "../components/messagebird-webhook-subscription-form";

const subscriptionListAccessors: SubscriptionListAccessors<MessageBirdSubscription> =
  {
    id: (subscription) => subscription.id,
    subject: (subscription) => subscription.channelId,
    events: (subscription) => subscription.events?.join(", "),
    endpointUrl: (subscription) => subscription.url,
  };

export const MessageBirdWebhooksPage: React.FC = () => {
  const { subscriptions, fetchSubscriptions, removeSubscription, loading } =
    useMessageBirdWebhooksPage();

  return (
    <WebhooksPage
      subscriptions={subscriptions}
      fetchSubscriptions={fetchSubscriptions}
      removeSubscription={removeSubscription}
      loading={loading}
      subscriptionListAccessors={subscriptionListAccessors}
      fetchSubjects={MessageBirdApi.getChannels.bind(MessageBirdApi)}
      fetchEvents={MessageBirdApi.getEvents.bind(MessageBirdApi)}
      createSubscription={({ subjectId, events }) =>
        MessageBirdApi.createSubscription({
          channelId: subjectId,
          events: events as unknown as string[],
        })
      }
      formComp={MessageBirdWebhookSubscriptionForm}
    />
  );
};
