import {
  IdCheckStatus,
  Reservation,
  ReservationFromJSON,
} from "../model/reservation";
import {
  BaseApiClient,
  JSONApiResponse,
  VoidApiResponse,
} from "../../core/api";
import { Page, PageFromJSON } from "../../core/page";
import {
  GetRequestParams,
  parseQueryParams,
} from "../../api/search-criteria-parsing";

const BASE_URL = "/api/guest-journey-service";

const convertBlobToBase64 = async (blob: Blob): Promise<string> => {
  // building Promise object by hand since FileReader doesn't support async/Promise syntax
  // "promisify-ing" is not possible as FileReader does not support a callback interface
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("error", reject);
    reader.addEventListener("load", () => {
      resolve(reader.result as string);
    });
    reader.readAsDataURL(blob);
  });
};

class GuestJourneyApiClient extends BaseApiClient {
  async getReservations(
    requestParams?: GetRequestParams<Reservation>
  ): Promise<Page<Reservation>> {
    let url = `${BASE_URL}/reservations`;

    if (requestParams) {
      url += "?" + parseQueryParams(requestParams);
    }
    const response = await this.fetchApi(url);
    return new JSONApiResponse(
      response,
      PageFromJSON(ReservationFromJSON)
    ).value();
  }

  async getDistinctStatus(): Promise<string[]> {
    let url = BASE_URL + "/reservations/distinct-status";
    const response = await this.fetchApi(url);
    return response.json();
  }

  async updateReservationIdCheckStatus(
    reservationId: string,
    status: IdCheckStatus
  ) {
    const response = await this.fetchApi(
      `${BASE_URL}/reservations/${reservationId}/id-check-status?status=${status}`,
      {
        method: "PATCH",
      }
    );
    return new VoidApiResponse(response).value();
  }

  async getMagicFile(fileName: string): Promise<string> {
    const encodedFileName = encodeURIComponent(fileName);
    let url = BASE_URL.concat(`/files/${encodedFileName}`);
    const response: Response = await this.fetchApi(url);
    const blob = await response.blob();
    return convertBlobToBase64(blob);
  }
}

export const GuestJourneyApi = new GuestJourneyApiClient();
