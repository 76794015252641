export interface ReservationExtras {
  preferredCommunicationChannel: string;
  tcGeneral: boolean;
  tcOnline: boolean;
  tcChannel: boolean;
  additionalCleaning: boolean;
  cleanUnitDialogSeen: boolean;
  dirtyUnitDialogSeen: boolean;
}

export const ReservationExtrasFromJSON = (json: any): ReservationExtras => {
  if (!json) {
    return json;
  }

  return {
    preferredCommunicationChannel: json["preferredCommunicationChannel"],
    tcGeneral: json["tcGeneral"],
    tcOnline: json["tcOnline"],
    tcChannel: json["tcChannel"],
    additionalCleaning: json["additionalCleaning"],
    cleanUnitDialogSeen: json["cleanUnitDialogSeen"],
    dirtyUnitDialogSeen: json["dirtyUnitDialogSeen"],
  };
};
