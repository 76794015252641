import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

// SVG received from Nemanja
export const OpenApiIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g transform="matrix(.571361 0 0 .571361 -1.714043 -1.713935)">
      <path
        d="M14.1,27.1H3l0,0.2l0,0.3l0,0.1l0,0.4l0,0.1l0,0.4c0,0,0,0,0,0l0.1,0.4c0.1,0.9,0.3,1.8,0.6,2.6l0,0l0.1,0.4
		l0,0L4,32.5l0,0.1l0.1,0.3l0.1,0.1l0.1,0.3l0.1,0.2l0.1,0.2L4.6,34l0.1,0.1l0.1,0.3l0,0.1L5,34.8l0,0.1l0.2,0.4l0,0.1l9.5-5.7
		C14.4,28.8,14.2,28,14.1,27.1L14.1,27.1z M3.5,32.2L3.5,32.2l0.4-0.1L3.5,32.2z"
      />
      <path
        d="M16.8,32.2L9,40.1l0.1,0.1l0.3,0.3l0.3,0.2l0.1,0.1l0.3,0.2l0,0l0.3,0.3c0,0,0,0,0,0l1.5,1l0.1,0l0.3,0.2
		l0.2,0.1l0.2,0.1l0.3,0.2l0,0l0.9,0.4L18,33.1C17.6,32.8,17.2,32.6,16.8,32.2L16.8,32.2z"
      />
      <path
        d="M16,31.5l-0.2-0.3l-0.2-0.3l-0.2-0.3l-0.2-0.3L5.7,36l0.5,0.8l0,0.1l0,0l0.5,0.7l0,0l0,0L7,37.9l0.3,0.3
		l0.1,0.1l0.2,0.3l0.1,0.1l0.2,0.2L7.9,39L8,39.1l0.1,0.2l0.1,0.1l0.3,0.3l7.8-7.8L16,31.5L16,31.5z"
      />
      <path d="M26.4,32.2l-0.3,0.2l0,0l5.7,9.5l0.8-0.5c0.6-0.4,1.1-0.8,1.6-1.3L26.4,32.2L26.4,32.2z" />
      <g>
        <path d="M31.1,42.4L31.1,42.4L31.1,42.4L31.1,42.4L31.1,42.4z M31.1,42.4L31.1,42.4L31.1,42.4z" />
        <path d="M31.1,42.4L31.1,42.4L31.1,42.4L31.1,42.4L31.1,42.4z M31.1,42.4L31.1,42.4L31.1,42.4z" />
        <path
          d="M31.1,42.4L31.1,42.4L31.1,42.4L31.1,42.4z M31.1,42.4L31.1,42.4L31.1,42.4z M31.1,42.4L31.1,42.4L31.1,42.4
			L31.1,42.4z"
        />
        <path d="M31.1,42.4L31.1,42.4L31.1,42.4L31.1,42.4L31.1,42.4z" />
        <path
          d="M31.1,42.4L31.1,42.4L31.1,42.4L31.1,42.4z M31.1,42.4L31.1,42.4L31.1,42.4z M31.2,42.3L31.2,42.3L31.2,42.3z
			 M31.1,42.4L31.1,42.4L31.1,42.4z"
        />
        <path d="M31.1,42.4L31.1,42.4L31.1,42.4z" />
        <path
          d="M31,42l-5.5-9.1L25.2,33l-0.3,0.2c-1,0.5-2.1,0.7-3.2,0.7c-0.7,0-1.5-0.1-2.2-0.3l-0.4-0.1l-0.4-0.1
			l-4.2,10.2l0,0l0,0h0l0,0l0.8,0.3c1.2,0.4,2.5,0.7,3.8,0.9c0.1,0,0.1,0,0.2,0l0.5,0l0.1,0l0.4,0l0.1,0l0.3,0l0.2,0l0.3,0l0.5,0
			c1,0,2.1-0.1,3.1-0.3l0.1,0l0.3-0.1l0.2,0l0.2,0l0.3-0.1l0.1,0c1.6-0.4,3.1-0.9,4.5-1.7l0,0l0.3-0.1l0.6-0.3l0,0l0,0l0,0l0.1,0
			L31,42z M16.8,20.6l0.3-0.2l0,0l-5.7-9.5l-0.8,0.5C10,11.8,9.5,12.3,9,12.7L16.8,20.6L16.8,20.6z"
        />
      </g>
      <path
        d="M8.4,13.2l-0.5,0.5c-0.6,0.6-1.1,1.3-1.6,2l-0.1,0.1l-0.2,0.2L6,16.3l-0.1,0.1c-1.8,2.8-2.8,6-2.9,9.2L3,26
		l0,0.4h11.1l0-0.4l0-0.4c0.1-1.5,0.7-2.9,1.7-4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3L8.4,13.2z M31.3,10.5L31.3,10.5
		l-0.3-0.2l-0.2-0.1l-0.2-0.1l-0.3-0.2l0,0c-0.6-0.3-1.3-0.6-2-0.9l-0.1,0l-0.4-0.2C26.5,8.4,25.3,8.1,24,8l-0.2,0l-0.2,0l-0.3,0
		l-0.1,0l-0.4,0l-0.4,0l-0.2,0v11.1c0.6,0.1,1.1,0.2,1.7,0.4l8.2-8.2C31.9,10.9,31.6,10.7,31.3,10.5L31.3,10.5z"
      />
      <path
        d="M12.2,10.8L12,10.5L12.2,10.8z M12,10.5L12,10.5L12,10.5z M21.2,7.8l-0.4,0c-0.8,0-1.6,0.1-2.3,0.2l-0.1,0
		l-0.3,0.1l-0.2,0l-0.2,0l-0.3,0.1l-0.1,0c-1.6,0.4-3.1,0.9-4.5,1.7L12,10.5l0,0l5.7,9.5l0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2
		c0.8-0.4,1.6-0.6,2.5-0.7l0.4,0l0.4,0l0-11.1C21.5,7.8,21.3,7.8,21.2,7.8L21.2,7.8z"
      />
      <path
        d="M40.2,25.5l0-0.3l0-0.1l0-0.4l0-0.1l0-0.4l0,0l0,0L40,23.7c0,0,0,0,0,0c-0.1-0.9-0.3-1.8-0.6-2.6l0,0l-0.1-0.4
		l0-0.1l-0.1-0.4l0-0.1L39,19.9L39,19.7l-0.1-0.3l-0.1-0.2l-0.1-0.2l-0.1-0.3l-0.1-0.1l-0.1-0.3l0-0.1L38.2,18l0,0L38,17.5
		c0,0,0,0,0,0c-0.3-0.6-0.7-1.2-1.1-1.7L28.7,24c0.2,0.5,0.3,1.1,0.4,1.7h11.1L40.2,25.5z"
      />
      <path
        d="M29.1,26.4l0,0.4l0,0.4c-0.1,1.4-0.7,2.8-1.7,4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3l7.8,7.8
		c0.1-0.1,0.2-0.2,0.3-0.3l0.3-0.3c0.6-0.6,1.1-1.3,1.6-2l0.1-0.1l0.2-0.3l0.1-0.2l0.1-0.1c1.8-2.8,2.8-6,2.9-9.2l0-0.4l0-0.4
		L29.1,26.4L29.1,26.4z"
      />
      <path
        d="M43.5,4.5c-2-2-5.3-2-7.3,0c-1.6,1.6-1.9,4-1,6L23.9,21.8c-1.9-1-4.3-0.6-6,1c-2,2-2,5.3,0,7.3
		c2,2,5.3,2,7.3,0c1.6-1.6,1.9-4,1-6l11.3-11.3c1.9,1,4.3,0.6,6-1C45.5,9.8,45.5,6.5,43.5,4.5L43.5,4.5z"
      />
    </g>
  </SvgIcon>
);
