import { BaseApiClient, JSONApiResponse } from "../core/api";

export interface ServiceInfo {
  id: string;
  health: {
    status: "UP" | "DOWN";
  };
  info?: {
    build: {
      artifact: string;
      name: string;
      time: Date;
      version: string;
      group: string;
    };
    git?: {
      commit?: {
        id: string;
        url: string;
        time: Date;
      };
    };
  };
}

function ServiceInfoFromJSON(json: any): ServiceInfo {
  return {
    id: json["id"],
    health: {
      status: json["health"]["status"],
    },
    info: !json["info"]
      ? undefined
      : {
          build: {
            artifact: json["info"]["build"]["artifact"],
            name: json["info"]["build"]["name"],
            time: new Date(json["info"]["build"]["time"]),
            version: json["info"]["build"]["version"],
            group: json["info"]["build"]["group"],
          },
          git: !json["info"]["git"]
            ? undefined
            : {
                commit: !json["info"]["git"]["commit"]
                  ? undefined
                  : {
                      id: json["info"]["git"]["commit"]["id"],
                      url: json["info"]["git"]["commit"]["url"],
                      time: new Date(json["info"]["git"]["commit"]["time"]),
                    },
              },
        },
  };
}

class ApiInfoApiClient extends BaseApiClient {
  private baseUrl = "/api/info";

  async getInfo() {
    const response = await this.fetchApi(this.baseUrl);
    return new JSONApiResponse(response, (json) =>
      (json as any[]).map(ServiceInfoFromJSON)
    ).value();
  }
}

export const ApiInfoApi = new ApiInfoApiClient();
