import React, { useState, useCallback } from "react";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { GuestIdentityFieldOptions } from "../../model/guest-identity";
import { BaseIdentity } from "../../model/base-identity";
import { GuestIdentityEditor } from "./guest-identity-editor";
import { GuestIdentitySummary } from "./guest-identity-summary";

interface GuestIdentityWidgetProps {
  guestIdentity: BaseIdentity;
  guestIdentityFieldOptions?: GuestIdentityFieldOptions;
  onGuestIdentitySave: (guestIdentity: BaseIdentity) => Promise<void>;
}

const useStyles = makeStyles(() => ({
  headerContainer: {
    padding: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  guestIdentityName: {
    padding: 12,
  },
}));

export const GuestIdentityWidget: React.FC<GuestIdentityWidgetProps> = ({
  guestIdentity,
  guestIdentityFieldOptions,
  onGuestIdentitySave,
}) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleGuestIdentitySave = useCallback(
    async (updatedGuestIdentity: BaseIdentity) => {
      await onGuestIdentitySave(updatedGuestIdentity);
      setEditMode(false);
    },
    [onGuestIdentitySave]
  );

  return (
    <Grid item xs={12} md={editMode ? 12 : 6}>
      <Paper elevation={1}>
        <Box className={classes.headerContainer}>
          <Typography
            variant="h6"
            className={classes.guestIdentityName}
          >{`${guestIdentity.firstName} ${guestIdentity.lastName}`}</Typography>
          {!editMode && (
            <IconButton onClick={() => setEditMode(true)} size="large">
              <EditOutlinedIcon />
            </IconButton>
          )}
        </Box>
        <Divider />
        <Box p={2}>
          {editMode ? (
            <GuestIdentityEditor
              guestIdentity={guestIdentity}
              guestIdentityFieldOptions={guestIdentityFieldOptions}
              onSave={handleGuestIdentitySave}
              onCancel={() => setEditMode(false)}
            />
          ) : (
            <GuestIdentitySummary guestIdentity={guestIdentity} />
          )}
        </Box>
      </Paper>
    </Grid>
  );
};
