export interface MessageBirdConversationChannel {
  id: string;
  name: string;
  platformId: string;
  status: string;
  createdDatetime: Date;
  updatedDatetime: Date;
}

export const MessageBirdConversationChannelFromJSON = (
  json: any
): MessageBirdConversationChannel => {
  if (!json) {
    return json;
  }

  return {
    id: json["id"],
    name: json["name"],
    platformId: json["platformId"],
    status: json["status"],
    createdDatetime: new Date(json["createdDatetime"]),
    updatedDatetime: new Date(json["updatedDatetime"]),
  };
};
