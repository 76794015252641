import { FC } from "react";
import { LogoProps } from "./Logo";
import lmLogoBlack from "./lm_logo_black.png";

export const LikeMagicBlackLogo: FC<React.PropsWithChildren<LogoProps>> = (
  props
) => {
  return (
    <img
      alt="LIKE MAGIC Logo"
      src={lmLogoBlack}
      height={props.height}
      style={{ paddingTop: "8px", paddingBottom: "8px", marginBottom: "-4px" }}
    />
  );
};
